import React from 'react';
import styled from 'styled-components';
import {CONSTS} from './_constants';
import Icon from './icon';
import {Link} from './typography';
import { Link as RLink } from 'react-router-dom';

const LinkOrNth = ({to, children}) => to ? <RLink to={to}>{children}</RLink> : <a>{children}</a>;

const BreadcrumbsItem = ({className, children, to, icon }) => {
    return(
        <li className={className}>
            <LinkOrNth to={to}>
                {children}
            </LinkOrNth>
        </li>
    )
}
const StyledBreadcrumbsItem = styled(BreadcrumbsItem)`
    display: inline-block;
    color: ${CONSTS.colors.primary};
    a {
        transition: color .25s;
        position: relative;
        text-decoration: none;
        padding: 3px 15px;
        color: ${CONSTS.colors.primary};
        &:hover {
            color: ${CONSTS.colors.primary};
        }
    }
    &:not(:last-child) {
        a {
            &::before {
                position: absolute;
                content: '';
                top: 50%;
                left: 0;
                width: 6px;
                height: 6px;
                border: 1px solid transparent;
                border-top-color: #ccc;
                border-left-color: #ccc;
                transform: rotate(-45deg) translateY(calc(-50%));
            }
        }
    }
`

const Breadcrumbs = ({className, children, start="/"}) => {
    return(
        <ul className={className}>
            {/* <StyledBreadcrumbsItem to={start}>
                خانه
            </StyledBreadcrumbsItem> */}
            {children}
        </ul>
    )
}
const StyledBreadcrumbs = styled(Breadcrumbs)`
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    width: fit-content;
    @media print {
        display: none;
    }
`
StyledBreadcrumbs.Item = StyledBreadcrumbsItem;

export default StyledBreadcrumbs;