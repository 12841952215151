import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { getCategoryAPI } from "components/api/categories";
import { deleteOrderAskAPI, getOrderAPI, orderStatusLabelMap } from "components/api/orders";
import { deleteServiceAskAPI, getServiceAPI } from "components/api/services";
import { deleteUserAskAPI } from "components/api/users";
import { Avatar, Badge, Button, DynamicForm, DynamicFormView, Input, Link, Modal, Pagination, Spin, Table, Tabs, Title } from "components/elements";
import { Page } from "components/shared/layouts";
import SearchInput from "components/shared/searchinput";
import { BASE_URL, WEBSITE_BASE_URL } from "inc/constants";
import { camelize, getURLParams, removeEmpty, serialize } from "inc/functions";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import Swal from "sweetalert2";
import persianDate from "persian-date"
import { OrderDetailModal, ordersListColumns, OrderStatusContext } from "./utils";
import { DatePicker } from "components/elements/react-fa-datepicker";


const Style = styled.div`
    
`
const OrderList = ({ className, match }) => {

	const defaultFilters = {
		limit: 20,
		skip: 0,
	}

    const history = useHistory();

	const { status } = match.params;

    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
	const [detailOrder, setDetailOrder] = useState();
    const [filters, setFilters] = useState({
		...defaultFilters, 
		...getURLParams(history.location.search)
	});

	useEffect(() => {
        load(getURLParams(history.location.search));
    }, [history.location.search, status]);

	useEffect(() => {
        history.push(`/orders/${status ? status + '/' : ''}?${serialize(filters)}`);
    }, [filters, status]);

	const ordersStatus = useContext(OrderStatusContext);


    const load = (params) => {
        setLoading(true);
		getOrderAPI({ params: removeEmpty({ ...params, status }) }).then(res => {
            setData(res);
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }

	const columns = [
		...ordersListColumns,
		{
			title: '',
			dataIndex: 'id',
			render: (id, order) => (
				<>
					<Button small secondary icon="eye" onClick={() => setDetailOrder(order)} />
					<a href={`/print/order/${id}/`} target="_blank">
						<Button small secondary icon="print"/>
					</a>
					<a href={`/orders/${id}/chats/`} target="_blank">
						<Button small secondary icon="comment"/>
					</a>
					{/* <Button small error icon="trash" onClick={() => deleteOrderAskAPI({pk: id, success: () => load(filters)})} /> */}
					<Link to={`/orders/${id}/edit/`}>
						<Button small secondary icon="edit" />
					</Link>
				</>
			)
		}
	]

	const handleColumnSearch = (key, value) => {

		setFilters(prevFilters => {
			const newFilters = {
				...prevFilters,
				[key]: value
			}

			// if (key === 'customerFirstName') {
			// 	newFilters.customerLastName = value;
			// } else if (key === 'serviceManFirstName') {
			// 	newFilters.serviceManLastName = value;
			// }

			return newFilters;
		});

	}

	const formatDate = ({ _d }) => _d.toLocaleDateString("en-US")

    return (
        <Style className={className}>
			<OrderDetailModal active={!!detailOrder} onToggle={() => setDetailOrder(undefined)} order={detailOrder} />
            <Page title="سفارشات">
                <div className="row my-2">
					<div className="col-5 fw-normal">
						<DatePicker placeholder={"تاریخ سفارش"} type="range" onChange={(_, [from, to]) => setFilters(ff => ({...ff, createdFrom: formatDate(from), createdTo: formatDate(to)}))} block />
                    </div>
                    <div className="col-5 text-start me-auto">
						<Link to="/orders/new/">
							<Button>سفارش جدید</Button>
						</Link>
                    </div>
                </div>
                <Tabs className="mb-3" items={[
					{
						label: `همه سفارشات`,
						badge: ordersStatus?.all,
						to: `/orders/?${serialize(filters)}`,
					},
					...Object.keys(orderStatusLabelMap).map(key => ({
						label: `${orderStatusLabelMap[key]}`,
						badge: ordersStatus?.[camelize(key)],
						to: `/orders/${key}/?${serialize(filters)}`,
					}))
				]} />
                <Spin active={loading}>
                    {columns && <Table columns={columns} dataSource={data?.data} onColumnSearch={handleColumnSearch} />}
                </Spin>
                <Pagination count={data?.count || 0} current={filters.skip / 20 + 1} pageSize={20} onChange={(p) => setFilters(x => ({...x, skip:(p -1 ) * 20}))}/>
            </Page>
        </Style>
    )
}

export default OrderList;