import { Switch, Route, Redirect } from 'react-router-dom';

import { Content, Container, Sidebar, Page, TopNav, TopBar, Main } from 'components/shared/layouts';
import { useSelector } from 'react-redux';
import Users from './users';
import Services from './services';
import Categories from './categories';
import Collaborations from './collaborations';
import ContactUs from './contactUs';
import FAQs from './faqs';
import Dashboard from './dashboard';
import Orders from './orders';
import Roles from './roles';
import Coupons from './coupons';
import Transactions from './transactions';
import Profile from './profile';
import Companies from './companies';
import Admins from './admins';
import Provinces from './provinces';
import Cities from './cities';
import Zones from './zones';

const AdminScene = () => {

    const access = useSelector(state => state.auth.access);
	const permissions = useSelector(state => state.auth.permissions);

    if (!access) {
        return <Redirect to="/auth/login/" />
    }

    return (
        <Container>
            <TopNav />
			<Main>
				<Sidebar userNav={true} />
				<Content>
					<Switch>
						{permissions?.orders && <Route path="/orders/" component={Orders} />}
						{permissions?.services && <Route path="/services/" component={Services} />}
						{permissions?.coupons && <Route path="/coupons/" component={Coupons} />}
						{permissions?.['collaboration-forms'] && <Route path="/collaborations/" component={Collaborations} />}
						{permissions?.['contact-us'] && <Route path="/contactus/" component={ContactUs} />}
						{permissions?.categories && <Route path="/categories/" component={Categories} />}
						{permissions?.users && <Route path="/users/" component={Users} />}
						{permissions?.faqs && <Route path="/faqs/" component={FAQs} />}
						{permissions?.provinces && <Route path="/provinces/" component={Provinces} />}
						{permissions?.cities && <Route path="/cities/" component={Cities} />}
						{permissions?.zones && <Route path="/zones/" component={Zones} />}
						{permissions?.transactions && <Route path="/transactions/" component={Transactions} />}
						{permissions?.roles && <Route path="/roles/" component={Roles} />}
						{permissions?.profile && <Route path="/profile/" component={Profile} />}
						{permissions?.companies && <Route path="/companies/" component={Companies} />}
						{permissions?.admins && <Route path="/admins/" component={Admins} />}
						{permissions?.dashboard && <Route path="/" exact component={Dashboard} />}
					</Switch>
				</Content>
			</Main>
        </Container>
    )
}

export default AdminScene;