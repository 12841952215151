import { CONSTS } from "components/elements/_constants"
import persianDate from "persian-date"
import { useEffect, useMemo, useRef } from "react"
import styled from "styled-components"
import { media } from "./mixins"

const NoMessage = styled.li`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	color: #444;
	padding: 15px;
	margin: auto;
`

const ChatItem = styled.li`
	width: max-content;
	max-width: 70%;
	border: 1px solid transparent;
	margin-top: 15px;
	padding: 10px 15px;
	border-radius: 6px;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.5rem;
	${media("phablet")`
		max-width: 95%;
		font-size: 14px;
		margin-top: 10px;
    `}
	${({ reverse }) => reverse ? `
		margin-right: auto;
		border-top-left-radius: 0;
		border-color: #ddd;
		color: #444;
	` : `
		color: #fff;
		background-color: ${CONSTS.colors.primary};
		border-bottom-right-radius: 0;
	`}
`

const ChatListStyle = styled.ul`
	display: flex;
	flex-direction: column;
	list-style-type: none;
	min-height: 300px;
	max-height: calc(100vh - 150px);
	margin-top: 10px;
	border: 1px solid #ddd;
	overflow: auto;
	padding: 0 20px 15px;
	background-color: #fff;
	${media("phablet")`
		padding-left: 10px;
		padding-right: 10px;
    `}
	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}
	&::-webkit-scrollbar-thumb {
		background: #888;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`
export const ChatList = ({ messages, currentUserId }) => {

	const wrapperRef = useRef();

	const markedMessages = useMemo(() => {
		return messages.map(msg => ({
			...msg,
			reverse: currentUserId !== msg.from.id
		})).reverse();
	}, [messages, currentUserId]);

	useEffect(() => {
		scrollToBottom();
	}, [messages]);

	function scrollToBottom() {
		
		if (!wrapperRef?.current) return;

		const scrollHeight = wrapperRef.current.scrollHeight;
		const height = wrapperRef.current.clientHeight;
		const maxScrollTop = scrollHeight - height;
		wrapperRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
	}

	return (
		<ChatListStyle ref={wrapperRef}>
			{messages?.length > 0 ? (
				<>
					{markedMessages.map(msg => (
						<ChatItem reverse={msg.reverse} key={msg.id} title={new persianDate(new Date(msg.createdAt)).format("YYYY/MM/DD ساعت HH:mm")}>
							{msg?.message}
						</ChatItem>
					))}
				</>
			) : (
				<NoMessage>
					هیچ پیامی وجود ندارد.
				</NoMessage>
			)}
		</ChatListStyle>
	)
}