import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { getOrderAPI } from "components/api/orders";
import { deleteUserAskAPI, getUserAPI } from "components/api/users";
import { Avatar, Badge, Button, DynamicForm, DynamicFormView, Link, Modal, Pagination, Spin, Table, Tabs, Title } from "components/elements";
import { CONSTS } from "components/elements/_constants";
import { Page } from "components/shared/layouts";
import SearchInput from "components/shared/searchinput";
import SectionTitle from "components/shared/sectionTitle";
import { BASE_URL, WEBSITE_BASE_URL } from "inc/constants";
import { intComma, removeEmpty } from "inc/functions";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import Swal from "sweetalert2";
import { OrderDetailModal, ordersListColumns } from "../orders/utils";
import { ServiceTabs, UserEditTabs } from "./utils";

const Style = styled.div`
    
`
const UserOrders = ({className, match}) => {

	const history = useHistory();

	const [viewingOrder, setViewingOrder] = useState();
	const [user, setUser] = useState();
	const [orders, setOrders] = useState();
    const [loading, setLoading] = useState(false);
	
	const { id } = match.params;

	const permissions = useSelector(state => state.auth.permissions);
    
    useEffect(() => {
		
		loadOrders()
		if (id) {
			loadUser()
		}
		
    }, [id]);

	const loadOrders = () => {
        setLoading(true);
        getOrderAPI({params: {limit: 100, serviceManId: id}}).then(res => {
            setOrders(res);
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }

	const loadUser = () => {
		setLoading(true);
        return getUserAPI({pk: id}).then(res => {
			setUser(res);
			setLoading(false);
		}).catch(err => {
			setLoading(false);
		})
    }

	const columns = [
		...ordersListColumns,
		{
			title: '',
			dataIndex: 'id',
			render: (id, order) => (
				<>
					<Button small secondary icon="eye" onClick={() => setViewingOrder(order)} />
					<a href={`/print/order/${id}/`} target="_blank">
						<Button small secondary icon="print"/>
					</a>
					{/* <Button small error icon="trash" onClick={() => deleteOrderAskAPI({pk: id, success: () => load(filters)})} /> */}
					{/* <Link to={`/orders/${id}/edit/`}>
						<Button small secondary icon="edit" />
					</Link> */}
				</>
			)
		}
	];

    return (
        <Style className={className}>
            <Page title="سفارشات خدمت رسان">
				<UserEditTabs user={user} />
				<Spin active={loading}>
					<Table columns={columns} dataSource={orders?.data} />
				</Spin>
            </Page>
			<OrderDetailModal active={!!viewingOrder} onToggle={() => setViewingOrder(null)} order={viewingOrder} />
        </Style>
    )
}

export default UserOrders;