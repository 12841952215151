import { Tabs } from "components/elements"


export const ServiceTabs = ({id}) => {

	const items = [
		{
			label: 'مشخصات خدمت',
			to: `/services/${id}/edit/`
		},
		{
			label: 'سوالات خدمت',
			to: `/services/${id}/questions/`
		},
		{
			label: 'نظرات',
			to: `/services/${id}/comments/`
		},
		{
			label: 'خدمت‌رسان‌ها',
			to: `/services/${id}/serviceMans/`
		},
	]

	return (
		<Tabs className="mb-3" items={items} />
	)
}