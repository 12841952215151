import axios from 'axios'
import { BASE_URL } from 'inc/constants'
import { getAPIDefaultHeaders, getAuthHeader ,handleCatch, handleEntityDelete, serialize} from 'inc/functions'

export const getCollaborationAPI = ({params=null, pk=null}) => {
    
    let url = `${BASE_URL}/collaboration/form${pk ? '/' + pk : ''}?${serialize(params)}`;
    
    return axios.get(url, getAPIDefaultHeaders())
            .then(res => res.data)
            .catch(err => {
                handleCatch(err);
                throw err;
            } )
}

export const deleteCollaborationAskAPI = ({pk, ...params}) => {
    return handleEntityDelete({
        url: `/collaboration/form/${pk}`,
        name: 'درخواست',
        ...params
    })
}