import React from 'react';
import styled from 'styled-components';
import { CONSTS } from './_constants';

const Grid = ({className, children, gap, cols, ...otherProps}) => (
    <div className={className} {...otherProps}>
        {children}
    </div>
)

const StyledGrid = styled(Grid)`
    display: grid;
    gap: ${props => props.gap ? props.gap : 10}px;
    grid-template-columns: ${props => props.cols ? 'auto '.repeat(props.cols) : 'auto '};
`

export default StyledGrid;