import axios from 'axios'
import { BASE_URL } from 'inc/constants'
import { getAPIDefaultHeaders, getAuthHeader ,handleCatch, handleEntityDelete, serialize} from 'inc/functions'

export const getCategoryAPI = ({params=null, pk=null}) => {
    
    let url = `${BASE_URL}/category${pk ? '/' + pk : ''}?${serialize(params)}`;
    
    return axios.get(url, getAPIDefaultHeaders())
            .then(res => res.data)
            .catch(err => {
                handleCatch(err);
                throw err;
            } )
}

export const deleteCategoryAskAPI = ({pk, ...params}) => {
    return handleEntityDelete({
        url: `/category/${pk}`,
        name: 'دسته',
        ...params
    })
}


export const submitCategoryAPI = ({data={}, pk=null}) => {
    
    let api;
    let url = `${BASE_URL}/category${pk ? '/' + pk  : ''}`;

    if (pk) {
        api = axios.put(url, data, getAPIDefaultHeaders())
    } else {
        api = axios.post(url, data, getAPIDefaultHeaders())
    }
    
    return api
        .then(res => res.data)
        .catch(err => {
            handleCatch(err);
            throw err;
        })
}