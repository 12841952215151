const { default: styled } = require("styled-components");

const SectionTitle = styled.h2`
    background-color: #F7F7FA;
    color: #232333;
    padding: 0 12px;
    line-height: 32px;
    border-radius: 8px;
    font-size: 14px;
    margin-bottom: 25px; 
    font-weight: 400;
`

export default SectionTitle