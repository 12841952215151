import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { getOrderAPI } from "components/api/orders";
import { deleteUserAskAPI, getUserAPI } from "components/api/users";
import { Avatar, Badge, Button, DynamicForm, DynamicFormView, Link, Modal, Pagination, Spin, Table, Tabs, Title } from "components/elements";
import { CONSTS } from "components/elements/_constants";
import { Page } from "components/shared/layouts";
import SearchInput from "components/shared/searchinput";
import SectionTitle from "components/shared/sectionTitle";
import { BASE_URL, WEBSITE_BASE_URL } from "inc/constants";
import { intComma, removeEmpty } from "inc/functions";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import Swal from "sweetalert2";
import { OrderDetailModal, ordersListColumns } from "../orders/utils";
import { ServiceTabs, UserEditTabs, usersListColumns } from "./utils";

const Style = styled.div`
    
`
const UserReferrals = ({className, match}) => {

	const history = useHistory();

	const [user, setUser] = useState();
	const [referrals, setReferrals] = useState();
    const [loading, setLoading] = useState(false);
	
	const { id } = match.params;

	const permissions = useSelector(state => state.auth.permissions);
    
    useEffect(() => {
		
		if (id) {
			loadUser()
		}
		
    }, [id]);

	const loadReferrals = (referralCode) => {
        getUserAPI({params: {limit: 100, referralCode}}).then(res => {
            setReferrals(res);
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }

	const loadUser = () => {
		setLoading(true);
        return getUserAPI({pk: id}).then(res => {
			setUser(res);
			loadReferrals(res.supportCode);
		}).catch(err => {
			setLoading(false);
		})
    }

	const columns = [
		...usersListColumns,
		{
			title: '',
			dataIndex: 'id',
			render: (id, user) => !user.archive && (
				<>
					{permissions?.['update-user'] && (
						<Link to={`/users/${id}/edit/`}>
							<Button small secondary icon="edit" />
						</Link>
					)}
				</>
			) 
		}
	];

    return (
        <Style className={className}>
            <Page title="زیرمجموعه‌ها">
				<UserEditTabs user={user} />
				<Spin active={loading}>
					<Table columns={columns} dataSource={referrals?.data} />
				</Spin>
            </Page>
        </Style>
    )
}

export default UserReferrals;