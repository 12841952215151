import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { getCategoryAPI } from "components/api/categories";
import { getCityAPI } from "components/api/cities";
import { deleteCouponAskAPI, getCouponAPI, submitCouponAPI } from "components/api/coupons";
import { deleteUserAskAPI } from "components/api/users";
import { Avatar, Badge, Button, DynamicForm, DynamicFormView, Link, Modal, Pagination, Spin, Table, Tabs, Title } from "components/elements";
import { Page } from "components/shared/layouts";
import SearchInput from "components/shared/searchinput";
import { BASE_URL, WEBSITE_BASE_URL } from "inc/constants";
import { removeEmpty } from "inc/functions";
import persianDate from "persian-date";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import Swal from "sweetalert2";


const Style = styled.div`
    
`
const CouponNew = ({className, match}) => {

	const history = useHistory();

    const [object, setObject] = useState();
    const [loading, setLoading] = useState(false);
	const [fields, setFields] = useState();
	const [expireType, setExpireType] = useState('time');
	const [amountType, setAmountType] = useState('fix');

	const permissions = useSelector(state => state.auth.permissions);

	const { id } = match.params;
    
    useEffect(() => {

		if (id) {
			load();
		}

    }, []);

	useEffect(() => {
		const f = [
			{
				label: 'کد تخفیف',
				name: 'code',
				required: true,
				defaultValue: object?.code
			},
			{
				label: 'توضیحات بیشتر',
				placeholder: 'به عنوان راهنما به کاربر نمایش داده می‌شود.',
				name: 'description',
				defaultValue: object?.description,
			},
			{
				type: 'price',
				label: 'مقدار',
				name: 'amount.value',
				defaultValue: object?.amount?.value,
				required: true,
				col: 4
			},
			{
				type: 'radio',
				label: ' ',
				className: 'pt-4',
				name: 'amount.type',
				defaultValue: amountType,
				horizontal: true,
				onChange: t => {
					setAmountType(t);
					return t;
				},
				options: [
					{
						label: 'درصد',
						value: 'percentage'
					},
					{
						label: 'تومان',
						value: 'fix'
					},
				],
				col: 4
			},
			{
				type: 'price',
				label: 'حداقل',
				name: 'amount.maximum',
				defaultValue: object?.amount?.maximum,
				className: amountType==='fix' && 'd-none',
				required: true,
				col: 4
			},
			{
				type: 'price',
				label: 'حداکثر',
				name: 'amount.minimum',
				defaultValue: object?.amount?.minimum,
				required: true,
				className: amountType==='fix' && 'd-none',
				col: 4
			},
			{
				type: 'radio',
				label: 'روش های پرداخت',
				name: 'usageType',
				horizontal: true,
				options: [
					{
						label: 'فقط آنلاین',
						value: 'online'
					},
					{
						label: 'فقط نقد',
						value: 'cash'
					},
					{
						label: 'هردو',
						value: 'both'
					},
				],
				defaultValue: object?.usageType,
				required: true
			},
			{
				type: 'textarea',
				label: 'کاربران شامل',
				name: 'cellphones',
				defaultValue: object?.cellphones?.join("\n"),
				placeholder: 'موبایل هر کاربر را در یک خط جدید وارد کنید.\nدرصورتی که برای تمام کاربران است این فیلد را خالی بگذارید.'
			},
			{
				type: 'textarea',
				label: 'خدمات شامل',
				name: 'services',
				defaultValue: object?.services?.join("\n"),
				placeholder: 'کد پشتیبانی هر خدمت را در یک خط جدید وارد کنید.\nدرصورتی که برای تمام خدمات است این فیلد را خالی بگذارید.'
			},
			{
				label: 'نحوه انقضاء',
				type: 'radio',
				name: 'expirationRole.type',
				options: [
					{
						label: 'بعد از تاریخ مشخص',
						value: 'time'
					},
					{
						label: 'بعد از تعداد مشخص',
						value: 'count'
					},
				],
				defaultValue: expireType,
				onChange: t => {
					setExpireType(t)
					return t;
				}
			},
		];
		if (expireType === 'time') {
			f.push(
				{
					type: 'pdate',
					label: 'از',
					name: 'expirationRole.expireTime.start',
					calendarPosition: 'top-right',
					defaultValue: object?.expirationRole?.expireTime?.start && new persianDate(new Date(object?.expirationRole?.expireTime?.start)).toLocale('en').format("YYYY-MM-DD"),
					col: 2
				},
				{
					type: 'pdate',
					label: 'تا',
					name: 'expirationRole.expireTime.end',
					calendarPosition: 'top-right',
					defaultValue: object?.expirationRole?.expireTime?.end && new persianDate(new Date(object?.expirationRole?.expireTime?.end)).toLocale('en').format("YYYY-MM-DD"),
					col: 2
				},

			)
		} else if (expireType === 'count') {
			f.push(
				{
					label: 'تعداد کل',
					name: 'expirationRole.expireCount.final',
					defaultValue: object?.expirationRole?.expireCount?.final,
					col: 2
				},
				{
					label: 'استفاده شده',
					defaultValue: object?.expirationRole?.expireCount?.current,
					disabled: true,
					col: 2
				},
			)
		}
		f.push(
			{
				label: ' ',
				type: 'submit',
				value: 'ذخیره',
				col: 1
			}
		)
		setFields(f);
	}, [object, expireType, amountType]);


	const handleSubmit = data => {
		data = {
			...data,
			cellphones: data?.cellphones?.split('\n'),
			services: data?.services?.split('\n'),
		}
		if (data?.amount?.type === "fix") {
			delete data['amount']['minimum'];
			delete data['amount']['maximum'];
		}
		if (data?.expirationRole?.type === "time") {
			if (data['expirationRole']['expireTime']['start']) {
				data['expirationRole']['expireTime']['start'] = new persianDate(data['expirationRole']['expireTime']['start'].split("-").map(x => parseInt(x))).toCalendar('gregorian').toLocale('en-US').format('YYYY-MM-DD')
			}
			if (data['expirationRole']['expireTime']['end']) {
				data['expirationRole']['expireTime']['end'] = new persianDate(data['expirationRole']['expireTime']['end'].split("-").map(x => parseInt(x))).toCalendar('gregorian').toLocale('en-US').format('YYYY-MM-DD')
			}
		}
		setLoading(true);
		submitCouponAPI({data: (data), pk: id || null}).then(res=> {
			setLoading(false);
			Swal.fire({
				title: 'تبریک!',
				text: `کد تخفیف با موفقیت ${id ? 'ویرایش' : 'ایجاد'} شد.`,
				icon: 'success',
				confirmButtonText: 'باشه'
			});
			history.push(`/coupons/${res.id}/edit/`);
		}).catch(err => {
			setLoading(false);
		})
	}

    const load = () => {
        setLoading(true);
        getCouponAPI({pk: id}).then(res => {
            setObject(res);
            setLoading(false);
			setExpireType(res?.expirationRole?.type)
        }).catch(err => {
            setLoading(false);
        })
    }

	const pageMeta = <>
		{id && permissions?.['delete-coupon'] && <Button
					error
					small
					icon="trash"
					onClick={() => deleteCouponAskAPI({
						pk: id, 
						name:'کد تخفیف', 
						success: () => history.push("/coupons")
					})}>حذف</Button>}
		{id && permissions?.['create-coupon'] && <Link to="/coupons/new/">
					<Button icon="plus" small>جدید</Button>
			</Link>}
		<Link to="/coupons/">
			<Button small secondary icon="angle-left">بازگشت</Button>
		</Link>
	</>

    return (
        <Style className={className}>
            <Page title={id ? 'ویرایش کد تخفیف' : 'کد تخفیف جدید'} meta={pageMeta}>
                <Spin active={loading}>
					<DynamicForm fields={fields} block onSubmit={handleSubmit} />
                </Spin>
            </Page>
        </Style>
    )
}

export default CouponNew;