import ContactUsList from "./list";
const { Switch, Route } = require("react-router")

const ContactUs = () => {
    return (
        <Switch>
            <Route path="/contactus/" exact component={ContactUsList} />
        </Switch>
    )
}

export default ContactUs;