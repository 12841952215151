import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import { getHeadersAuth } from 'inc/functions';
import { Button, Icon } from '../elements';
import { CONSTS } from '../elements/_constants';

const Stlyes = styled.div`
    .library {
        &__header {
            background-color: ${CONSTS.colors.primary};
            color: #fff;
            border-radius: 4px 4px 0 0;
            &__button {
                position: relative;
                background-color: rgba(5, 101, 165, 1);
                &:hover {
                    background-color: rgba(5, 101, 165, .5);
                }
            }
            &__disabled {
                background-color: rgba(5, 101, 165, 0);
                cursor: auto;
                &:hover {
                    background-color: rgba(5, 101, 165, 0);
                }
            }
        }
        &__body {
            border-radius: 0 0 4px 4px;
            background-color: #fff;
            max-height: 200px;
            overflow: auto;
            &::-webkit-scrollbar {
                width: 5px;
            }
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }
            &::-webkit-scrollbar-thumb {
                background: #888;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
        }
        &__list{
            list-style-type: none;
            padding: 0;
            margin: 0;
            border: 1px solid #eee;
            border-top: none;
            &__item {
                font-size: 11px;
                &__delete {
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    color: #999;
                    font-size: 14px;
                    &:hover {
                        color: #333;
                    }
                }
                &__status {
                    position: absolute;
                    left: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &__link {
                    position: relative;
                    padding: 5px 25px 5px 30px;
                    display: block;
                    color: #333;
                    transition: background-color .25s;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    &:hover {
                        background-color: rgba(5, 101, 165, .05);
                        text-decoration: none;
                    }
                }
                &:not(:last-child){
                    border-bottom: 1px solid #eee;
                }
                &--error {
                    .library__list__item__status{
                        color: ${CONSTS.colors.error};
                    }
                    
                }
                &--ok {
                    .library__list__item__status{
                        color: ${CONSTS.colors.success};
                    }
                }
                &--loading {
                    opacity: .6;
                    pointer-events: none;
                    .library__list__item__status{
                        color: #999;
                    }
                }
            }
        }
        &__input {
            display: none;
        }
        &__nofile {
            background-color: #fff;
            padding: 15px 10px;
            text-align: center;
            font-size: 13px;
            color: #999;
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid #eee;
            border-top: none;
            &__icon {
                font-size: 2rem;
                /* margin-bottom: 15px; */
            }
        }
    }
`

const FileLibrary = ({className, onUpload, onDelete, files, placeholder, canUpload=true}) => {
    const fileRef = useRef();
    const mapStatus = {
        '-1': ['error', 'exclamation-triangle'],
        '0': ['loading', 'spinner'],
        '1': ['ok', 'check']
    }
    const [items, setItems] = useState([]);

    useEffect(()=> {
        const x = files?.map(file => {
            file.status = 1;
            return file;
        }) || [];
        setItems(x);
    }, [files]);

    const handleUploadClick = (ev) => {
        fileRef.current.click();
    }

    const handleInputChange = (ev) => {
        if(!ev.target.files[0]) return;

        let file = ev.target.files[0];
        
        if(onUpload) {
            const item = {
                name: file.name,
                status: 0
            }
            setItems(x => [{
                ...item
            }, ...x])
            onUpload(file)
        } else {
            const item = {
                name: file.name,
                status: -1
            }
            setItems(x => [{
                ...item
            }, ...x])
        }
    }

    const handleDelete = (ev, item, index) => {
        ev.preventDefault();
        if(onDelete) {
            setItems(x => 
                x.map(itm => {
                    if (itm===item) {
                        return {
                            ...itm,
                            status: 0
                        }
                    }
                    return itm;
                })
            )
            onDelete(item);
        } else {
            setItems(x => 
                x.map(itm => {
                    if (itm===item) {
                        return {
                            ...itm,
                            status: -1
                        }
                    }
                    return itm;
                })
            )
        }
    }

    return (
        <Stlyes className={className}>
            <input type="file" ref={fileRef} onChange={handleInputChange} className="library__input" />
            <div className="library__header">
                {onUpload ? <Button className="library__header__button" onClick={handleUploadClick}>
                    <Icon fa icon="upload" />
                    Upload File
                </Button> : <Button disabled={true} className="library__header__disabled">Files</Button>}
            </div>
            <div className="library__body">
                {items.length > 0 && <ul className="library__list">
                    {items.map((item, indx) => 
                            <li key={indx} className={`library__list__item library__list__item--${mapStatus[item.status][0]}`}>
                                <a className="library__list__item__link" target="_blank" href={item.url} title={item.name}>
                                    {item.name}
                                    <Icon className="library__list__item__status" fa icon={mapStatus[item.status][1]} />
                                    {onDelete && <Icon className="library__list__item__delete" fa icon="times" onClick={ev => handleDelete(ev, item, indx)} />}
                                </a>
                            </li>
                        )}
                </ul>}
                {items.length===0 && 
                    <div className="library__nofile">
                        <Icon className="library__nofile__icon" fa icon="folder-open" />
                        {placeholder || "There is no file."}
                    </div>
                }
            </div>
        </Stlyes>
    )
}

export default FileLibrary;