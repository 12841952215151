import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CONSTS } from './_constants';
import { Link, Avatar ,Spin} from '.'
import Icon from './icon';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Table = ({ columns, dataSource, selectable, onSelect, selectIndex = 'id', className, style, header=true, onColumnSearch }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [matrix, setMatrix] = useState([]);

    const makeMatrix = (records, cols) => {
        records = records.map((record, index) => ({
            ...record,
            index: index
        }));

        const getCollapserRecords = (record, col, records, depth=0) => {
            const filtered = col.collapseFilter(record, records).map(item => ({...item, depth:depth}));

            if (!filtered.find(child => col.collapseFilter(child, records).length > 0)) return filtered;

            const all = filtered;
            filtered.forEach(child => {
                all.push(...getCollapserRecords(child, col, records, depth + 1))
            });

            return all;
        }

        const invisible_indexes = [];
        let matrix_raw = 
            records.map((record, index) => {
                const columns = cols.map(col => {
                    let isCollapser = false;
                    let collapserRecords = [];
                    let collapserRealRecords = []
                    const collapseDefault = typeof col.collapseDefault === "boolean" ? col.collapseDefault : true;
                    if (typeof col.collapseFilter === "function") {
                        collapserRealRecords = col.collapseFilter(record, records);
                        collapserRecords = getCollapserRecords(record, col, records);
                        if (collapserRecords.length > 0) {
                            isCollapser = true;
                        }
                    }
                    if (collapseDefault === true) {
                        invisible_indexes.push(...collapserRecords.map(x => x.index));
                    }
                    return {
                        src: col,
                        isCollapser: isCollapser,
                        isCollapserActive: !collapseDefault,
                        collapserRecords: collapserRecords,
                        collapserRealRecords: collapserRealRecords
                    }
                });
                return {
                    index: index,
                    src: record,
                    hasCollapser: !!columns.find(col => col?.isCollapser===true),
                    isVisible: true,
                    columns: columns
                }
            });

        matrix_raw = matrix_raw.map(matRec => {
            return {
                ...matRec,
                isVisible: !invisible_indexes.includes(matRec.src.index)
            }
        })

        setMatrix(matrix_raw);
        
    }

    const renderMatrixCell = (row, col) => {
        let rendered = null;

        if (typeof (col.src.render) == 'function') {
            rendered = col.src.render(row.src[col.src.dataIndex], row.src, row.index);
        }
        else {
            rendered = row.src[col.src.dataIndex];
        }

        const handleClick = (ev) => {
            
            if (!col.isCollapser) return;
            
            const delList = col.collapserRecords.map(r => r.index);

            const isRealRecord = (index) => {
                return !!col.collapserRealRecords.find(x => x.index === index)
            }

            setMatrix(mtrx => mtrx.map(matRec => {
                if (delList.includes(matRec.src.index)) {
                    return {
                        ...matRec,
                        isVisible: col.isCollapserActive===false ? isRealRecord(matRec.src.index) : !col.isCollapserActive,
                        columns: matRec.columns.map(matCol => {
                            return {
                                ...matCol,
                                isCollapserActive: false
                            }
                        })
                    }
                } else if (matRec.index === row.index) {
                    return {
                        ...matRec,
                        columns: row.columns.map(matCol => {
                            if (matCol === col) {
                                return {
                                    ...matCol,
                                    isCollapserActive: !matCol.isCollapserActive
                                }
                            } else {
                                return matCol
                            }
                        })
                    }
                } else {
                    return matRec;
                }
            }));
        }

        const getClasses = () => {
            let cls = '';
            if (col.isCollapser) {
                cls += ' collapser'
            }
            if (col.isCollapserActive) {
                cls += ' collapser--active'
            } else if (col.isCollapser) {
                cls += ' collapser-deactive'
            }
            return cls;
        }
        
        return (
            <td 
                className={getClasses()}
                onClick={handleClick} 
                >
                    {rendered}
            </td>
        )
    }
    const renderMatrixRow = (row) => {
        const getClasses = () => {
            let cls = '';
            if (!row.isVisible) {
                cls += ' not-visible'
            }
            if (row.hasCollapser) {
                cls += ' has-collapser'
            }
            if (row.src.depth) {
                cls += ` depth-${row.src.depth}`;
            }
            return cls;
        }

        const handleClick = () => {
            if (!selectable) return;

            let copy_of_rows;
            if (selectedRows.includes(row.src.index)) { //should be removed
                let index_index = selectedRows.indexOf(row.src.index);
                copy_of_rows = [...selectedRows];
                copy_of_rows.splice(index_index, 1);
                setSelectedRows(copy_of_rows);
            } else { // should be added
                copy_of_rows = [...selectedRows, row.src.index];
            }
            setSelectedRows(copy_of_rows);
            if (onSelect) {
                let ids = [];
                let objs = [];
                copy_of_rows.forEach(rowIndex => {
                    objs.push(
                        matrix[rowIndex].src
                    );
                    ids.push(
                        matrix[rowIndex].src[selectIndex]
                    );
                });
                onSelect(ids, objs);
            }
        }
        return <tr onClick={handleClick} key={row.index} className={getClasses()}>
            {row.columns.map(col => 
                renderMatrixCell(row, col)
            )}
        </tr>
    }
    const renderMatrix = () => matrix.map(renderMatrixRow);

	const renderHeaderCell = (col, index) => {

		let searchIcon = null;

		const openSearchDialog = (searchIndex, searchMessage='جستجو کنید') => {
			Swal.fire({
				text: searchMessage,
				input: 'text',
				confirmButtonText: 'جستجو',
				showCancelButton: true,
				cancelButtonText: 'لغو'
			}).then(({value, isConfirmed}) => {
				
				if (!isConfirmed) return;
				
				onColumnSearch?.(searchIndex, value);
			})
		}
		
		if (col?.searchIndex && onColumnSearch) {
			searchIcon = (
				<FontAwesomeIcon className='me-2 cursor-pointer' icon={"search"} onClick={() => openSearchDialog(col.searchIndex, col.searchMessage)} />
			)
			
		}

		return (
			<th key={index} style={col.style}>
				{col.title}
				{searchIcon}
			</th>
		)
	}

    // make matrix
    useEffect(() => {
        if (dataSource && columns) {
            makeMatrix(dataSource, columns);
        }
    }, [dataSource, columns])
    
    return (
        <div className={`${className}__wrapper`}>
            <table style={style} className={`${className} ${selectable ? className + '--selectable' : ''}`} border="0" cellPadding="0" cellSpacing="0">
                {header && <thead>
                    <tr>
                        {selectable && <th></th>}
                        {columns.map(renderHeaderCell)}
                    </tr>
                </thead>}
                <tbody>
                    {renderMatrix()}
                </tbody>
            </table>
        </div>
    )
}

const StyledTable = styled(Table)`
    width: 100%;
    border-collapse: separate;
    text-align: right;
    &__wrapper {
        width: 100%;
        overflow-x: auto;
    }
    & > thead{
        text-transform: uppercase;
        color: #000;

        > tr{
            > th{
                /* text-align: center; */
                /* background-color: #F5F6FA; */
                padding: 10px 6px;
                font-size: 14px;
                font-weight: 800;
                white-space: nowrap;
                border-bottom: 1px solid #F5F5F7;
            }
        }
    }
    & > tbody{
        color: #333;
        > tr{
            pointer-events: all;
            &:hover {
                background-color: rgba(0, 0, 0, .03);
            }
            > td{
                padding: 8px 10px;
                line-height: 25px;
                /* text-align: center; */
                font-size: 13px;
                font-weight: 400;
                white-space: nowrap;
                border-bottom: 1px solid #F5F5F7;
                &.collapser {
                    position: relative;
                    cursor: pointer;
                    user-select: none;
                    padding-right: 20px;
                    &:before {
                        position: absolute;
                        content: '+';
                        right: 5px;
                        top: 50%;
                        transform: translateY(-50%);
                        color: #666;
                        font-size: 1rem;
                    }
                    &:hover {
                        background-color: rgba(0, 0, 0, .03);
                    }
                    &--active {
                        &::before {
                            content: '-';
                        }
                    }
                }
            }
            &.not-visible {
                display: none;
            }
            &.has-collapser {
                
            }
            &[class*="depth-"] {
                background-color: rgba(0, 0, 0, .14);
            }
            &.depth-1 {
                background-color: rgba(0, 0, 0, .02);
                margin-right: 15px;
            }
            &.depth-2 {
                background-color: rgba(0, 0, 0, .03);
            }
            &.depth-3 {
                background-color: rgba(0, 0, 0, .045);
            }
            &.depth-4 {
                background-color: rgba(0, 0, 0, .06);
            }
            &.depth-5 {
                background-color: rgba(0, 0, 0, .07);
            }
            &.depth-6 {
                background-color: rgba(0, 0, 0, .08);
            }
            &.depth-7 {
                background-color: rgba(0, 0, 0, .09);
            }
            &.depth-8 {
                background-color: rgba(0, 0, 0, .1);
            }
            &.depth-9 {
                background-color: rgba(0, 0, 0, .11);
            }
            &.depth-10 {
                background-color: rgba(0, 0, 0, .12);
            }
            /* &.collapsing {
                td {
                    &.collapser {
                        &::before {
                            content: '+';
                        }
                    }
                }
            } */
        }
    }
    &--selectable{
        & > tbody > tr{
            transition: background .15s;
            & > td:first-child{
                position: relative;
                cursor: pointer;
                color: #ccc;
            }
            &.selected{
                background-color: #F7FCFF;
                & > td:first-child{
                    color: ${CONSTS.colors.primary};
                }
            }
            
        }
    }
    ${props =>
        (props.small && (`
            > thead {
                font-size: 13px;
                > tr{
                    > td{

                    }
                }
            }
            > tbody{
                font-size: 13px;
                > tr{
                    > td{
                        padding: 5px;
                        border-left: none;
                        border-right: none;
                        border-radius: 0 !important
                        font-weight: normal;
                    }
                }
            }
        `)
        )
    }
    ${CONSTS.selectors.rtl}{
        & > thead{
            > tr{
                > td{
                    &:first-child{
                        padding-left: 0px;
                        padding-right : 20px; 
                    }
                }
            }
        }
        & > tbody{
            > tr{
                border: 1px solid red;
                > td{
                    border: 1px solid rgba(215, 219, 228, 1);
                    &:not(:last-child){
                        border-left: none;
                    }
                    &:not(:first-child){
                        border-right: none;
                    }
                    &:last-child{
                        padding-left: 20px;
                        border-radius: ${CONSTS.border.radius2} 0 0 ${CONSTS.border.radius2};
                    }
                    &:first-child{
                        padding-right: 20px;
                        border-radius: 0 ${CONSTS.border.radius2} ${CONSTS.border.radius2} 0;
                    }
                }
            }
        }
    }
`

export default StyledTable;