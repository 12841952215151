import { getDashboardAPI } from "components/api/dashboard";
import { DatePicker, DynamicForm, Spin } from "components/elements";
import JoditTextEditor from "components/elements/joditeditor";
import { intComma } from "inc/functions";
import persianDate from "persian-date";
import { useEffect, useState } from "react";
import { DashTitle, InfoBox, SelectInfoBox } from "./utils";

const Dashboard = () => {

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();

	useEffect(() => {
		setLoading(true);
		getDashboardAPI({}).then(res => {
			setData(res);
			setLoading(false);
		}).catch(err => setLoading(false));
	}, []);

	if (!data || loading) return (
		<Spin active={true}>درحال بارگزاری</Spin>
	)

	const { entitiesCount, orders, bestServiceMan, bestCustomer } = data;

	const thisDWMOptions = [
		{
			label: 'امروز',
			value: 'today'
		},
		{
			label: 'این هفته',
			value: 'lastWeek'
		},
		{
			label: 'این ماه',
			value: 'lastMonth'
		}
	]

	const DWMOptions = [
		{
			label: 'امروز',
			value: 'day'
		},
		{
			label: 'این هفته',
			value: 'week'
		},
		{
			label: 'این ماه',
			value: 'month'
		}
	]

	return(
		<>
			<div className="row mb-3">
				<div className="col-6 col-lg-4 col-xl-3 mb-3">
					<SelectInfoBox 
						title={"خدمت رسان ها"} 
						options={[
							{
								label: 'فعال',
								value: 'active'
							},
							{
								label: 'غیرفعال',
								value: 'deActive'
							},
						]}
						selectProps={{defaultValue: 'active'}}
						renderVal={v => entitiesCount?.serviceMans?.[v || 'active']}
					/>
				</div>
				<div className="col-6 col-lg-4 col-xl-3 mb-3">
					<SelectInfoBox 
						title={"مشتریان"} 
						options={[
							{
								label: 'فعال',
								value: 'active'
							},
							{
								label: 'غیرفعال',
								value: 'deActive'
							},
						]}
						selectProps={{defaultValue: 'active'}}
						renderVal={v => entitiesCount?.customers?.[v || 'active']}
					/>
				</div>
				<div className="col-6 col-lg-4 col-xl-3 mb-3">
					<SelectInfoBox 
						title={"مشتریان جدید"} 
						options={Object.keys(entitiesCount?.customers?.newUsers?.lastMonth).map(d => ({
							value: d,
							label: new persianDate(d.split('-')).toCalendar('gregorian').format('dddd MM/D')
						}))}
						selectProps={{defaultValue: Object.keys(entitiesCount?.customers?.newUsers?.lastMonth)[0]}}
						renderVal={v => entitiesCount?.customers?.newUsers?.lastMonth?.[v || Object.keys(entitiesCount?.customers?.newUsers?.lastMonth)[0]]}
					/>
				</div>
				<div className="col-6 col-lg-4 col-xl-3 mb-3">
					<SelectInfoBox 
						title={"مدیران"} 
						options={[
							{
								label: 'فعال',
								value: 'active'
							},
							{
								label: 'غیرفعال',
								value: 'deActive'
							},
						]}
						selectProps={{defaultValue: 'active'}}
						renderVal={v => entitiesCount?.admins?.[v || 'active']}
					/>
				</div>
			</div>

			<DashTitle>سفارشات</DashTitle>
			<div className="row mb-3">
				<div className="col-6 col-lg-4 col-xl-3 mb-3">
					<InfoBox title={"سفارشات هفته"}>{orders?.count?.totalInWeek}</InfoBox>
				</div>
				<div className="col-6 col-lg-4 col-xl-3 mb-3">
					<SelectInfoBox 
						title={"اتمام یافته"} 
						options={thisDWMOptions}
						selectProps={{defaultValue: 'today'}}
						renderVal={v => Object.entries(orders?.count?.[v || 'today']?.OrderCompleted).map(([k,v]) => <div style={{fontSize: 16}}>{k}: {v}</div>)}
					/>
				</div>
				<div className="col-6 col-lg-4 col-xl-3 mb-3">
					<SelectInfoBox 
						title={"منتظر خدمت رسان"} 
						options={thisDWMOptions}
						selectProps={{defaultValue: 'today'}}
						renderVal={v => Object.entries(orders?.count?.[v || 'today']?.ServiceManPending).map(([k,v]) => <div style={{fontSize: 16}}>{k}: {v}</div>)}
					/>
				</div>
				<div className="col-6 col-lg-4 col-xl-3 mb-3">
					<SelectInfoBox 
						title={"لغو شده"} 
						options={thisDWMOptions}
						selectProps={{defaultValue: 'today'}}
						renderVal={v => Object.entries(orders?.count?.[v || 'today']?.OrderCanceled).map(([k,v]) => <div style={{fontSize: 16}}>{k}: {v}</div>)}
					/>
				</div>
			</div>

			<DashTitle>درآمد</DashTitle>
			<div className="row mb-3">
				<div className="col-6 col-lg-4 col-xl-3 mb-3">
					<InfoBox title={"امروز"} meta="تومان">
						{intComma(orders?.totalPriceDone?.today)}
					</InfoBox>
				</div>
				<div className="col-6 col-lg-4 col-xl-3 mb-3">
					<InfoBox title={"این هفته"} meta="تومان">
						{intComma(orders?.totalPriceDone?.lastWeek)}
					</InfoBox>
				</div>
				<div className="col-6 col-lg-4 col-xl-3 mb-3">
					<InfoBox title={"این ماه"} meta="تومان">
						{intComma(orders?.totalPriceDone?.lastMonth)}
					</InfoBox>
				</div>
				<div className="col-6 col-lg-4 col-xl-3 mb-3">
					<InfoBox title={"مجموع کمیسیون ماه"} meta="تومان">
						{intComma(orders?.totalCommissionAmountInMonth)}
					</InfoBox>
				</div>
			</div>

			<DashTitle>بهترین خدمت رسان</DashTitle>
			<div className="row mb-3">
				<div className="col-6 col-lg-4 col-xl-3 mb-3">
					<SelectInfoBox 
						title={"بیشترین سفارش"} 
						options={DWMOptions}
						selectProps={{defaultValue: 'day'}}
						renderVal={v => {
							const x = bestServiceMan?.totalOrders?.[v || 'day'];
							return <span style={{fontSize: 16}}>{x?.user?.firstName} {x?.user?.lastName}: {x?.count} سفارش</span>
						}}
					/>
				</div>
				<div className="col-6 col-lg-4 col-xl-3 mb-3">
					<SelectInfoBox 
						title={"بیشترین درآمد"} 
						options={DWMOptions}
						selectProps={{defaultValue: 'day'}}
						renderVal={v => {
							const x = bestServiceMan?.ordersAmount?.[v || 'day'];
							return <span style={{fontSize: 16}}>{x?.serviceMan?.firstName} {x?.serviceMan?.lastName}: {intComma(x?.amount?.total)} تومان</span>
						}}
					/>
				</div>
				<div className="col-6 col-lg-4 col-xl-3 mb-3">
					<SelectInfoBox 
						title={"بیشترین کمیسیون"} 
						options={DWMOptions}
						selectProps={{defaultValue: 'day'}}
						renderVal={v => {
							const x = bestServiceMan?.companyCommission?.[v || 'day'];
							return <span style={{fontSize: 16}}>{x?.serviceMan?.firstName} {x?.serviceMan?.lastName}: {intComma(x?.amount?.total)} تومان</span>
						}}
					/>
				</div>
			</div>

			<DashTitle>بهترین مشتری</DashTitle>
			<div className="row mb-3">
				<div className="col-6 col-lg-4 col-xl-3 mb-3">
					<SelectInfoBox 
						title={"بیشترین سفارش"} 
						options={DWMOptions}
						selectProps={{defaultValue: 'day'}}
						renderVal={v => {
							const x = bestCustomer?.totalOrders?.[v || 'day'];
							return <span style={{fontSize: 16}}>{x?.user?.firstName} {x?.user?.lastName}: {x?.count} سفارش</span>
						}}
					/>
				</div>
				<div className="col-6 col-lg-4 col-xl-3 mb-3">
					<SelectInfoBox 
						title={"بیشترین مبلغ"} 
						options={DWMOptions}
						selectProps={{defaultValue: 'day'}}
						renderVal={v => {
							const x = bestCustomer?.ordersAmount?.[v || 'day'];
							return <span style={{fontSize: 16}}>{x?.customer?.firstName} {x?.customer?.lastName}: {intComma(x?.amount?.total)} تومان</span>
						}}
					/>
				</div>
				<div className="col-6 col-lg-4 col-xl-3 mb-3">
					<SelectInfoBox 
						title={"بیشترین معرفی"} 
						options={DWMOptions}
						selectProps={{defaultValue: 'day'}}
						renderVal={v => {
							const x = bestCustomer?.referrals?.[v || 'day'];
							return <span style={{fontSize: 16}}>{x?.user?.firstName} {x?.user?.lastName}: {x?.count} نفر</span>
						}}
					/>
				</div>
			</div>
		</>
	)
}

export default Dashboard;