import React from 'react';
import styled from 'styled-components';
import { CONSTS } from './_constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = ({ className, icon, size, ...otherProps }) => {
    return (
        <span className={className}>
            <FontAwesomeIcon icon={icon} size={size} {...otherProps} />
        </span>
    )
}
const StyledIcon = styled(Icon)`
    ${props => (`
        ${props.primary ? `color:${CONSTS.colors.primary}` : ""}    
        ${props.warning ? `color:${CONSTS.colors.warning}` : ""}
        ${props.error ? `color:${CONSTS.colors.error}` : ""}
        ${props.success ? `color:${CONSTS.colors.success}` : ""}
    `)}
    &__img{
        width: 12px;
        height: 12px;
        &--size-2x{
            width: 24px;
            height: 24px;
        }
    }
`
export default StyledIcon;