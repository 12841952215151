import React,{useState} from 'react';
import styled from "styled-components";
import {CONSTS}  from './_constants';
import IconAttach from 'static/img/attach.png';
import { Link } from '.';

const FormFile = (props) => {
    const {value, onDelete} = props;
    const filename = value.split('/')[value.split('/').length - 1];
    return (
        // porps.value
        // props.onDelete
        <div className={props.className}>
            <div className="attach">
                <img src={IconAttach}/>
            </div>
            <div className="filename">
                <Link className="filename__text" target="_blank" href={value}>
                    {filename}
                </Link>
                {!props.disabled && <span onClick={onDelete} className="_remove">
                    [remove]
                </span>}
            </div>
        </div>
    )
}
const StyledFormFile = styled(FormFile)`
    display: flex;
    align-items: center;
    color: ${CONSTS.colors.primary};
    /* margin-top: 8px; */
    margin-bottom: 10px;
    .attach{
        width: 15px;
        img{
            max-width: 100%;
        }
    }
    .filename{
        margin-left: 10px;
        &__text {
            font-size: 11px;
            /* white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block; */
        }
        ._remove{
            font-size: 10px;
            display: inline-block;
            color: ${CONSTS.colors.greyDarker};
            text-decoration: none;
            margin-left: 10px;
            cursor: pointer;
            &:hover{
                color: #333;
            }
        }
    }
`
export default StyledFormFile;