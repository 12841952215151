import ProvinceList from "./list";
import ProvinceNew from "./new";
const { Switch, Route } = require("react-router")

const Provinces = () => {
    return (
        <Switch>
            <Route path="/provinces/" exact component={ProvinceList} />
            <Route path="/provinces/new/" exact component={ProvinceNew} />
            <Route path="/provinces/:id/edit/" exact component={ProvinceNew} />
        </Switch>
    )
}

export default Provinces;