import React from 'react';
import styled from "styled-components"
import Icon from './icon';
import { CONSTS } from './_constants';

const Spin = ({className, children, active, icon="spinner", message}) => (
    <div className={`${className} ${active ? 'active' : ''}`}>
        {icon && <Icon className="_icon" icon={icon} spin />}
        {message && <div className="_message">{message}</div>}
        {children}
    </div>
)

const StyledSpin = styled(Spin)`
    position: relative;
    min-height: 50px;
    @keyframes spin { 100% {transform:rotate(360deg); } }
    & > ._icon{
        position: absolute;
        left: calc(50% - 12.5px);
        top: calc(50% - 12.5px);
        font-size: 20px;
        width: 25px;
        height: 25px;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        color: ${CONSTS.colors.primary};
        transform: rotate(0) ;
        /* animation:spin 2s linear infinite; */
        z-index: 1;
        display: none;
    }
    & > ._message {
        position: absolute;
        left: 50%;
        top: 50%;
        font-size: 14px;
        text-align: center;
        color: ${CONSTS.colors.primary};
        transform: translate(-50%, -50%);
        z-index: 2;
        border: 1px solid ${CONSTS.colors.primary};
        padding: 5px 15px;
        background-color: #fff;
        border-radius: 4px;
        display: none;
    }
    &::after{
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        /* background: rgba(255, 255, 255, .7); */
        opacity:0;
        pointer-events: none;
        backdrop-filter: blur(2px);
    }
    &.active{
        &::after{
            opacity: 1;
            pointer-events: all;
        }
        & > ._icon{
            display: flex;
        }
        & > ._message {
            display: flex;
        }
    }
`

export default StyledSpin;