import { Card, DynamicForm, Avatar, Badge, Button, Icon, Title, Link, Text, Modal, Spin, Tabs} from 'components/elements';
import styled from 'styled-components';
import LogoImg from 'static/img/logo.png';
import Login from './login'
import { Switch, Route, Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { CONSTS } from 'components/elements/_constants';
import { media } from 'components/shared/mixins';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion, AnimatePresence } from 'framer-motion';

const Styles = styled.div`
    background-color: rgb(240, 245, 241);
    min-height: 100vh;
    display: flex;
    align-items:center;
    overflow: hidden;
    .auth {
        &__container {
            display: flex;
            box-shadow: 0 20px 50px rgba(0, 0, 0, .1);
			background-color: #fff;
            padding: 0;
            width: 95%;
            max-width: 600px;
            border-radius: 25px;
            overflow: hidden;
            margin-top: 35px;
            margin-bottom: 35px;
            ${media("phablet")`
                flex-direction: column;
                min-height: auto;
                margin-top: 25px;
                margin-bottom: 25px;
            `}
        }
        &__main {
            flex: 1;
            padding: 40px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            width: 100%;
            &__wrapper {
                flex: 1;
            }
            &__tabs {
                overflow: hidden;
                margin-bottom: 25px;
                li {
                    flex: 1;
                    text-align: center;
                }
            }
            ${media("tablet")`
                padding: 35px;
            `}
        }
        &__logo{
            display: block;
            margin: 0 auto 25px auto;
            width: 100px;
        }
    }
    .secondary-link {
        color: ${CONSTS.colors.primary};
        &__icon {
            margin-left: 10px;
        }
    }
`

const AuthScene = ({className}) => {
   
    const access = useSelector(state => state.auth.access);

    if (access) {
        return <Redirect to="/" />
    }

    return (
        <Styles className={className} >
            <div className="container auth__container">
                <div className="auth__main">
                    <img className='auth__logo col-md-5 col-lg-5 col-sm-2' src={LogoImg} alt="چی کارا" />
                    <div layout className="auth__main__wrapper">
                        <Switch>
                            <Route path="/auth/login/" component={Login} />
                        </Switch>
                    </div>
                </div>
            </div>
        </Styles>
    )
}

export default AuthScene;