import { Input } from "components/elements"
import { DropDown } from "components/elements/react-fa-datepicker"
import { CONSTS } from "components/elements/_constants"
import { useState } from "react"
import styled from "styled-components"


const DashCard = styled.div`
	box-shadow: 0 4px 10px #eee;
	border-radius: 4px;
	padding: 20px;
	background-color: #fff;
`

export const DashTitle = styled.h2`
	font-weight: 500;
	font-size: 1.4rem;
	color: #333;
	margin-bottom: 20px;
`

const MyDropDown = styled(DropDown)`
	background-color: rgba(0, 0, 0, .04);
	border-radius: 4px;
	width: max-content;
	margin-left: auto;
	margin-right: auto;
`

const InfoBoxStyle = styled(DashCard)`
	text-align: center;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	${({ primary }) => primary ? `
		color: ${CONSTS.colors.primary};
	` : ``}
	.info {
		&__title {
			font-size: 1rem;
			font-weight: 400;
			margin-bottom: 25px;
		}
		&__body {
			font-size: 3rem;
			/* height: 60px; */
			/* display: flex; */
			/* align-items: center; */
			/* justify-content: center; */
			margin-bottom: 25px;
			small {
				font-size: 15px;
				margin-right: 5px;
			}
			/* margin: auto; */
		}
		&__footer {
			/* margin-top: auto; */
			line-height: 24px;
			opacity: .7;
		}
	}
`
export const InfoBox = ({ title, children, meta, ...otherProps }) => {
	return (
		<InfoBoxStyle {...otherProps}>
			<h3 className="info__title">{title}</h3>
			<div className="info__body">{children}</div>
			<div className="info__footer">{meta}</div>
		</InfoBoxStyle>
	)
}

export const SelectInfoBox = ({selectProps, options, renderVal, meta, children, ...rest}) => {

	const [selectVal, setSelectVal] = useState();

	return (
		<InfoBox
			meta={
				<MyDropDown 
					options={options}
					onChange={v => setSelectVal(v)}
					{...selectProps}
				/>
			}
			{...rest}
		>
			{renderVal?.(selectVal)}
		</InfoBox>
	)
}