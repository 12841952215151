import RoleList from "./list";
import RoleNew from "./new";
const { Switch, Route } = require("react-router")

const Roles = () => {
    return (
        <Switch>
            <Route path="/roles/" exact component={RoleList} />
            <Route path="/roles/new/" exact component={RoleNew} />
            <Route path="/roles/:id/edit/" exact component={RoleNew} />
        </Switch>
    )
}

export default Roles;