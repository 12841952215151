import { getMyProfileAPI, getWhoamiAPI } from "components/api/userProfile";

export const memberLoginAction = (access) => ({
    type: 'MEMBER_LOGIN',
    payload: {
        access
    }
});
export const memberLogoutAction = () => ({
    type: 'MEMBER_LOGOUT',
});

export const getOrLoadWhoamiAction = () =>
	(dispatch, getState) => {
		const data = getState().auth.member;
		if (!data) {
			dispatch(loadWhoamiAction());
		}
		else {
			return
		}
};

export const loadWhoamiAction = () =>

	(dispatch) => {
		dispatch({ type: 'START_LOADING' });
		return getMyProfileAPI({}).then(res => {
			dispatch({
				type: 'WHOAMI_SUCCESS',
				payload: res,
			});
		})
			.catch(error => {
				dispatch({
					type: 'END_LOADING',
				});
			})
	};

export const updateAccessTokenAction = (access) => {
	return {
		type: 'UPDATE_ACCESS_TOKEN',
		payload: access
	}
}