import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { getCategoryAPI } from "components/api/categories";
import { deleteServiceAskAPI, getServiceAPI } from "components/api/services";
import { deleteUserAskAPI, getUserAPI, restoreUserAPI, submitUserAPI } from "components/api/users";
import { Avatar, Badge, Button, DynamicForm, DynamicFormView, Input, Link, Modal, Pagination, Spin, Table, Tabs, Title } from "components/elements";
import { Page } from "components/shared/layouts";
import SearchInput from "components/shared/searchinput";
import { BASE_URL, WEBSITE_BASE_URL } from "inc/constants";
import { getURLParams, removeEmpty, serialize } from "inc/functions";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import Swal from "sweetalert2";
import persianDate from "persian-date"
import qs from 'querystring'
import { usersListColumns } from "./utils";

const Style = styled.div`
    
`
const UserList = ({ className, match }) => {

	const permissions = useSelector(state => state.auth.permissions);

	const { type = '' } = match.params;

	const defaultFilters = {
		limit: 20,
		skip: 0,
	}

    const history = useHistory()

    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
	const [columns, setColumns] = useState();
    const [filters, setFilters] = useState({
		...defaultFilters, 
		...getURLParams(history.location.search),
	});

	useEffect(() => {
        load(getURLParams(history.location.search));
    }, [history.location.search, type]);

	useEffect(() => {
        history.push(`/users/${type ? type + '/' : ''}?${serialize(filters, true)}`);
    }, [filters, type]);


    const load = (params) => {
        setLoading(true);
        getUserAPI({params: removeEmpty({...params, type})}).then(res => {
            setData(res);
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }

	const handleRestore = id => {
		setLoading(true);
		restoreUserAPI(id).then(res => {
			setLoading(false);
			Swal.fire({
				text: 'کاربر به لیست کاربران موجود اضافه شد.',
				icon: 'success',
				confirmButtonText: 'باشه'
			});
			load(filters);
		}).catch(() => setLoading(false));
	}

	useEffect(() => {

		if (type === 'serviceMan') {
			usersListColumns.splice(0, 1, {
				title: 'کد حسابداری',
				dataIndex: 'serviceManSection',
				render: s => s?.contractDetails?.accountingNumber
			});
		}

		setColumns([
			...usersListColumns,
			{
				title: '',
				dataIndex: 'id',
				render: (id, service) => !service.archive && !JSON.parse(filters?.archive || false) ? (
					<>
						{permissions?.['delete-user'] && (
							<Button small error icon="trash" onClick={() => deleteUserAskAPI({pk: id, success: () => load(filters)})} />
						)}
						{permissions?.['update-user'] && (
							<Link to={`/users/${id}/edit/`}>
								<Button small secondary icon="edit" />
							</Link>
						)}
					</>
				) : (
					<Button small success title="بازگردانی کاربر" icon="undo" onClick={() => handleRestore(id)} />
				)
			}
		])
	}, [filters]);

	const handleColumnSearch = () => {
		
	}

    return (
        <Style className={className}>
            <Page title="کاربران">
                <div className="row my-2">
					<div className="col-2 pe-0">
						<Input.Select block className="me-2" placeholder="فیلتر کاربران حذف شده" options={[
							{
								label: 'کاربران موجود',
								value: false
							},
							{
								label: 'کاربران حذف شده',
								value: true
							}
						]} defaultValue={JSON.parse(filters?.archive || false)} onChange={ev => setFilters(x => ({...x, archive: ev.target.value}))} />
					</div>
                    <div className="col-5 text-start me-auto">
						<Link to="/users/new/">
							{permissions?.['create-user'] && (
								<Button>کاربر جدید</Button>
							)}
						</Link>
                    </div>
                </div>
                <Tabs className="mb-3" items={[
					{
						label: 'همه کاربران',
						to: `/users/?${serialize(filters)}`,
						visible: !!permissions?.users && !!permissions?.serviceMans
					},
					{
						label: 'مشتریان',
						to: `/users/customer/?${serialize(filters)}`,
						visible: !!permissions?.users
					},
					{
						label: 'خدمت‌رسان‌ها',
						to: `/users/serviceMan/?${serialize(filters)}`,
						visible: !!permissions?.serviceMans
					}
				]} />
                <Spin active={loading}>
                    {columns && <Table columns={columns} dataSource={data?.data} onColumnSearch={(key, val) => setFilters(f => ({...f, [key]: val}))} />}
                </Spin>
                <Pagination count={data?.count || 0} current={filters.skip / 20 + 1} pageSize={20} onChange={(p) => setFilters(x => ({...x, skip:(p -1 ) * 20}))}/>
            </Page>
        </Style>
    )
}

export default UserList;