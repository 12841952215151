import { CONSTS } from "components/elements/_constants";

const { default: styled } = require("styled-components")

const Style = styled.div`
	position: relative;
	min-height: 38px;
	width: 100%;
	background-color: ${CONSTS.colors.primary};
`
const TopBar = () => <Style></Style>

export default TopBar;