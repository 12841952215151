import ZoneList from "./list";
import ZoneNew from "./new";
const { Switch, Route } = require("react-router")

const Zones = () => {
    return (
        <Switch>
            <Route path="/zones/" exact component={ZoneList} />
            <Route path="/zones/new/" exact component={ZoneNew} />
            <Route path="/zones/:id/edit/" exact component={ZoneNew} />
        </Switch>
    )
}

export default Zones;