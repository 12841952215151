import React, { useEffect, useReducer, useRef, useState } from "react";
import { commonStyles } from "./input";
import { DatePicker } from "jalali-react-datepicker";
import styled from "styled-components";

const PDatePicker = ({
	className,
	onChange,
	defaultValue,
	timePicker=true,
	style,
	...otherProps
	}) => {

	const [value, setValue] = useState();

	useEffect(() => {
		if (defaultValue) {
			setValue(
				new Date(defaultValue)
			)
		} else {
			setValue(new Date())
		}
	}, [defaultValue]);


	const handleChange = (ev) => {
		const { _d } = ev.value;
		setValue(_d);
		const d = new Date(_d).toLocaleDateString("en-US");
		const [mo, da, ye] = d.split("/");
		let date = `${ye}-${mo}-${da}`;

		if (timePicker) {
			const t = new Date(_d).toLocaleTimeString("en-GB");
			const [h, m] = t.split(":");
			date = date.concat(`T${h}:${m}:00`);
		}

		if (onChange) {
			onChange(date);
		}
	}

	return (
		<div className={className} style={style}>
			<DatePicker
				key={value?.toLocaleString()}
				label={false}
				timePicker={timePicker}
				onClickSubmitButton={handleChange}
				{...otherProps}
				value={value}
			/>
		</div>
	);
};

const StyledPDatePicker = styled(PDatePicker)`
  label {
    display: none;
  }
  ${(props) => commonStyles(props, "input")}
`;
export default StyledPDatePicker;
