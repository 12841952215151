import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { deleteCategoryAskAPI, getCategoryAPI } from "components/api/categories";
import { deleteUserAskAPI } from "components/api/users";
import { Avatar, Badge, Button, DynamicForm, DynamicFormView, Input, Link, Modal, Pagination, Spin, Table, Tabs, Title } from "components/elements";
import { Page } from "components/shared/layouts";
import SearchInput from "components/shared/searchinput";
import { BASE_URL } from "inc/constants";
import { getURLParams, serialize } from "inc/functions";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import Swal from "sweetalert2";


const Style = styled.div`
    
`
const CategoryList = ({className}) => {

	const defaultFilters = {
		limit: 20,
		skip: 0,
	}

	const permissions = useSelector(state => state.auth.permissions);

    const history = useHistory()

    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({...defaultFilters, ...getURLParams(history.location.search)});

	useEffect(() => {
        load(getURLParams(history.location.search));
    }, [history.location.search]);

	useEffect(() => {
        history.push(`/categories/?${serialize(filters)}`);
    }, [filters]);


    const load = (params) => {
        setLoading(true);
        getCategoryAPI({params: params}).then(res => {
            setData(res);
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }

    const columns = [
		{
			dataIndex: 'icon',
			render: icon => icon && <Avatar src={icon} />
		},
		{
            title: 'کد پشتیبانی',
            dataIndex: 'supportCode',
        },
		{
			title: 'فعال',
			dataIndex: 'active',
			render: a => <FontAwesomeIcon className={a ? 'color-primary' : ''} icon={a ? 'check-square' : 'times'} />
		},
        {
            title: 'نام دسته',
            dataIndex: 'name',
        },
		{
            title: 'عنوان دسته',
            dataIndex: 'title',
        },
		{
			title: 'رنگ',
			dataIndex: 'color',
			render: c => <div style={{width: 25, height: 25, backgroundColor: c, borderRadius: 25}}></div>
		},
        {
            title: '',
            dataIndex: 'id',
            render: (id) => (
                <>
					{permissions?.['delete-category'] && (
                    	<Button small error icon="trash" onClick={() => deleteCategoryAskAPI({pk: id, success: load})} />
					)}
					{permissions?.['update-category'] && (
						<Link to={`/categories/${id}/edit/`}>
							<Button small secondary icon="edit" />
						</Link>
					)}
                </>
            )
        }
    ]

    return (
        <Style className={className}>
            <Page title="دسته‌بندی‌ها">
                <div className="row my-2">
                    <div className="col-3 ps-0">
                        <SearchInput defaultValue={filters?.name} block onChange={q => setFilters(f => q ? {...f, name: q, ...defaultFilters} : {...defaultFilters} )} />
                    </div>
					<div className="col-2 pe-0">
						<Input.Select block className="me-2" placeholder="فیلتر دسته های فعال" options={[
							{
								label: 'دسته های فعال',
								value: true
							},
							{
								label: 'دسته های غیرفعال',
								value: false
							}
						]} defaultValue={JSON.parse(filters?.active || true)} onChange={ev => setFilters(x => ({...x, active: ev.target.value}))} />
					</div>
                    <div className="col-5 text-start me-auto">
						{permissions?.['create-category'] && (
							<Link to="/categories/new/">
								<Button>دسته جدید</Button>
							</Link>
						)}
                    </div>
                </div>
                {/* <div style={{height: 60}} /> */}
                <Spin active={loading}>
                    <Table columns={columns} dataSource={data?.data} />
                </Spin>
                <Pagination count={data?.count || 0} current={filters.skip / 20 + 1} pageSize={20} onChange={(p) => setFilters(x => ({...x, skip:(p -1 ) * 20}))}/>
            </Page>
        </Style>
    )
}

export default CategoryList;