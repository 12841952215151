import { useSelector } from 'react-redux';
import styled from 'styled-components'
import { Avatar, Title } from '../../elements'
import UserNav from './usernav';

const PageStyle = styled.div`
    .page {
        &__header {
            display: flex;
            align-items: center;
            /* border-bottom: 1px solid #eee; */
            &__title {
                font-size: 1.6rem;
                color: #252733;
            }
			&__meta {
				margin-right: auto;
			}
        }
        &__body {
        }
    }
`
const Page = ({className, title, meta, userNav=true, children}) => {

    return (
        <PageStyle className={className}>
            {(title || meta) && <header className="page__header">
                <Title className="page__header__title" h={1}>{title}</Title>
				<div className="page__header__meta">{meta}</div>
            </header>}
            
            <div className="page__body">
                {children}
            </div>
        </PageStyle>
    )
}

export default Page;