import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import Input from "./input";

const { default: styled } = require("styled-components");

const Style = styled.div`
    display: flex;
    flex-wrap: wrap;
    .multicheck {
        &__checkbox {
            margin-left: 15px;
            margin-bottom: 10px;
        }
    }
`
const MultiCheckbox = ({className, options, defaultValue, onChange}) => {

    const [value, setValue] = useState();

    useEffect(() => {
        if (defaultValue !== undefined) {
            setValue(defaultValue);
        }
    }, [defaultValue]);

    const doOnChange  = newVal => {
        if (onChange) {
            onChange(newVal);
        }
    }

    const handleOnChange = (ev, value) => {

        if (ev.target.checked) {
            setValue(prev => {
                const res = [...(prev || []), value];
                doOnChange(res);
                return res;
            })
        } else {
            setValue(prev => {
                const res = (prev || []).filter(item => item !== value);
                doOnChange(res);
                return res;
            });
        }
    }

    return (
        <Style>
            {options?.map(opt => 
                    <Input.Checkbox className="multicheck__checkbox" label={opt.label} value={value?.includes(opt.value)} onChange={ev => handleOnChange(ev, opt.value)} />
                )}
        </Style>
    )
}

export default MultiCheckbox;