import CompanyList from "./list";
import CompanyNew from "./new";
const { Switch, Route } = require("react-router")

const Companies = () => {
    return (
        <Switch>
            <Route path="/companies/" exact component={CompanyList} />
            <Route path="/companies/new/" exact component={CompanyNew} />
            <Route path="/companies/:id/edit/" exact component={CompanyNew} />
        </Switch>
    )
}

export default Companies;