import React from 'react'
import styled from 'styled-components'
import { CONSTS } from './_constants'

const AlertStyle = styled.div`
    background-color: rgba(0, 0, 0, .03);
    border: 1px solid rgba(0, 0, 0, .1);
    color: #333;
    padding: 5px 8px;
    border-radius: 4px;
    font-size: 13px;
`
const Alert = ({className, children, ...otherProps}) => {
    return (
        <AlertStyle className={className} {...otherProps}>
            {children}
        </AlertStyle>
    )
}

export default Alert;