import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { getCategoryAPI } from "components/api/categories";
import { deleteCityAskAPI, getCityAPI, submitCityAPI } from "components/api/cities";
import { getProvinceAPI } from "components/api/provinces";
import { deleteUserAskAPI } from "components/api/users";
import { Avatar, Badge, Button, DynamicForm, DynamicFormView, Link, Modal, Pagination, Spin, Table, Tabs, Title } from "components/elements";
import { Page } from "components/shared/layouts";
import SearchInput from "components/shared/searchinput";
import { BASE_URL, WEBSITE_BASE_URL } from "inc/constants";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import Swal from "sweetalert2";


const Style = styled.div`
    
`
const CityNew = ({className, match}) => {

	const history = useHistory();

    const [object, setObject] = useState();
    const [loading, setLoading] = useState(false);
	const [fields, setFields] = useState();
	const [provinces, setProvinces] = useState();

	const { id } = match.params;
    
    useEffect(() => {

		if (id) {
			load();
		}

		getProvinceAPI({ params: { limit: 100 } }).then(({data}) => {
			setProvinces(data);
		})

    }, []);

	useEffect(() => {
		setFields([
			{
				label: 'نام شهر',
				name: 'name',
				required: true,
				defaultValue: object?.name
			},
			{
				type: 'select',
				label: 'استان',
				name: 'provinceId',
				required: true,
				options: provinces?.map(p => ({
					label: p.name,
					value: p.id
				})),
				defaultValue: object?.province?.id
			},
			{
				label: 'عرض جغرافیایی',
				name: 'latitude',
				required: true,
				defaultValue: object?.latitude,
				col: 2
			},
			{
				label: 'طول جغرافیایی',
				name: 'longitude',
				required: true,
				defaultValue: object?.longitude,
				col: 2
			},
			{
				type: 'textarea',
				label: 'توضیحات',
				name: 'description',
				defaultValue: object?.description,
			},
			{
				label: ' ',
				type: 'submit',
				value: 'ذخیره',
				col: 1
			}
		])
	}, [provinces, object]);


	const handleSubmit = data => {
		data.language = "fa"
		setLoading(true);
		submitCityAPI({data: data, pk: id || null}).then(res=> {
			setLoading(false);
			Swal.fire({
				title: 'تبریک!',
				text: `شهر با موفقیت ${id ? 'ویرایش' : 'ایجاد'} شد.`,
				icon: 'success',
				confirmButtonText: 'باشه'
			});
			history.push(`/cities/${res.id}/edit/`);
		}).catch(err => {
			setLoading(false);
		})
	}

    const load = () => {
        setLoading(true);
        getCityAPI({pk: id}).then(res => {
            setObject(res);
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }

	const pageMeta = <>
		{id && <Button 
					error
					small
					icon="trash"
					onClick={() => deleteCityAskAPI({
						pk: id, 
						name:'شهر', 
						success: () => history.push("/cities")
					})}>حذف</Button>}
		{id && <Link to="/cities/new/">
					<Button icon="plus" small>جدید</Button>
			</Link>}
		<Link to="/cities/">
			<Button small secondary icon="angle-left">بازگشت</Button>
		</Link>
	</>

    return (
        <Style className={className}>
            <Page title={id ? 'ویرایش شهر' : 'شهر جدید'} meta={pageMeta}>
                <Spin active={loading}>
					<DynamicForm fields={fields} block onSubmit={handleSubmit} />
                </Spin>
            </Page>
        </Style>
    )
}

export default CityNew;