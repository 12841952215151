import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Badge, Button, DynamicForm, DynamicFormView, Link, Modal, Pagination, Spin, Table, Tabs, Title } from "components/elements";
import { Page } from "components/shared/layouts";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import Swal from "sweetalert2";
import { removeEmpty } from "inc/functions";
import { loadWhoamiAction } from "redux/actions/auth";
import { submitAdminAPI } from "components/api/admins";
import SectionTitle from "components/shared/sectionTitle";


const Style = styled.div`
    
`
const Profile = ({className, match}) => {

	const [fields, setFields] = useState();
	const [loading, setLoading] = useState(false);

	const member = useSelector(state => state.auth.member);

	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {

		setFields([
			{
				label: 'نام کاربری',
				defaultValue: member?.username,
				disabled: true
			},
			{
				label: 'شرکت',
				defaultValue: member?.company?.name,
				disabled: true,
			},
			{
				label: 'نام',
				name: 'details.firstName',
				defaultValue: member?.details?.firstName,
				required: true
			},
			{
				label: 'نام خانوادگی',
				name: 'details.lastName',
				defaultValue: member?.details?.lastName,
				required: true
			},
			{
				type: 'email',
				label: 'ایمیل',
				name: 'details.email',
				defaultValue: member?.details?.email,
				required: true
			},
			{
				label: 'تلفن همراه',
				name: 'details.cellphone',
				defaultValue: member?.details?.cellphone,
				required: true
			},
			{
				type: 'submit',
				value: 'ذخیره',
				col: 1
			}

		])
	}, [member]);

	const handleSubmit = data => {
		setLoading(true);
		submitAdminAPI({pk: member?.id, data: data}).then(res => {
			dispatch(loadWhoamiAction()).then(res2 => {
				setLoading(false);
				Swal.fire({
					text: 'پروفایل شما با موفقیت ویرایش شد.',
					icon: 'success',
					confirmButtonText: 'باشه'
				})
			});
		}).catch(err => {
			setLoading(false);
		})
	}

	const changePassword = (data) => {
		
		const { password, password2 } = data;
		
		let err;
		if (password !== password2) {
			err = 'رمز با تکرار آن مطابقت ندارد.'
		} else if (password.length < 8 ){
			err = 'رمز باید حداقل ۸ کاراکتر باشد.'
		}

		if (err) {
			Swal.fire({
				text: err,
				icon: 'warning',
				confirmButtonText: 'باشه'
			});
			return
		}

		delete data['password2'];
		handleSubmit(data);
	}

    return (
        <Style className={className}>
            <Page title='حساب کاربری'>
                <Spin active={loading}>
					<DynamicForm fields={fields} block cols={2} onSubmit={handleSubmit} />

					<SectionTitle className="mb-1 mt-4">تغییر رمز عبور</SectionTitle>
					<DynamicForm fields={[
						{
							label: 'رمز جدید',
							name: 'password',
							required: true,
						},
						{
							label: 'تکرار رمز جدید',
							name: 'password2',
							required: true,
						},
						{
							type: 'submit',
							value: 'تغییر رمز'
						}
					]} block cols={1} onSubmit={changePassword} />
                </Spin>
            </Page>
        </Style>
    )
}

export default Profile;