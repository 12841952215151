import styled from 'styled-components'

const Container = styled.div`
    background-color: #FFF;
    display: flex;
	flex-direction: column;
	/* justify-content: flex-start; */
	/* overflow: auto; */
    height: 100vh;
`

export default Container;