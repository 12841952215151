import React, {useState} from 'react';
import styled from 'styled-components';
import { Button, Icon } from '.';
import { Link } from './typography';
import { CONSTS } from './_constants';


const Action = ({ className, children, label, onView, onEdit, onDelete, ...otherProps }) => {
    const [isMore, setIsMore] = useState(false);
    const moreActions = onEdit || onDelete;
    return (
        <div className={className}>
            {children}
            {label && <span className='__label'>{label}</span>}
            <div className="__btns">
                {onView && <span onClick={onView} className="__btn __view">
                        <Icon icon="view" className="__view__icon" />
                    </span>}
                {moreActions && (<>
                        <Button onClick={() => setIsMore(!isMore)} secondary className="__btn" icon={isMore ? 'minus' : 'plus'} small />
                        {isMore && (<>
                            {onEdit && <Button onClick={onEdit} className="__btn" icon="pencil-alt" small />}
                            {onDelete && <Button onClick={onDelete} error className="__btn" icon="trash" small />}
                        </>)}
                    </>)}
            </div>
            
            <div className={`__overlay ${isMore ? 'active' : ''}`}></div>
        </div>
    )
};
const StyledAction = styled(Action)`
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #eee;
    /* box-shadow: 0 0 4px rgba(0, 0, 0, .1); */
    padding: 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    font-weight: 300;
    overflow: hidden;
    position: relative;
    & > .__label{
        position: absolute;
        left: 0;
        top: 0;
        background-color: ${CONSTS.colors.primaryDark};
        color: #fff;
        font-size: .8rem;
        padding: 2px 5px;
    }
    & > .__btns {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 6;
        display: flex;
        flex-direction: column;
        & > .__btn{
            width: 20px;
            height: 20px;
            margin: 0 0 5px 0;
            font-size: .5rem;
            padding: 0;
            & > span {
                margin-right: 0;
            }
            &:first-child{
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
                border-top-left-radius: 0 !important;
            }
        }
        & > .__view{
            cursor: pointer;
            img{
                width: 20px;
                height: 20px;
            }
        }
    }
    & > .__overlay{
        z-index: 5;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, .3);
        opacity: 0;
        transition: opacity .25s;
        pointer-events: none;
        &.active{
            opacity: 1;
            pointer-events: all;
        }
    }
`;

export default StyledAction;