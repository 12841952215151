import React,{useState,useEffect} from 'react';
import styled from 'styled-components';
import {Input, Button, Label, List, Table, Modal, Text} from '.';
import StyledAvatar from './avatar';
import { CONSTS } from './_constants';

const Style = styled.div`
    display: flex;
    flex-wrap: wrap;
    .formitem{
        margin-bottom: 15px;
        padding-left: 5px;
        padding-right: 5px;
        &__label{
            font-size: 14px;
            color: #747487;
            font-weight: 400;
            margin-bottom: 4px;
            padding-right: 5px;
        }
        &__input {
            min-height: 38px;
            color: #000;
            font-weight: 500;
            font-size: 15px;
        }
    }
    ${({theme}) => {
        switch (theme) {
            case 'inline':
                return `
                    .formitem {
                        padding-left: 40px;
                        display: flex;
                        &__label {
                            flex: 1;
                            max-width: 200px;
                            padding-right: 0;
                        }
                        &__input {
                            flex: 1;
                        }
                    }
                `
            default:
                break;
        }
    }}
`

const DynamicFormView = ({className, fields, cols, theme, emptyValue="-"}) => {
    /**
     * `fields` prop has array of:
     * {type, name, placeholder, defaultValue, defaultChecked, disabled, required, value}
     * 
     */

    const getFieldValue = (field) => {
        if (field.defaultValue && !field.value){
            field['value'] = field.defaultValue;
        }
        if(field.value === undefined) return;
        var val;
        if(field.render){
            return field.render(field.value);
        }
        var type = field.type ? field.type : "text";
        switch (type) {
            case 'select':
            case 'radio':
                if (typeof field.value === "number") {
                    val = field.options?.find(opt => opt.value == field.value)?.label;
                } else {
                    val = field.value;
                }
                break;
            case 'file':
                var url = field.value.file;
                val = <a target="_blank" href={url}>دانلود</a>
                break;
            case 'image':
                var url = field.value.file;
                val = <a target="_blank" href={url}><img className="_image" src={url} /></a>
                break;
            case 'location':
                val = `${field.value.lat}, ${field.value.lng}`
                break;
            default:
                val = field.value;
                break;
        }
        return val;
    }

    const renderField = field => {
        let inputLabel;
        let input;

        if (field.render) {
            if (typeof field.render === "function") {
                input = field.render(field);
            } else {
                input = field.render;
            }
        } else {
            switch (field.type) {
                case 'submit':
                    input = null;
                    break;
                case 'image': 
                    const { defaultValue, size, ...otherProps } = field;
    
                    var url = defaultValue?.file;
                    input = <a target="_blank" href={url}>
                            <StyledAvatar className="_image" src={url} size={size || 100} {...otherProps} />
                        </a>
                    break;
                default:
                    input = field?.defaultValue || field?.value || emptyValue;
                    break;
            }
    
        }

        if(field.label && !['checkbox'].includes(field.type)){
            inputLabel = <label className="formitem__label">
                    {field.label}
                </label>
        }


        const col = field.col || cols || 1;
        return (
            <div key={field.name} className={`formitem ${field?.className || ''}`} style={{width: `${100/col}%`}}>
                {inputLabel}
                {input && <div className="formitem__input">{input}</div>}
            </div>
        )
    }

    return (
        <Style className={className} theme={theme}>
            {fields && fields.map((field, i) => renderField(field))}
        </Style>
  );
}

export default DynamicFormView;