import { getOrdersStatusAPI } from "components/api/orders";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import OrderChats from "./chats";
import OrderDetail from "./detail";
import OrderList from "./list";
import OrderNew from "./new";
import { OrderTenders } from "./tenders";
import { OrderStatusContext } from "./utils";
const { Switch, Route } = require("react-router")

const Orders = () => {

	const permissions = useSelector(state => state.auth.permissions);

	const [ordersStatus, setOrdersStatus] = useState();

	useEffect(() => {
		getOrdersStatusAPI().then(setOrdersStatus);
	}, []);

    return (
		<OrderStatusContext.Provider value={ordersStatus}>
			<Switch>
				{permissions?.['create-order'] && (
					<Route path="/orders/new/" exact component={OrderNew} />
				)}
				{permissions?.['update-order'] && (
					<Route path="/orders/:id/edit/" exact component={OrderNew} />
				)}
				{permissions?.['tenders'] && (
					<Route path="/orders/:id/tenders/" exact component={OrderTenders} />
				)}
				{permissions?.['order'] && (
					<Route path="/orders/:id/view/" exact component={OrderDetail} />
				)}
				{permissions?.['chats'] && (
					<Route path="/orders/:id/chats/" exact component={OrderChats} />
				)}
				{permissions?.orders && (
					<>
						<Route path="/orders/" exact component={OrderList} />
						<Route path="/orders/:status/" exact component={OrderList} />
					</>
				)}
				{/* <Route path="/orders/:id/edit/" exact component={FAQNew} /> */}
			</Switch>
		</OrderStatusContext.Provider>
    )
}

export default Orders;