import axios from 'axios'
import { BASE_URL } from 'inc/constants'
import { getAPIDefaultHeaders, getAuthHeader ,handleCatch, handleEntityDelete, serialize} from 'inc/functions'

export const getUserAPI = ({params=null, pk=null, throwCatch=true}) => {
    
    let url = `${BASE_URL}/users/${pk ? pk + '/' : ''}?${serialize(params)}`;
    
    return axios.get(url, getAPIDefaultHeaders())
            .then(res => res.data)
            .catch(err => {
				if (throwCatch) {
					handleCatch(err);
				}
                throw err;
            } )
}

export const getServiceManServicesAPI = (pk) => {
    
    let url = `${BASE_URL}/users/serviceMan/services/${pk}`;
    
    return axios.get(url, getAPIDefaultHeaders())
            .then(res => res.data)
            .catch(err => {
                handleCatch(err);
                throw err;
            } )
}

export const submitUserAPI = ({data={}, pk=null}) => {
    
    let api;
    let url = `${BASE_URL}/users/${pk ? pk+'/' : ''}`;

    if (pk) {
        api = axios.put(url, data, getAPIDefaultHeaders())
    } else {
        api = axios.post(url, data, getAPIDefaultHeaders())
    }
    
    return api
        .then(res => res.data)
        .catch(err => {
            handleCatch(err);
            throw err;
        })
}

export const restoreUserAPI = (pk) => {
    
    let api;
    let url = `${BASE_URL}/users/restore/${pk}`;

	api = axios.put(url, {}, getAPIDefaultHeaders())
    
    return api
        .then(res => res.data)
        .catch(err => {
            handleCatch(err);
            throw err;
        })
}

export const deleteUserAskAPI = ({pk, ...params}) => {
    return handleEntityDelete({
        url: `/users/${pk}/`,
        name: 'کاربر',
        ...params
    })
}