import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router"
import { getOrLoadWhoamiAction } from "redux/actions/auth";
import PrintOrderPage from "./order/print-order";

export const PrintScene = () => {

	const permissions = useSelector(state => state.auth.permissions);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getOrLoadWhoamiAction());
	}, [])

	return (
		<Switch>
			{permissions?.order && <Route path="/print/order/:id/" component={PrintOrderPage} />}
		</Switch>
	)
}