import { Alert, Avatar, Badge, Button, DynamicForm, DynamicFormView, Link, Modal, Spin, Tabs } from "components/elements"
import { useEffect } from "react";
import { useState } from "react"
import persianDate from "persian-date"
import { calculateOrderPriceAPI, orderPaymentStatuses, orderPaymentTypes, orderStatusLabelMap, ORDER_CREATEDWITH_OPTIONS } from "components/api/orders";
import { intComma, removeEmpty } from "inc/functions";
import SectionTitle from "components/shared/sectionTitle";
import styled from "styled-components";
import { createContext } from "react";

const StyledModal = styled(Modal)`
	@media print {
		max-width: none;
		max-height: none;
		width: 100vw;
		height: 100vh;
		._header {
			display: none;
		}
		._body {
			padding: 15px 45px;
			overflow: visible;
		}
		body {
			overflow: hidden;
		}
	}
`

export const OrderDetailModal = ({active, onToggle, order}) => {

	const modalTitle = <div className="flex items-center">
		<span>جزئیات سفارش</span>
		<a href={`/print/order/${order?.id}/`} target="_blank">
			<Button className="py-1" icon="print" secondary>چاپ</Button>
		</a>
	</div>
	
	return (
		<StyledModal title={modalTitle}  xl active={active} onToggle={onToggle}>
			<OrderDetailForm order={order} />
		</StyledModal>
	)
}

export const OrderDetailForm = ({order, ...rest}) => {


	const [fields, setFields] = useState();

	useEffect(() => {

		if (!order) return;

		const f = [
			{
				label: 'شماره سفارش',
				defaultValue: order?.supportCode
			},
			{
				label: 'خدمت درخواستی',
				render: (
					<Link to={`/services/${order.service?.id}/edit/`}>{order?.service?.name}</Link>
				)
			},
			{
				label: 'مشتری',
				defaultValue: order?.customer,
				render: ({defaultValue: customer}) => customer && (
					<Link to={`/users/${customer.id}/edit/`}>
						{`${customer?.firstName} ${customer?.lastName}`}
					</Link>
				)
			},
			{
				label: 'خدمت رسان',
				defaultValue: order?.serviceMan,
				render: ({defaultValue: serviceMan}) => serviceMan && (
					<Link to={`/users/${serviceMan.id}/edit/`}>
						{`${serviceMan?.firstName} ${serviceMan?.lastName}`}
					</Link>
				)
			},
			{
				label: 'تاریخ و زمان ثبت',
				defaultValue: order?.createdAt && (new persianDate(new Date(order.createdAt)).format("LLLL"))
			},
			{
				label: 'وضعیت',
				defaultValue: order.status && orderStatusLabelMap[order.status]
			},
			{
				label: 'تاریخ و زمان انجام',
				defaultValue: order?.orderDate && (new persianDate(new Date(order.orderDate)).format("dddd D MMMM")) + ' ساعت ' + order?.orderTime?.split("-")?.join(" الی ")
			},
			{
				label: 'آدرس',
				defaultValue: `${order?.address?.title}: ${order?.address?.content} (منطقه ${order?.address?.zone?.name})`
			},
			{
				label: 'تلفن',
				defaultValue: order?.orderPhone
			},
			{
				label: 'توضیحات مشتری',
				defaultValue: order?.orderDescription
			},
			{
				label: 'تصاویر',
				defaultValue: order?.orderImages,
				render: ({defaultValue}) => defaultValue?.length && (
					defaultValue?.map(dv => 
						<a href={dv} target="_blank">
							<Avatar size={150} src={dv} />
						</a>
					)
				),
			},
			{
				label: 'تعداد خدمت رسان ها',
				defaultValue: order?.serviceMansCount
			},
			{
				label: 'علت کنسلی',
				defaultValue: order?.cancellationReason?.join("، ")
			},
			{
				label: 'سوالات',
				col: 1,
				type: 'custom',
				render: (
					<ul>
						{order?.answers?.map(ans => 
								<li>
									<span>{ans?.question?.title}: </span>
									<b>{ans?.answer}</b>
								</li>
							)}
					</ul>
				)
			}
		];

		if (order?.tender) {
			f.push(
				{
					type: 'custom',
					render: <SectionTitle>جزئیات مناقصه</SectionTitle>,
					col: 1
				},
				{
					label: 'هزیه انجام خدمت',
					defaultValue: `${intComma(order?.tender?.prices?.wages) || 0} تومان`
				},
				{
					label: 'هزیه های مازاد',
					defaultValue: `${intComma(order?.tender?.prices?.materials) || 0} تومان`
				},
				{
					label: 'تاریخ و زمان انجام',
					defaultValue: `${new persianDate(order?.tender?.tenderDate).format("dddd D MMMM")} ساعت ${order?.tender?.tenderTime?.split("-")?.join(" الی ")}`
				},
				{
					label: 'مجموع هزینه',
					// defaultValue: `${intComma(order?.tender?.prices?.estimated) || ''} تومان`,
					render: <Badge>
						{intComma(order?.tender?.prices?.estimated)}
						&nbsp;
						تومان
					</Badge>
				},
			)
		}

		if (order?.amount) {
			f.push(
				{
					type: 'custom',
					render: <SectionTitle className="mb-0">جزئیات مالی</SectionTitle>,
					col: 1
				},
				{
					type: 'custom',
					render: <Alert warning className="mb-3">ممکن است مالیات بر ارزش افزوده به مبلغ نهایی اضافه شود.</Alert>,
					col: 1
				},
				{
					label: 'مبلغ اجرت',
					defaultValue: `${intComma(order?.amount?.wage || 0)} تومان`
				},
				{
					label: 'مبلغ وسایل و مواد اولیه',
					defaultValue: `${intComma(order?.amount?.materials) || 0} تومان`
				},
				{
					label: 'تخفیف',
					defaultValue: `${intComma(order?.amount?.coupon?.amount?.value) || 0} تومان`
				},
				{
					label: 'قابل پرداخت',
					render: <Badge className="fs-6" success>
						{intComma(order?.amount?.total) || 0}
						&nbsp;
						تومان
					</Badge>
				},
				{
					label: 'نحوه پرداخت',
					render: orderPaymentTypes?.[order?.amount?.settlement?.type]
				},
			)
		}

		if (order?.amount?.upfront) {
			f.push(
				{
					type: 'custom',
					render: <SectionTitle className="mb-0">پیش پرداخت</SectionTitle>,
					col: 1
				},
				{
					label: 'نحوه پرداخت',
					render: orderPaymentTypes?.[order?.amount?.upfront?.type]
				},
				{
					label: 'وضعیت پرداخت',
					render: orderPaymentStatuses?.[order?.amount?.upfront?.status],
				},
				{
					label: 'مبلغ پیش‌پرداخت',
					render: `${intComma(order?.amount?.upfront?.amount) || 0} تومان`
				},
				
			)
		}
		
		if (order?.amount?.settlement) {
			f.push(
				{
					type: 'custom',
					render: <SectionTitle className="mb-0">تسویه حساب</SectionTitle>,
					col: 1
				},
				{
					label: 'نحوه پرداخت',
					render: orderPaymentTypes?.[order?.amount?.settlement?.type]
				},
				{
					label: 'وضعیت پرداخت',
					render: orderPaymentStatuses?.[order?.amount?.settlement?.status],

				},
				{
					label: 'تخفیف',
					render: `${intComma(order?.amount?.settlement?.discountAmount) || 0} تومان`
				},
				{
					label: 'مبلغ پرداخت شده',
					render: `${intComma(order?.amount?.settlement?.amount) || 0} تومان`
				},
				{
					label: 'سهم شرکت',
					render: `${intComma(order?.amount?.settlement?.multiplexingDetails?.companyAmount) || 0} تومان`
				},
				{
					label: 'سهم خدمت‌رسان',
					render: `${intComma(order?.amount?.settlement?.multiplexingDetails?.serviceManAmount) || 0} تومان`
				},
				{
					label: 'پیام بانک',
					render: order?.gateway?.flow?.start?.response?.Message
				},
			)
		}

		if (order?.rateDetails) {
			f.push(
				{
					type: 'custom',
					render: <SectionTitle className="mb-0">نظرسنجی</SectionTitle>,
					col: 1
				},
				{
					label: 'امتیاز',
					render: `${order?.rateDetails?.rate}/5`
				},
				{
					label: 'توضیحات',
					render: order?.rateDetails?.description
				},
				{
					label: 'گزینه ها',
					render: <ul>
								{order?.rateDetails?.assessment?.map(a => <li>{a}</li>)}
							</ul>
				},
			)
		}

		setFields(f);
	}, [order]);

	if (!fields) return null;

	return (
		<DynamicFormView theme="inline" fields={fields} cols={2} {...rest} />
	)

}

export const OrderStatusContext = createContext();

export const ordersListColumns  = [
	{
		title: 'کد سفارش',
		dataIndex: 'supportCode',
		searchIndex: 'supportCode'
	},
	{
		title: 'روش',
		dataIndex: 'createdWith',
		render: c => ORDER_CREATEDWITH_OPTIONS?.[c] || '-'
	},
	{
		title: 'موبایل',
		dataIndex: 'customer',
		render: c => c?.cellphone,
		searchIndex: 'orderPhone'
	},
	{
		title: 'نام مشتری',
		dataIndex: 'customer',
		render: c => c?.firstName,
		searchIndex: 'customerFirstName',
		searchMessage: 'نام مشتری را وارد کنید'
	},
	{
		title: 'نام.خ مشتری',
		dataIndex: 'customer',
		render: c => c?.lastName,
		searchIndex: 'customerLastName',
		searchMessage: 'نام خانوادگی مشتری را وارد کنید'
	},
	{
		title: 'خدمت',
		dataIndex: 'service',
		render: s => s?.name,
		searchIndex: 'serviceSupportCode',
		searchMessage: 'کد خدمت را وارد کنید.'
	},
	{
		title: 'وضعیت',
		dataIndex: 'status',
		render: s => orderStatusLabelMap[s]
	},
	{
		title: 'نام خدمت‌رسان',
		dataIndex: 'serviceMan',
		render: u =>u?.firstName,
		searchIndex: 'serviceManFirstName',
		searchMessage: 'نام خدمت‌رسان را وارد کنید.'
	},
	{
		title: 'نام.خ خدمت‌رسان',
		dataIndex: 'serviceMan',
		render: u =>u?.lastName,
		searchIndex: 'serviceManLastName',
		searchMessage: 'نام خانوادگی خدمت‌رسان را وارد کنید.'
	},
	{
		title: 'تاریخ ثبت',
		dataIndex: 'createdAt',
		render: d => new persianDate(new Date(d)).format("dddd D MMMM")
	},
	{
		title: 'زمان انجام',
		dataIndex: 'orderDate',
		render: (d, order) => `${new persianDate(new Date(d)).format("dddd D MMMM")} ${order.orderTime?.split("-")?.join(" الی ") || ''}`
	},
	{
		title: 'شرکت',
		dataIndex: 'serviceMan',
		render: s => s?.admin?.company?.name || '-',
		searchIndex: 'companyName',
	}
];

export const getQuestionFormField = (q, onChange) => {
	switch (q.type) {
		case 'list':
			return {
				type: 'radio',
				horizontal: true,
				label: q.title,
				onChange: ans => onChange(q.id, ans),
				key: q.id,
				options: q.items?.map(it => ({
					label: it,
					value: it
				})),
				required: true,
				col: 2,
			}
			break;
		default:
			return {
				type: q.type,
				label: q.title,
				onChange: ev => onChange(q.id, ev.target.value),
				required: true,
				col: 2
			}
		break;
	}
}


export const OrderEditTabs = ({ id }) => {

	if (!id) return null;
	
	const items = [
		{
			label: 'ویرایش سفارش',
			to: `/orders/${id}/edit/`,
		},
		{
			label: 'مناقصات',
			to: `/orders/${id}/tenders/`,
		},
	]

	return (
		<Tabs className="mb-3" items={items} />
	)
}

export const CalculateOrderPriceModal = ({orderId, afterSubmit, active, onToggle}) => {

	const [loading, setLoading] = useState(false);

	const fields = [
		{
			type: 'price',
			label: 'دستمزد',
			name: 'wage',
			required: true,
		},
		{
			type: 'price',
			label: 'کالا یا مواد اولیه',
			name: 'materials',
			required: true,
		},
		{
			type: 'select',
			label: 'نوع پرداخت',
			name: 'type',
			options: Object.entries(orderPaymentTypes).map(([value, label]) => ({
				label,
				value,
			}))
		},
		{
			type: 'text',
			label: 'کد تخفیف',
			name: 'couponCode',
		},
		{
			type: 'checkbox',
			inputLabel: 'استفاده از اعتبار',
			name: 'creditUsage',
		},
		{
			type: 'submit',
			value: 'تایید و ذخیره'
		}
	]

	function handleSubmit(data) {
		// console.log(data);
		setLoading(true);
		calculateOrderPriceAPI({data: removeEmpty(data), pk: orderId}).then(res => {
			afterSubmit?.(res)
		})
		.finally(() => {
			setLoading(false);
		})
	}

	return (
		<Modal active={active} onToggle={onToggle} title="محاسبه دستمزد سفارش" >
			<Spin active={loading}>
				<DynamicForm block fields={fields} theme='inline' onSubmit={handleSubmit} />
			</Spin>
		</Modal>
	)
}