import { useSelector } from "react-redux";
import UserCredit from "./credit";
import UserList from "./list";
import UserNew from "./new";
import UserOrders from "./orders";
import UserReferrals from "./referrals";
import UserTransactions from "./transactions";
const { Switch, Route } = require("react-router")

const Users = () => {

	const permissions = useSelector(state => state.auth.permissions);

    return (
        <Switch>
            <Route path="/users/" exact component={UserList} />
            {permissions?.['create-user'] && (
				<Route path="/users/new/" exact component={UserNew} />
			)}
            <Route path="/users/:type/" exact component={UserList} />
            {permissions?.['update-user'] && (
				<Route path="/users/:id/edit/" exact component={UserNew} />
			)}
			{permissions?.['transactions'] && (
            	<Route path="/users/:id/transactions/" exact component={UserTransactions} />
			)}
			{permissions?.['transactions'] && (
            	<Route path="/users/:id/credit/" exact component={UserCredit} />
			)}
			{permissions?.['users'] && (
            	<Route path="/users/:id/referrals/" exact component={UserReferrals} />
			)}
			{permissions?.orders && (
            	<Route path="/users/:id/orders/" exact component={UserOrders} />
			)}
			{permissions?.['create-user'] && (
            	<Route path="/users/new/:collaborationid/" exact component={UserNew} />
			)}
        </Switch>
    )
}

export default Users;