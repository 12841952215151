import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { getCategoryAPI } from "components/api/categories";
import { getCityAPI } from "components/api/cities";
import { deleteCommentAskAPI, getCommentAPI } from "components/api/comments";
import { deleteQuestionAskAPI, getQuestionAPI, submitQuestionAPI } from "components/api/questions";
import { deleteServiceAskAPI, getServiceAPI, submitServiceAPI } from "components/api/services";
import { deleteUserAskAPI } from "components/api/users";
import { Avatar, Badge, Button, DynamicForm, DynamicFormView, Link, Modal, Pagination, Spin, Table, Tabs, Title } from "components/elements";
import { CONSTS } from "components/elements/_constants";
import { Page } from "components/shared/layouts";
import SearchInput from "components/shared/searchinput";
import { BASE_URL, WEBSITE_BASE_URL } from "inc/constants";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import Swal from "sweetalert2";
import { ServiceTabs } from "./utils";

const Style = styled.div`
    
`
const ServiceComments = ({className, match}) => {

	const history = useHistory();

	const [comments, setComments] = useState();
    const [loading, setLoading] = useState(false);
	
	const { id } = match.params;
    
    useEffect(() => {
		
		if (id) {
			loadComments();
		}
		
    }, [id]);

	const loadComments = () => {
        setLoading(true);
			getCommentAPI({params: {limit: 100, entityId: id, entityType: 'service', status: 'all'}}).then(res => {
            setComments(res);
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }

	const handleToggleApproved = comment => {
		setLoading(true);
		getCommentAPI({action: comment.approved ? 'disable' : 'approved', pk: comment.id}).then(res => {
			Swal.fire({
				icon: 'success',
				text: `نظر با موفقیت ${comment.approved ? 'عدم تایید' : 'تایید'} شد`,
				confirmButtonText: 'باشه'
			});
			loadComments();
			setLoading(false)
		}).catch(err => {
			setLoading(false);
		})
	}

	const columns = [
		{
			title: 'کاربر',
			dataIndex: 'user',
			render: (user) => (
				<Link to={`/users/${user.id}/edit/`}>{user?.firstName} {user?.lastName}</Link>
			)
		},
		{
			title: 'نام',
			dataIndex: 'fullName'
		},
		{
			title: 'عنوان',
			dataIndex: 'title'
		},
		{
			title: 'نظر',
			dataIndex: 'comment',
		},
		{
			title: 'تعداد لایک',
			dataIndex: 'likes',
		},
		{
			title: '',
			dataIndex: 'id',
			render: (id, comment) => (
				<>
					<Button small error icon="trash" onClick={() => deleteCommentAskAPI({pk: id, success: loadComments})} />
					{comment?.approved ? (
						<Button sm light onClick={() => handleToggleApproved(comment)} >عدم تایید</Button>
					) : (
						<Button sm success onClick={() => handleToggleApproved(comment)} >تایید</Button>
					)}
				</>
			)
		}
	]

    return (
        <Style className={className}>
            <Page title="نظرات خدمت">
				{id && <ServiceTabs id={id} />}
				<Spin active={loading}>
					<Table columns={columns} dataSource={comments?.data} />
				</Spin>
            </Page>
        </Style>
    )
}

export default ServiceComments;