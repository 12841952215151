import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { getCategoryAPI } from "components/api/categories";
import { deleteCompanyAskAPI, getCompanyAPI } from "components/api/companies";
import { deleteServiceAskAPI, getServiceAPI } from "components/api/services";
import { deleteUserAskAPI } from "components/api/users";
import { Avatar, Badge, Button, DynamicForm, DynamicFormView, Input, Link, Modal, Pagination, Spin, Table, Tabs, Title } from "components/elements";
import { Page } from "components/shared/layouts";
import SearchInput from "components/shared/searchinput";
import { BASE_URL, WEBSITE_BASE_URL } from "inc/constants";
import { getURLParams, serialize } from "inc/functions";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import Swal from "sweetalert2";
import persianDate from "persian-date"


const Style = styled.div`
    
`
const CompanyList = ({className}) => {

	const defaultFilters = {
		limit: 20,
		skip: 0,
	}

    const history = useHistory()

    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
	const [columns, setColumns] = useState();
    const [filters, setFilters] = useState({
		...defaultFilters, 
		...getURLParams(history.location.search)
	});

	useEffect(() => {
        load(getURLParams(history.location.search));
    }, [history.location.search]);

	useEffect(() => {
        history.push(`/companies/?${serialize(filters)}`);
    }, [filters]);


    const load = (params) => {
        setLoading(true);
        getCompanyAPI({params: params}).then(res => {
            setData(res);
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }

	useEffect(() => {
		setColumns([
			{
				title: 'کد پشتیبانی',
				dataIndex: 'supportCode'
			},
			{
				title: 'فعال',
				dataIndex: 'active',
				render: a => <FontAwesomeIcon className={a ? 'color-primary' : ''} icon={a ? 'check-square' : 'times'} />
			},
			{
				title: 'نام',
				dataIndex: 'name',
			},
			{
				title: 'آخرین ویرایش',
				dataIndex: 'updatedAt',
				render: d => new persianDate(new Date(d)).format("dddd D MMMM ساعت HH:mm")
			},
			{
				title: '',
				dataIndex: 'id',
				render: (id, service) => !service.archive && !JSON.parse(filters?.archive || false) && (
					<>
						<Button small error icon="trash" onClick={() => deleteCompanyAskAPI({pk: id, success: () => load(filters)})} />
						<Link to={`/companies/${id}/edit/`}>
							<Button small secondary icon="edit" />
						</Link>
					</>
				)
			}
		])
	}, [filters])

    return (
        <Style className={className}>
            <Page title="شرکت ها">
                <div className="row my-2">
                    <div className="col-3 ps-0">
                        <SearchInput block defaultValue={filters?.name} onChange={q => setFilters(f => q ? {...f, name: q, ...defaultFilters} : {...defaultFilters} )} />
                    </div>
                    <div className="col-5 text-start me-auto">
						<Link to="/companies/new/">
							<Button>شرکت جدید</Button>
						</Link>
                    </div>
                </div>
                {/* <div style={{height: 60}} /> */}
                <Spin active={loading}>
                    {columns && <Table columns={columns} dataSource={data?.data} />}
                </Spin>
                <Pagination count={data?.count || 0} current={filters.skip / 20 + 1} pageSize={20} onChange={(p) => setFilters(x => ({...x, skip:(p -1 ) * 20}))}/>
            </Page>
        </Style>
    )
}

export default CompanyList;