import { useEffect } from "react";
import styled from "styled-components";
import { CONSTS } from "./_constants";

const pSBC = (p, c0, c1, l) => {
	let r, g, b, P, f, t, h, i = parseInt, m = Math.round, a = typeof (c1) == "string";
	if (typeof (p) != "number" || p < -1 || p > 1 || typeof (c0) != "string" || (c0[0] != 'r' && c0[0] != '#') || (c1 && !a)) return null;
	const psbcr = (d) => {
		let n = d.length, x = {};
		if (n > 9) {
			[r, g, b, a] = d = d.split(",");
			n = d.length;
			if (n < 3 || n > 4) return null;
			x.r = i(r[3] == "a" ? r.slice(5) : r.slice(4));
			x.g = i(g);
			x.b = i(b);
			x.a = a ? parseFloat(a) : -1;
		} else {
			if (n == 8 || n == 6 || n < 4) return null;
			if (n < 6) d = "#" + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : "");
			d = i(d.slice(1), 16);
			if (n == 9 || n == 5) {
				x.r = d >> 24 & 255;
				x.g = d >> 16 & 255;
				x.b = d >> 8 & 255;
				x.a = m((d & 255) / 0.255) / 1000;
			}
			else {
				x.r = d >> 16;
				x.g = d >> 8 & 255;
				x.b = d & 255;
				x.a = -1
			}
		} return x
	};
	h = c0.length > 9;
	h = a ? c1.length > 9 ? true : c1 == "c" ? !h : false : h;
	f = psbcr(c0);
	P = p < 0;
	t = c1 && c1 != "c" ? psbcr(c1) : P ? { r: 0, g: 0, b: 0, a: -1 } : { r: 255, g: 255, b: 255, a: -1 };
	p = P ? p * -1 : p;
	P = 1 - p;
	if (!f || !t) return null;
	if (l) {
		r = m(P * f.r + p * t.r);
		g = m(P * f.g + p * t.g);
		b = m(P * f.b + p * t.b);
	}
	else {
		r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5);
		g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5);
		b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5);
	}
	a = f.a;
	t = t.a;
	f = a >= 0 || t >= 0;
	a = f ? a < 0 ? t : t < 0 ? a : a * P + t * p : 0;
	if (h) return "rgb" + (f ? "a(" : "(") + r + "," + g + "," + b + (f ? "," + m(a * 1000) / 1000 : "") + ")";
	else return "#" + (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0)).toString(16).slice(1, f ? undefined : -2)
}


function hexToRgb(hex) {
	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? [
		parseInt(result[1], 16),
		parseInt(result[2], 16),
		parseInt(result[3], 16)
	 ] : null; 
} 

const ThemeContainer = ({theme, children}) => {

	let styles;
	if (theme) {
		
	} else {
		styles = '';
	}

	useEffect(() => {

		if (!theme) return;

		const styles = `
			:root {
				--primary: ${theme.primary};
				--primaryDark: ${pSBC(-0.3, theme.primary)};
				--primary-20: rgba(${hexToRgb(theme.primary)?.join(",")}, .2);
				--primary-005: rgba(${hexToRgb(theme.primary)?.join(",")}, .05);
				--background: ${theme.background};
				--text: ${theme.text};
				--text-10: rgba(${hexToRgb(theme.text)?.join(",")}, .1);
				color: var(--text);
			}
		`

		var styleSheet;

		styleSheet = document.getElementById("theme-variables");

		if (styleSheet) {
			styleSheet.innerText = styles
		} else {
			var styleSheet = document.createElement("style")
			styleSheet.type = "text/css"
			styleSheet.id = "theme-variables";
			styleSheet.innerText = styles
			document.head.appendChild(styleSheet)	
		}

	}, [theme])

	return children;
}

export default ThemeContainer;