import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { getCityAPI } from "components/api/cities";
import { deleteCategoryAskAPI, getCategoryAPI, submitCategoryAPI } from "components/api/categories";
import { deleteUserAskAPI } from "components/api/users";
import { Avatar, Badge, Button, DynamicForm, DynamicFormView, Link, Modal, Pagination, Spin, Table, Tabs, Title } from "components/elements";
import { Page } from "components/shared/layouts";
import SearchInput from "components/shared/searchinput";
import { BASE_URL } from "inc/constants";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import Swal from "sweetalert2";
import { removeEmpty } from "inc/functions";


const Style = styled.div`
    
`
const CategoryNew = ({className, match}) => {

	const history = useHistory();

    const [object, setObject] = useState();
    const [loading, setLoading] = useState(false);
	const [fields, setFields] = useState();
	const [categories, setCategories] = useState();

	const { id } = match.params;

	const permissions = useSelector(state => state.auth.permissions);
    
    useEffect(() => {

		if (id) {
			load();
		}
		getCategoryAPI({}).then(res => {
			setCategories(res?.data);
		});
    }, []);

	useEffect(() => {
		setFields([
			{
				label: 'نام دسته',
				name: 'name',
				required: true,
				defaultValue: object?.name
			},
			{
				label: 'عنوان دسته',
				name: 'title',
				required: true,
				defaultValue: object?.title
			},
			{
				type: 'select',
				label: 'دسته پدر',
				name: 'parentCategoryId',
				options: categories?.map(cat => ({
					label: cat.name,
					value: cat.id
				})),
				defaultValue: object?.parentCategoryId
				// required: true
			},
			{
				type: 'color',
				label: 'رنگ',
				name: 'color',
				defaultValue: object?.color,
				col: 4
			},
			{
				type: 'number',
				label: 'اولویت ترتیب',
				name: 'order',
				defaultValue: object?.order,
				col: 4
			},
			{
				type: 'image',
				label: 'آیکون',
				name: 'icon',
				defaultValue: object?.icon
			},
			{
				type: 'image',
				label: 'عکس دسته',
				name: 'image',
				defaultValue: object?.image
			},
			{
				type: 'textarea',
				label: 'توضیحات',
				name: 'description',
				required: true,
				defaultValue: object?.description,
				col: 1 
			},
			{
				type: 'checkbox',
				name: 'active',
				defaultValue: object?.active,
				inputLabel: 'دسته فعال است'
			},
			{
				label: ' ',
				type: 'submit',
				value: 'ذخیره',
				col: 1
			}
		])
	}, [categories, object]);


	const handleSubmit = data => {
		setLoading(true);
		submitCategoryAPI({data: removeEmpty(data), pk: id || null}).then(res=> {
			setLoading(false);
			Swal.fire({
				title: 'تبریک!',
				text: `دسته با موفقیت ${id ? 'ویرایش' : 'ایجاد'} شد.`,
				icon: 'success',
				confirmButtonText: 'باشه'
			});
			history.push(`/categories/${res.id}/edit/`);
		}).catch(err => {
			setLoading(false);
		})
	}

    const load = () => {
        setLoading(true);
        getCategoryAPI({pk: id}).then(res => {
            setObject(res);
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }

	const pageMeta = <>
		{id && permissions?.['delete-category'] && <Button 
					error
					small
					icon="trash"
					onClick={() => deleteCategoryAskAPI({
						pk: id, 
						name:'دسته', 
						success: () => history.push("/categories")
					})}>حذف</Button>}
		{id && permissions?.['create-category'] && (
			<Link to="/categories/new/">
				<Button icon="plus" small>جدید</Button>
			</Link>
			)}
		<Link to="/categories/">
			<Button small secondary icon="angle-left">بازگشت</Button>
		</Link>
	</>

    return (
        <Style className={className}>
            <Page title={id ? 'ویرایش دسته' : 'دسته جدید'} meta={pageMeta}>
                <Spin active={loading}>
					<DynamicForm fields={fields} block cols={2} onSubmit={handleSubmit} />
                </Spin>
            </Page>
        </Style>
    )
}

export default CategoryNew;