import { useSelector } from "react-redux";
import CategoryList from "./list";
import CategoryNew from "./new";
const { Switch, Route } = require("react-router")

const Categories = () => {

	const permissions = useSelector(state => state.auth.permissions);

    return (
        <Switch>
			{permissions?.categories && (
            	<Route path="/categories/" exact component={CategoryList} />
			)}
			{permissions?.['create-category'] && (
            	<Route path="/categories/new/" exact component={CategoryNew} />
			)}
			{permissions?.['update-category'] && (
	            <Route path="/categories/:id/edit/" exact component={CategoryNew} />
			)}
        </Switch>
    )
}

export default Categories;