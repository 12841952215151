import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { getAccessAPI } from "components/api/accesses";
import { getCategoryAPI } from "components/api/categories";
import { getCityAPI } from "components/api/cities";
import { getCompanyAPI } from "components/api/companies";
import { deleteRoleAskAPI, getRoleAPI, submitRoleAPI } from "components/api/roles";
import { getServiceAPI } from "components/api/services";
import { deleteUserAskAPI } from "components/api/users";
import { Avatar, Badge, Button, DynamicForm, DynamicFormView, Link, Modal, Pagination, Spin, Table, Tabs, Title } from "components/elements";
import { Page } from "components/shared/layouts";
import SearchInput from "components/shared/searchinput";
import { BASE_URL, WEBSITE_BASE_URL } from "inc/constants";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import Swal from "sweetalert2";


const Style = styled.div`
    
`
const RoleNew = ({className, match}) => {

	const history = useHistory();

    const [object, setObject] = useState();
    const [loading, setLoading] = useState(false);
	const [fields, setFields] = useState();
	const [accesses, setAccesses] = useState();
	const [services, setServices] = useState();
	const [companies, setCompanies] = useState();
	const [servicesAccessesType, setServicesAccessesType] = useState('all');
	const [companiesAccessesType, setCompaniesAccessesType] = useState('all');

	const { id } = match.params;
    
    useEffect(() => {

		if (id) {
			load();
		}

		getAccessAPI({}).then(res => {
			setAccesses(res?.data);
		});

		getServiceAPI({params: {limit: 100}}).then(res => {
			setServices(res?.data);
		})

		getCompanyAPI({params: {limit: 100}}).then(res => {
			setCompanies(res?.data);
		})
		
    }, []);

	useEffect(() => {

		const f = [
			{
				label: 'عنوان',
				name: 'description',
				required: true,
				defaultValue: object?.description,
				disabled: !!object,
			},
			{
				label: 'نام سیستمی',
				name: 'name',
				required: true,
				defaultValue: object?.name,
				disabled: !!object,
			},
			{
				type: 'select',
				label: 'شرکت',
				name: 'companyId',
				required: true,
				options: companies?.map(p => ({
					label: p.name,
					value: p.id
				})),
				defaultValue: object?.company?.id
			},
			{
				type: 'react-select',
				label: 'سطوح دسترسی',
				name: 'access',
				isMulti: true,
				closeMenuOnSelect: false,
				options: accesses?.map(acc => ({
					label: acc?.description,
					value: acc?.id
				})),
				defaultValue: object?.access.map(acc => ({
					label: acc?.description,
					value: acc?.id
				})),
				defaultOptions: false
			},
			{
				type: 'radio',
				label: 'دسترسی به خدمات',
				name: 'servicesAccess.type',
				defaultValue: servicesAccessesType,
				onChange: ev => setServicesAccessesType(ev),
				options: [
					{
						label: 'دسترسی به همه خدمات',
						value: 'all'
					},
					{
						label: 'دسترسی به برخی خدمات',
						value: 'custom'
					}
				]
			}
		]

		if (servicesAccessesType === 'custom') {
			f.push(
				{
					type: 'react-select',
					label: 'خدمات',
					name: 'servicesAccess.accesses',
					isMulti: true,
					closeMenuOnSelect: false,
					options: services?.map(acc => ({
						label: acc?.name,
						value: acc?.id
					})),
					defaultValue: object?.servicesAccess?.accesses.map(acc => ({
						label: acc?.name,
						value: acc?.id
					})),
					defaultOptions: false
				},
			)
		}

		f.push(
			{
				type: 'radio',
				label: 'دسترسی به شرکت‌ها (خدمت‌رسان‌ها)',
				name: 'companiesAccess.type',
				defaultValue: companiesAccessesType,
				onChange: ev => setCompaniesAccessesType(ev),
				options: [
					{
						label: 'دسترسی به همه شرکت‌ها',
						value: 'all'
					},
					{
						label: 'دسترسی به برخی شرکت‌ها',
						value: 'custom'
					}
				]
			}
		)

		if (companiesAccessesType === 'custom') {
			f.push(
				{
					type: 'react-select',
					label: 'شرکت‌ها',
					name: 'companiesAccess.accesses',
					isMulti: true,
					closeMenuOnSelect: false,
					options: companies?.map(acc => ({
						label: acc?.name,
						value: acc?.id
					})),
					defaultValue: object?.companiesAccess?.accesses.map(acc => ({
						label: acc?.name,
						value: acc?.id
					})),
					defaultOptions: false
				},
			)
		}

		f.push(
			{
				label: ' ',
				type: 'submit',
				value: 'ذخیره',
				col: 1
			}
		)

		setFields(f);

	}, [object, accesses, servicesAccessesType, services, companies, companiesAccessesType]);


	const handleSubmit = data => {
		setLoading(true);
		submitRoleAPI({data: data, pk: id || null}).then(res=> {
			setLoading(false);
			Swal.fire({
				title: 'تبریک!',
				text: `نقش با موفقیت ${id ? 'ویرایش' : 'ایجاد'} شد.`,
				icon: 'success',
				confirmButtonText: 'باشه'
			});
			history.push(`/roles/${res.id}/edit/`);
		}).catch(err => {
			setLoading(false);
		})
	}

    const load = () => {
        setLoading(true);
        getRoleAPI({pk: id}).then(res => {
            setObject(res);
			setServicesAccessesType(res?.servicesAccess?.type);
			setCompaniesAccessesType(res?.companiesAccess?.type);
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        })
    }

	const pageMeta = <>
		{id && <Button
			error
			small
			icon="trash"
			onClick={() => deleteRoleAskAPI({
				pk: id,
				name: 'نقش',
				success: () => history.push("/roles")
			})}>حذف</Button>}
		{id && <Link to="/roles/new/">
			<Button icon="plus" small>جدید</Button>
		</Link>}
		<Link to="/roles/">
			<Button small secondary icon="angle-left">بازگشت</Button>
		</Link>
	</>

    return (
        <Style className={className}>
            <Page title={id ? 'ویرایش نقش' : 'نقش جدید'} meta={pageMeta}>
                <Spin active={loading}>
					<DynamicForm fields={fields} block onSubmit={handleSubmit} />
                </Spin>
            </Page>
        </Style>
    )
}

export default RoleNew;