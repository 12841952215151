const { Input } = require("components/elements")

const SearchInput = ({defaultValue, onChange, ...otherProps}) => {

    const handleKeyDown = ev => {
        if (ev.keyCode === 13) {
            onChange(ev.target.value);
        }
    }

    return (
        <Input defaultValue={defaultValue ? decodeURI(defaultValue) : null} onKeyDown={handleKeyDown} placeholder="جستجو کنید." {...otherProps} />
    )
}

export default SearchInput;