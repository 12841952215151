import React, { useEffect, useReducer, useRef, useState } from 'react';
import styled from 'styled-components';
import {CONSTS}  from './_constants';
import {Icon, Label, Modal} from '.';
import Button from './button';
// import {DynamicCreateInner} from '../../scenes/dashboard/dynamic-create';
// import DateTimePicker from 'react-datetime-picker';
import { Text } from './typography';

export const commonStyles = (props, selector) => (`
    position: relative;
    ${props.block ? `
        width: 100%;
    ` : `
        width: max-content;
    `}
    > ._icon{}
    > ._icon{
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        color: ${CONSTS.colors.secondary};
        font-size: 13px;
    }
    ${selector}{
        background: #fff;
        padding: 8px 14px;
        width: 100%;
		min-height: 42.5px;
        font-size: 15px;
        ${
            props.icon ? `padding-left: 25px;` : ''
        }
        border: 1px solid #babacc;
        color: #000;
        border-radius: ${CONSTS.border.radius}px;
        box-sizing: border-box;
        margin-top: 3px;
        margin-bottom:3px;
        transition: border-color .2s, color .2s;

        &[type="number"] {
            -moz-appearance: textfield;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        &:focus ~ ._icon{
            color: ${CONSTS.colors.secondaryDark};
        }
        &:focus{
            outline: none;
            border-color: ${CONSTS.colors.primary};
            
        }
        &:disabled{
            opacity: 1;
            background-color: rgba(230, 230, 230, .4);
        }
        ::placeholder{
            color:#999;
        }
    }
    ${props.error ? `
        ${selector}, > ._icon{
            border-color: ${CONSTS.colors.error} !important;
            ::placeholder{
                color: ${CONSTS.colors.error};
            }
        }
    ` : ``}

`)

const Input = ({className, iref, icon, ...otherProps}) => {
    return (
        <div className={className}>
            <input ref={iref} {...otherProps}/>
            {icon && <Icon className="_icon" icon={icon} fa />}
        </div>
    )
}

const StyledInput = styled(Input)`
    ${props => commonStyles(props, "input")}
`;

const HiddenInput = ({className, iref, ...otherProps}) => (
    <input ref={iref} type="hidden" {...otherProps}/>
)
StyledInput.Hidden = HiddenInput;

const Textarea = ({className, iref, ...otherProps}) => (
    <div className={className}>
        <textarea ref={iref} {...otherProps}></textarea>
    </div>
)
const StyledTextarea = styled(Textarea)`
    ${props => commonStyles(props, "textarea")}
    > textarea{
        resize: vertical;
        min-height: 100px;
    }
`
StyledInput.Textarea = StyledTextarea;


const Select = ({className, iref, placeholder, options, defaultValue, ...otherProps}) => (
    <div className={className}>
        <select ref={iref} {...otherProps}>
            <option selected hidden value="">{placeholder ? placeholder : "انتخاب کنید"}</option>
            {options?.map((opt, i)=>(
                <option key={i} value={opt.value} selected={opt.value == defaultValue}>{opt.label}</option>
            ))}
        </select>
    </div>
)
const StyledSelect = styled(Select)`
    ${props => commonStyles(props, "select")}
`
StyledInput.Select = StyledSelect;


const Radio = ({className, options, disabled, iref, defaultValue, onChange, horizontal, ...otherProps}) => {
    // const def = props.value ? props.value : (props.defaultValue ? props.defaultValue : null);
    const [active, setActive] = useState();
    const handleChange = val => {
        if (disabled) return;
        setActive(val);
        iref.current.value = val;
        if (onChange) {
            onChange(val);
        }
    }
    useEffect(() => {
		if (defaultValue !== undefined) {
			setActive(defaultValue);
			iref.current.value = defaultValue;
		}
    }, [defaultValue]);

    return(
        <div className={`${className} ${disabled ? '--disabled' : ''} ${horizontal ? '--horizontal' : ''}`}>
            <input ref={iref} {...otherProps} type="hidden" />
            {options?.map((opt, i) => (
                <label className={`_item ${opt.value==active ? 'active' : ''}`} onClick={() => handleChange(opt.value)}>
                    <Icon className="_item__icon" fa icon={opt.value===active ? 'check-circle' : ['far', 'circle']} />
                    {opt.label}
                </label>
            ))}
        </div>
    )
}
const StyledRadio = styled(Radio)`
    /* ${props => commonStyles(props, "label")} */
    &.--disabled {
		opacity: .6;
        > label {
            /* background-color: rgba(0, 0, 0, .03) !important; */
			color: #999 !important;
        }
    }
    > input {
        /* display: none; */
    }
    > label{
        padding: 3px 0 3px 12px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    ._item {
        &__icon {
            /* display: none; */
            font-size: .9rem;
            margin-left: 8px;
        }
    }
    ._item.active{
        /* color: ${CONSTS.colors.primary}; */
        ._item__icon {
            color: ${CONSTS.colors.primary};
            /* display: inline-block; */
        }
    }
	&.--horizontal {
		display: flex;
		._item {

		}
	}
`
StyledInput.Radio = StyledRadio;

const Checkbox = ({className, label, iref, defaultValue, onChange, ...otherProps}) => {
    // const def = props.value ? props.value : (props.defaultValue ? props.defaultValue : "");
    const [value, setValue] = useState();

    useEffect(() => {
        if (typeof value !== "boolean" && typeof defaultValue==="boolean") {
            setValue(defaultValue);
        }
    }, [defaultValue]);

    useEffect(() => {
        if (otherProps.value !== undefined) {
            setValue(otherProps.value);
        }
    }, [otherProps.value])

    const handleChange = ev => {
        setValue(ev.target.checked);
        if (onChange) {
            onChange(ev);
        }
            
    }

    return(
        <div className={className}>
            <Label className="_item">
                <input type="checkbox" ref={iref} {...otherProps} defaultChecked={value} onClick={handleChange} />
                <Icon className="_icon" icon={value ? ["fas", "check-square"] : ["far", "square"]} />
                {label}
                {/* {value?.toString() || 'undefined'} */}
            </Label>
        </div>
    )
}
const StyledCheckbox = styled(Checkbox)`
    padding: 0;
    line-height: 1;
    > label{
        margin: 0;
        padding: 0;
        user-select: none;
        ${props => props.disabled ?  `
            color: #777;
        ` : `
            color: #333;
            cursor: pointer;
        `}
        & > ._icon {
            margin-left: 5px;
        }
        input{
            border: 1px solid;
            margin-top: 0;
            margin-bottom: 0;
            margin-left: 0;
            position: relative;
            width: 0;
            height: 0;
            opacity: 0;
            &:checked{
                & ~ ._icon {
                    color: ${CONSTS.colors.primary};
                }
            }
        }
    }
`
StyledInput.Checkbox = StyledCheckbox;

// const DateTime = (props) => (
//     <div className={props.className}>
//         <DateTimePicker {...props} className="_picker" />
//     </div>
// )

// const StyledDateTime = styled(DateTime)`
//     .react-datetime-picker__wrapper{
//         background: transparent;
//         padding: 15px 25px;
//         border: 1px solid rgba(100, 100, 100, .2);
//         color: #666;
//         border-radius: ${CONSTS.border.radius};
//         text-align: left;
//         font-size: 15px;
//         box-sizing: border-box;
//         margin-top:5px;
//         margin-bottom:5px;
//         :focus{
//             outline: none;
//             border-color: rgba(100, 100, 100, .4);
//             color: #333;
//         }
//         ::placeholder{
//             color:#999;
//         }
//     }
// `
// StyledInput.DateTime = StyledDateTime

export default StyledInput;