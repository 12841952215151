import React, {forwardRef} from 'react';
import styled from 'styled-components';
import { CONSTS } from './_constants';
import Icon from './icon';

const Button = forwardRef(({ className, children, icon, ...props }, ref) => {
    return (
        <button type="button" ref={ref} className={`${className} ${icon ? '--icon' : ''}`} {...props}>
            {icon && <Icon className={`__icon ${children ? `__icon--margin` : ''}`} icon={icon} />}
            {children}
        </button>
    )
})

Button.displayName = 'Button'


const StyledButton = styled(Button)`
    padding: 10px 12px;
    background-color: ${CONSTS.colors.primary};
    border: none;
    border-radius: ${CONSTS.border.radius}px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-family:inherit;
    cursor: pointer;
    transition: background-color .2s, color .2s;
    margin: ${props => props.block ? "5px 0" : "5px"};
    ${props => props.block ? `
        display: block;
        width: 100%;
    ` : ""}
    
    &:hover{
        background-color: ${CONSTS.colors.primaryDark};
    }
    &:focus{
        outline: none;
    }
    &.--icon{
        padding: 3px 8px;
    }
    .__icon {
        font-size: 12px;
        &--margin {
            margin-left: 5px;
        }
    }
    
    ${props => (`
        ${props.success ? `
            background: ${CONSTS.colors.success};
            &:hover{
                background: ${CONSTS.colors.successDark};
            }
        ` : ``}
		${
            props.sm
                ? `
            padding-top: 5px;
            padding-bottom: 5px;
        `
                : ``
        }
        ${props.error ? `
            background: ${CONSTS.colors.error};
            &:hover{
                background: ${CONSTS.colors.errorDark};
            }
        ` : ``}
        ${props.secondary ? `
            background: ${CONSTS.colors.secondary};
			color: #000;
            &:hover{
                background: ${CONSTS.colors.secondaryDark};
            }
        ` : ``}
		${
            props.light
                ? `
            background: #fff;
			border: 1px solid #ddd;
			color: var(--primary);
            &:hover{
                background: rgb(240, 240, 240);
            }
        `
                : ``
        }
        ${props.outline ? `
            background: transparent;
            border:2px solid ${CONSTS.colors.primary};
            color: ${CONSTS.colors.primary};
            &:hover{
                color: #fff;
            }
        ` : ``}
        ${props.link ? `
            border: none;
            background: transparent;
            padding: 5px 10px;
            color: ${CONSTS.colors.primary};
            &:hover{
                color: ${CONSTS.colors.primaryDark};
                background: transparent;
            }
        ` : ``}
        ${props.rounded ? `
            border-radius: 30px;
        ` : ``}
    `)}
`;

export default StyledButton;