import styled from 'styled-components';
import {Range}  from 'rc-slider'
import 'rc-slider/assets/index.css';
import { useState } from 'react';
import { Handle } from "rc-slider";

function MyHandle(props) {
  const { value, dragging, index,formatter=x=>x, ...rest } = props;

  return (
    <FlexHandle key={index} value={value} {...rest}>
      {dragging && <Value>{`${formatter(value)}`}</Value>}
    </FlexHandle>
  );
}
const FlexHandle = styled(Handle)`
  display: flex;
  justify-content: center;
`;
const Value = styled.div`
  margin-top: -32px;
  background-color: #eeeeee88;
  white-space: nowrap;
  border-radius: 10px;
  height: fit-content;
  padding: 5px;
  font-size: auto;
`;
const Style = styled.div`
    justify-self: center;
    padding: 12px 10px 12px 10px ;
`;
export const RangeSlider = ({ step, max, min, onChange, className,formatter, ...params }) => {
  return (
        <Style className={className}>
        <Range
         onChange={onChange} 
         step={step}
         tipFormatter={x=>x }
         max={max}
         min={min}
         defaultValue={[min, max]}
         handle={x=>MyHandle({...x,formatter:formatter})}
        />
        </Style>
        )   
}
export const Slider = ({ step, max, min, onChange, className, ...params }) => {
  return (
      <Style className={className}>
      <Range
       onChange={onChange} 
       step={step}
       tipFormatter={x=>x }
       max={max}
       min={min}
       defaultValue={(min+max)/2}
       handle={MyHandle}
      />
      </Style>
      )   
}