import styled from 'styled-components'


const Button = styled.button`
	${({primary}) => primary ? `
		background-color: #0033B6;
		color: #fff;
		border: 1px solid transparent;
		&:hover {
			background-color: #012b94;
		}
	` : `
		background-color: #fff;
		color: #0033B6;
		border: 1px solid #E0E0E0;
		&:hover {
			background-color: #f5f5f5;
		}
	`}
	${({small}) => small ? `
		padding: 4px 15px;
		font-size: 14px;
	` : `
		padding: 7px 25px;
		font-size: 18px;
	`}
	border-radius: 4px;
	cursor: pointer;
	transition: background-color .2s;
	margin: 4px;
`

export default Button;