import { getOrderAPI } from "components/api/orders";
import { Button } from "components/elements";
import { intComma } from "inc/functions";
import persianDate from "persian-date";
import styled from "styled-components";
import './print.css'

const { useEffect, useState } = require("react");

const Style = styled.div`
	max-width: 1080px;
	margin: 15px auto;
	table {
		border-collapse: collapse;
		width: 100%;
		table-layout: fixed;
		th, td {
			border: 1px solid #ccc;
			padding: 6px 10px;
		}
		th {
			font-weight: 600;
		}
		td {
			font-weight: normal;
		}
	}
	.date {
		font-size: 80%;
		padding: 6px;
	}
	.print-button {
		@media print {
			display: none;
		}
	}
`

const PrintOrderPage = ({ match }) => {
	
	const { id } = match.params;

	const [order, setOrder] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (id) {
			load();
		}
	}, [id]);

	const load = () => {
		setLoading(true);
		getOrderAPI({ pk: id })
		.then(res => {
			setOrder(res);
			setTimeout(() => {
				// window.print();
			}, 500);
		})
		.catch( err => {})
		.finally( () => setLoading(false) );
	}

	if (loading) {
		return <div>درحال بارگزاری...</div>
	}

	const {
		supportCode,
		orderDate,
		customer,
		serviceMan,
		address,
		service,
		amount
	} = order;

	return (
		<Style>
			<table>
				<tr>
					<td></td>
					<td></td>
					{/* <td></td>
					<td></td> */}
					<th className="text-center" colSpan={9} rowSpan={2}>
						صورتحساب فروش کالا و خدمات
					</th>
					{/* <td></td>
					<td></td>
					<td></td> */}
					<th>شماره فاکتور:</th>
					<td>{supportCode}</td>
				</tr>
				<tr>
					<td></td>
					<td></td>
					{/* <td></td>
					<td></td>
					<td></td>
					<td></td>
					<td></td> */}
					<th>تاریخ:</th>
					<td className="date">
						{new persianDate(new Date(orderDate)).toLocale('en').format("YYYY/M/D")}
					</td>
				</tr>
				<tr>
					<th className="text-center" colSpan={13}>
						مشخصات فروشنده
					</th>
				</tr>
				<tr>
					<td colSpan={3}>
						نام شخص حقيقي / حقوقي : کارآفرینان راه اندیشه کاراتوس
					</td>
					<td colSpan={3}>
						شماره اقتصادی : 411581373919
					</td>
					<td colSpan={7}>
						شماره ثبت/شماره ملی : 14007400858 
					</td>
				</tr>
				<tr>
					<td colSpan={3}>
						نشاني :  استان : خراسان رضوی                         
					</td>
					<td colSpan={3}>
						شهر :  مشهد 
					</td>
					<td colSpan={7}>
						آدرس: فلسطین ۱۸، پلاک ۲۴
					</td>
				</tr>
				<tr>
					<td colSpan={6}>
						کد پستی : 9185779314 
					</td>
					<td colSpan={7}>
						شماره تلفن / نمابر: 05137676070
					</td>
				</tr>
				<tr>
					<th className="text-center" colSpan={13}>
						مشخصات خریدار
					</th>
				</tr>
				<tr>
					<td colSpan={3}>
						نام شخص حقيقي / حقوقي : {customer?.firstName} {customer?.lastName}
					</td>
					<td colSpan={3}>
						شماره اقتصادی : -
					</td>
					<td colSpan={7}>
						شماره ثبت/شماره ملی : -
					</td>
				</tr>
				<tr>
					<td colSpan={3}>
						نشاني :  استان : خراسان رضوی
					</td>
					<td colSpan={3}>
						شهر :  مشهد 
					</td>
					<td colSpan={7}>
						نشاني : {address?.zone?.name}، {address?.content}
					</td>
				</tr>
				<tr>
					<td colSpan={6}>
						کد پستی : -
					</td>
					<td colSpan={7}>
						شماره تلفن / نمابر: {customer.cellphone}
					</td>
				</tr>
				<tr>
					<th className="text-center" colSpan={13}>
						مشخصات كالا يا خدمات مورد معامله
					</th>
				</tr>
				<tr className="text-center">
					<th>ردیف</th>
					<th>شرح کالا یا خدمات</th>
					<th>تعداد</th>
					<th>مبلغ واحد (ريال)</th>
					<th>مبلغ كل (ريال)</th>
					<th>مبلغ تخفيف</th>
					<th colSpan={2}>مبلغ كل پس از تخفيف (ريال)</th>
					<th colSpan={2}>جمع ماليات وعوارض (ريال)</th>
					<th colSpan={3}>جمع مبلغ كل بعلاوه جمع ماليات و عوارض (ريال)</th>
				</tr>
				{/* repeat this */}
				<tr className="text-center">
					<td>1</td>
					<td>{service.name}</td>
					<td>1</td>
					<td>{intComma((amount.total || 0)*10 + (amount.settlement?.discountAmount || 0 )*10)}</td>
					<td>{intComma((amount.total || 0)*10 + (amount.settlement?.discountAmount || 0)*10)}</td>
					<td>{intComma((amount.settlement?.discountAmount || 0)*10 || 0)}</td>
					<td colSpan={2}>{intComma(amount.total*10)}</td>
					<td colSpan={2}>{intComma((amount.settlement.taxAmount || 0) * 10)}</td>
					<td colSpan={3}>{intComma((amount.settlement.taxAmount || 0) * 10 + (amount.total || 0) * 10)}</td>
				</tr>

				<tr className="text-center">
					<td colSpan={4}>جمع کل</td>
					<td>{intComma((amount.total || 0)*10 + (amount.settlement?.discountAmount || 0)*10)}</td>
					<td>{intComma((amount.settlement?.discountAmount || 0)*10 || 0)}</td>
					<td colSpan={2}>{intComma(amount.total*10)}</td>
					<td colSpan={2}>{intComma((amount.settlement.taxAmount || 0) * 10)}</td>
					<td colSpan={3}>{intComma((amount.settlement.taxAmount || 0) * 10 + (amount.total || 0) * 10)}</td>
				</tr>
				<tr>
					<th colSpan={13}>شرايط و نحوه فروش: نقدي</th>
				</tr>
				<tr>
					<td colSpan={2} className="text-center">...............</td>
					<td colSpan={11} rowSpan={2} className="text-center">
						مهر و امضاء خریدار
					</td>
				</tr>
				<tr>
					<td colSpan={2} className="text-center py-4">
						مهر و امضاء فروشنده
					</td>
				</tr>
			</table>

			<div className="text-start print-button">
				<Button className="px-4" onClick={() => window.print()}>
					چاپ
				</Button>
			</div>
		</Style>
	)
}

export default PrintOrderPage;