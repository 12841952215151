import { parseJwt } from 'inc/functions';
import Cookies from 'js-cookie'

const initialState = {
    access: null,
    member: null,
	permissions: {},
    is_expired: null,
	loading: false
}

export const auth = (state = initialState, action) => {
    switch (action.type) {
		case 'UPDATE_ACCESS_TOKEN':
            localStorage.setItem('access', action.payload);
			return {
				...state,
				access: action.payload,
				is_expired: false
			}
			return
        case 'MEMBER_LOGIN':
            const { access } = action.payload;

            localStorage.setItem('access', access);

            return {
                ...state,
                access: access,
                is_expired: false
            }
            break;
        case 'MEMBER_LOGOUT':
            localStorage.removeItem('access');
            return {
                ...state,
                access: null,
				member: null,
				permissions: []
            }
            break;
		case 'START_LOADING':
			return { 
				...state,
				loading: true, 
			};
		case 'WHOAMI_SUCCESS':
			return {
				...state,
				loading: false,
				member: action.payload,
				permissions: Object.assign({}, ...action.payload.permissions?.map(p => ({[p.name]: true}))),
			};
		case 'END_LOADING':
			return {
				...state,
				loading: false
			};
        default:
            const storage_access = localStorage.getItem('access');

            return {
                ...state,
                access: storage_access,
                is_expired: storage_access ? parseJwt(storage_access)['exp'] < parseInt(Date.now() / 1000) : null
            }
            break;
    }
}