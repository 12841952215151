import axios from 'axios'
import { BASE_URL } from 'inc/constants'
import { getAPIDefaultHeaders,handleCatch, serialize} from 'inc/functions'

export const getDashboardAPI = ({params=null}) => {
    
    let url = `${BASE_URL}/dashboard?${serialize(params)}`;
    
    return axios.get(url, getAPIDefaultHeaders())
            .then(res => res.data)
            .catch(err => {
                handleCatch(err);
                throw err;
            } )
}