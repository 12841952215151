import { useSelector } from "react-redux";
import AdminList from "./list";
import AdminNew from "./new";
const { Switch, Route } = require("react-router")

const Admins = () => {

	const permissions = useSelector(state => state.auth.permissions);

    return (
        <Switch>
			{permissions?.admins && (
            	<Route path="/admins/" exact component={AdminList} />
			)}
			{permissions?.['create-admin'] && (
            	<Route path="/admins/new/" exact component={AdminNew} />
			)}
			{permissions?.['update-admin'] && (
            	<Route path="/admins/:id/edit/" exact component={AdminNew} />
			)}
        </Switch>
    )
}

export default Admins;