import React, { useState, useEffect, useRef } from "react";
import mapboxgl from 'mapbox-gl';
// import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
import styled from "styled-components";

import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { CONSTS } from "./_constants";
import { Button, Icon, Input, Modal } from ".";
import { MAPBOX_TOKEN } from "../../inc/constants";
import Swal from "sweetalert2";

mapboxgl.accessToken = MAPBOX_TOKEN;
mapboxgl.setRTLTextPlugin(
    'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
    null,
    true // Lazy load the plugin
    );
const dictToArrCoords = dict => {
    return [
        dict?.lng || 51.388973,
        dict?.lat || 35.689198
    ]
}
var picker_timeout;
const LocationPicker = ({ className, defaultValue, onChange, disabled }) => {
    const mapContainer = useRef(null);
    const defaultCenter = [51.388973, 35.689198];
    const [map, setMap] = useState();
    const [marker, setMarker] = useState();
    const [center, setCenter] = useState();
    useEffect(() => {
        let mapObj = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: defaultCenter,
            zoom: 13,
          });
        mapObj.addControl(
            new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                mapboxgl: mapboxgl,
                marker: false,
                zoom: 13,
                placeholder:'جستجو'
            })
        );
        mapObj.addControl(
            new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                trackUserLocation: true
            })
        );
        const mrkr = new mapboxgl.Marker()
            .setLngLat(defaultCenter)
            .addTo(mapObj);

        mapObj.on('move', async () => {
            handleMapMove(mapObj, mrkr);
        });

        setMap(mapObj);
        setMarker(mrkr);

    }, []);
    // useEffect(()=>{ if(map) map.transform._center=defaultValue},[defaultValue])

    useEffect(() => {
        if (!center || !map) return;
        map.flyTo({
            center: dictToArrCoords(center),
            zoom: 13,
            speed:2,
            curve:3
        });
    }, [center, map])

    useEffect(() => {
        if (!defaultValue) return
        setCenter(defaultValue);
        if (marker)
            marker.setLngLat(dictToArrCoords(defaultValue));
    }, [defaultValue, marker]);

    const handleMapMove = async (map, marker) => {
        clearTimeout(picker_timeout)
        if (disabled) {
            console.log("map disabled.")
            return
        };
        const cntr = map.getCenter();
        if (marker) {
            marker.setLngLat(cntr);
        }
        if (onChange) {
           picker_timeout= setTimeout(()=>onChange(cntr),300)
        }
    }

    return (
        <div className={className}>
            <div className='location-picker__map' ref={mapContainer}></div>
        </div>
    )
};

const StyledLocationPicker = styled(LocationPicker)`
    width: 100%;
    border: 1px solid transparent;
    .location-picker {
        &__map {
            height: 250px;
            width: 100%;
        }
    }
    ${props => props.error ? `
            border-color: ${CONSTS.colors.error} !important;
        }`
    : ``}
`


const ModalLocationPickerStyle = styled.div`
    display: flex;
    align-items: center;
    .modal-location {
        &__input {
            flex: 1;
        }
        &__icon {
            padding-left: 5px;
            color: ${CONSTS.colors.primary};
            cursor: pointer;
        }
    }
`
export const ModalLocationPicker = ({className, defaultValue, onChange, disabled, hasInput=true, ...otherProps}) => {
    const [modalIsActive, setModalIsActive] = useState(false);
    const [value, setValue] = useState({
        lat: null,
        lng: null,
        name: ''
    })

    const toggleModal = () => {
        setModalIsActive(x => !x);
    }

    useEffect(() => {
        setValue( val => ({
            ...val,
            ...defaultValue
        }))
    }, [defaultValue]);

    useEffect(() => {
        onChange(value);
    }, [value]);

    const handleClickIcon = () => {
        if (!value.lat && disabled) {
            Swal.fire({
                text: 'There is no active location',
            })
        } else {
            toggleModal()
        }
    }

    return (
        <>
            <Modal lg active={modalIsActive}>
                <StyledLocationPicker onChange={loc => setValue(val => ({...val, ...loc}))} disabled={disabled} defaultValue={defaultValue} />
                <Button onClick={e => {e.preventDefault(); toggleModal()}}>Close</Button>
            </Modal>
            <ModalLocationPickerStyle className={className}>
                {hasInput && <Input {...otherProps} onChange={ev => setValue(val => ({...val, name: ev.target.value}))} className="modal-location__input" disabled={disabled} value={value.name} />}
                <spin className="modal-location__icon" onClick={handleClickIcon}>
                    <Icon fa icon="location" />
                </spin>
            </ModalLocationPickerStyle>
        </>
    )
}

export default StyledLocationPicker;