import { useSelector } from "react-redux";
import CouponList from "./list";
import CouponNew from "./new";
const { Switch, Route } = require("react-router")

const Coupons = () => {

	const permissions = useSelector(state => state.auth.permissions);

    return (
        <Switch>
            <Route path="/coupons/" exact component={CouponList} />
			{permissions?.['create-coupon'] && (
            	<Route path="/coupons/new/" exact component={CouponNew} />
			)}
			{permissions?.['update-coupon'] && (
            	<Route path="/coupons/:id/edit/" exact component={CouponNew} />
			)}
        </Switch>
    )
}

export default Coupons;