import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import styled from 'styled-components';
import { CONSTS } from './_constants';

export const reactSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        fontSize: '14px',
        padding: '10px 12px',
    }),
    container: (s) => ({
        ...s,
        fontSize: '14px',
		fontWeight: '400',
        padding: '0',
        minWidth: '200px',
		marginTop: 3
    }),
    control: (s) => ({
        // none of react-select's styles are passed to <Control />
        ...s,
        padding: '0',
        minHeight: 0,
        borderColor: '#babacc',
        borderRadius: `${CONSTS.border.radius}px`
    }),
    valueContainer: (s) => ({
        ...s,
        padding: '6px 12px',
    }),
    indicatorsContainer: (s) => ({
        ...s,
        padding: '0',
    }),
    indicatorSeparator: s => ({
        ...s,
        display: 'none'
    }),
    input: s => ({
        ...s,
        // padding: '0 2px'
    })
}

const Style = styled.div`
	position: relative;
	max-width: 100%;
    [class$="indicatorContainer"] {
        svg {
            width: 15px !important;
            height: 15px !important;
            fill: #666;
        }
    }
	[class$="control"] {
		box-shadow: none !important;
		border-color: #babacc;
		&:hover {
			border-color: #babacc;
		}
		${({error}) => error ? `
			border-color: ${CONSTS.colors.error} !important;
		` : ''}
	}
`

const ReactSelect = ({disabled, defaultValue, options, iref, onChange, isMulti, ...otherProps}) => {
    const [value, setValue] = useState();

    const handleChange = opt => {
        setValue(opt);
		if (isMulti) {
			onChange(opt?.map(x => x.value));
		} else {
			onChange(opt.value);
		}
    }

    useEffect(() => {

        if (defaultValue===undefined || !options) return;

		if (isMulti) {
			setValue(defaultValue);
		} else {

			const def = options.find(opt => opt.value===defaultValue);
	
			if (!value) {
				setValue(def);
			}

		}

		
    }, [defaultValue, options, isMulti]);

    return (
        <Style {...otherProps}>
            <input type="hidden" ref={iref} value={value?.value} />
            <Select placeholder="انتخاب کنید" {...otherProps} isMulti={isMulti} styles={reactSelectStyles} isDisabled={disabled} value={value} onChange={handleChange} options={options} />
        </Style>
        
    )
}

export default ReactSelect;


export const AsyncReactSelect = ({ disabled, defaultValue, iref, onChange, optionLoader, ...otherProps }) => {
    const [value, setValue] = useState();

    const handleChange = opt => {
        setValue(opt);
        if (onChange)
            onChange(opt);
    }

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue])

	useEffect(() => {
        setValue(otherProps.value || null);
    }, [otherProps.value])
    
    return (
        <Style {...otherProps}>
            <AsyncSelect
                placeholder="انتخاب کنید"
                noOptionsMessage={({inputValue}) => 
                    inputValue.length ? 'هیچ نتیجه‌ای یافت نشد.' : 'جستجو کنید'
                }
                loadingMessage={() => "درحال جستجو..."}
                {...otherProps}
                styles={reactSelectStyles}
                isDisabled={disabled}
                onChange={handleChange}
                loadOptions={optionLoader}
                value={value}
            />
        </Style>

    )
}
