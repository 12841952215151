import { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ToolTip = (props) => {
    const Styles = styled.div`
    .tooltip{
        left:0%;
        outline: none;
        position: relative;
        margin:auto;
        width: fit-content;
        height: 30px;
        min-height: 30px;
        max-height: 255px;
        min-width: 75px;
        max-width: 555px;
        &__text{
            
            visibility: hidden;
            width: auto;
            background-color:  ${props.color} ;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            transform: translateX(-6%);
            margin:auto;

            padding: 5px;
            position: absolute;
            z-index: 1;
            bottom: 100%;
            margin:0;
            padding:0px 5px 0px 5px;
            width:fit-content;
            height:100%;
        }
        &__text::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: ${props.color} transparent transparent transparent;
        }
    }
    .tooltip:hover {
        .tooltip__text{
            visibility: visible;
        }
    }
    
    
    `
    return (
        <Styles className={props.className} >
            <div className='tooltip'>
                {props.face}
                <span className='tooltip tooltip__text' style={props.style}>
                    {props.children}
                </span>
            </div>
        </Styles>
    )



}
ToolTip.propTypes={
    face:PropTypes.element.isRequired,
    className:PropTypes.string
}
ToolTip.defaultProps={
    color:'grey'
}
export default ToolTip;