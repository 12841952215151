import {useState, useRef} from 'react';
import JoditEditor from "jodit-react";
import { useEffect } from 'react';
import { CONSTS } from './_constants';

const { default: styled } = require("styled-components");

const Style = styled.div`
	${({error}) => error ? `
		border: 1px solid ${CONSTS.colors.error};
	` : ''}
`

const JoditTextEditor = ({className, defaultValue, onUploadImage=undefined, onChange, ...otherProps}) => {

    const editor = useRef(null)
    const [content, setContent] = useState(defaultValue)

    const config = {
		direction: 'rtl',
		placeholder: 'شروع به نوشتن کنید...',
		// language: 'fa',
		i18n: {
			fa: {
				'URL': 'آدرس',
				'Alternative text': 'متن جایگزین',
				'Insert': 'افزودن',
				'Insert table': 'افزودن جدول',
				'Ok': 'تایید',
				'Select Special Character': 'کاراکترهای ویژه',
				'Search for': 'جستجو برای',
				'Paste as HTML': 'چسباندن به عنوان HTML',
				'Your code is similar to HTML. Keep as HTML?': 'متن کپی شده شبیه به HTML است. میخواهید آن را حفظ کنید؟',
				'Yes': 'بله',
				'Cancel': 'لغو',
				'Keep': 'نگه داشتن',
				'Insert as Text': 'افزودن به عنوان متن',
				'Insert only Text': 'افزودن متن تنها',
				'Link': 'لینک',
				'Code': 'کد',
				'Embed code': 'کد امبد',
				'Default': 'پیشفرض',
				'Circle': 'دایره',
				'Dot': 'نقطه',
				'Square': 'مربع',
				'Lower Alpha': 'آلفا کوچک',
				'Lower Greek': 'یونانی کوچک',
				'Lower Roman': 'رومانی کوچک',
				'Upper Alpha': 'آلفا بزرگ',
				'Upper Roman': 'رومانی بزرگ',
				'Normal': 'معمولی',
				'Heading 1': 'سرفصل ۱',
				'Heading 2': 'سرفصل ۲',
				'Heading 3': 'سرفصل ۳',
				'Heading 4': 'سرفصل ۴',
				'Quote': 'نقل قول',
				'Background': 'پس زمینه',
				'Text': 'متن',
				'Class name': 'نام کلاس',
				'Open in new tab': 'باز کردن در تب جدید',
				'No follow': 'نو فالو',
				'Preview': 'پیش نمایش',
				'Empty': 'خالی'
			}
		},
		minHeight: 400,
		zIndex: 1000,
        // readonly: false // all options from https://xdsoft.net/jodit/doc/
    }

	const handleStateChange = newContent => {
        if (onChange)
            onChange(newContent);
        setContent(newContent);
    }

    useEffect(() => {
        if (!defaultValue) return;

		setContent(defaultValue);
        
    }, [defaultValue]);

    return (
		<Style {...otherProps}>
			<JoditEditor
					ref={editor}
					value={content}
					config={config}
					tabIndex={1} // tabIndex of textarea
					onBlur={handleStateChange} // preferred to use only this option to update the content for performance reasons
					onChange={newContent => {}}
			/>
		</Style>
    );
}

export default JoditTextEditor;