import styled from 'styled-components';
import Nav from './nav';
import LogoImg from 'static/img/logo.png';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CONSTS } from 'components/elements/_constants';
import { useSelector } from 'react-redux';

const Styles = styled.div`
    position: relative;
    width: 290px;
    overflow: auto;
    overflow: hidden;
    background-color: rgb(240, 245, 241);
    padding: 30px 20px;
    .sidebar {
        &__first-nav {
            margin-bottom: 40px;
        }
        &__subtitle {
            margin-bottom: 20px;
			color: ${CONSTS.colors.primary};
        }
    }
`

const Sidebar = ({className}) => {
    const [isOpened, setIsOpened] = useState(true);

	const permissions = useSelector(state => state.auth.permissions);
    
    const toggleSidebar = () => {
        setIsOpened(!isOpened);
    };

    const admin = [
		{
            label: 'داشبورد',
			to: '/',
			visible: !!permissions?.dashboard
			// exact: true
        },
		{
			label: 'ثبت سفارش',
			to: '/orders/new/',
			visible: !!permissions?.['create-order']
		},
		{
			label: 'سفارشات',
			to: '/orders/',
			visible: !!permissions?.['orders']
		},
		{
			label: 'کدهای تخفیف',
			children: [
				{
					label: 'لیست کدهای تخفیف',
					to: '/coupons/',
					visible: !!permissions?.['coupons']
				},
				{
					label: 'کد تخفیف جدید',
					to: '/coupons/new/',
					visible: !!permissions?.['create-coupon']
				},
			]
		},
		{
			label: 'خدمات',
			children: [
				{
					label: 'همه خدمات',
					to: '/services/',
					visible: !!permissions?.services
				},
				{
					label: 'خدمت جدید',
					to: '/services/new/',
					visible: !!permissions?.['create-service']
				},
			]
		},
		{
			label: 'دسته بندی خدمات',
			children: [
				{
					label: 'دسته ها',
					to: '/categories/',
					visible: !!permissions?.categories
				},
				{
					label: 'دسته جدید',
					to: '/categories/new/',
					visible: !!permissions?.['create-category']
				},
			]
		},
		{
			label: 'کاربران',
			children: [
				{
					label: 'مشتریان',
					to: '/users/customer/',
					visible: !!permissions?.users
				},
				{
					label: 'خدمت رسان ها',
					to: '/users/serviceMan/',
					visible: !!permissions?.serviceMans
				},
				{
					label: 'نقش ها',
					to: '/roles/',
					visible: !!permissions?.roles
				},
				{
					label: 'مدیر ها',
					to: '/admins/',
					visible: !!permissions?.admins
				},
			]
		},
		{
			label: 'تراکنش ها',
			to: '/transactions/',
			visible: !!permissions?.transactions
		},
		{
			label: 'وب سایت',
			children: [
				{
					label: 'فرم همکاری',
					to: '/collaborations/',
					visible: !!permissions?.['collaboration-forms']
				},
				{
					label: 'تماس با ما',
					to: '/contactus/',
					visible: !!permissions?.['contact-us']
				},
				{
					label: 'سوالات متداول',
					to: '/faqs/',
					visible: !!permissions?.faqs
				},
			]
		},
		{
			label: 'مناطق',
			children: [
				{
					label: 'استان ها',
					to: '/provinces/',
					visible: !!permissions?.['provinces']
				},
				{
					label: 'شهرها',
					to: '/cities/',
					visible: !!permissions?.['cities']
				},
				{
					label: 'مناطق',
					to: '/zones/',
					visible: !!permissions?.zones
				},
			]
		},
		{
			label: "شرکت ها",
			to: '/companies/',
			visible: !!permissions?.companies
		}
    ]
      
    return (
        <Styles id='sidebar' className={className} isOpened={isOpened}>
            <h6 className="sidebar__subtitle">ادمین</h6>
            <Nav items={admin} isOpened={isOpened} />
        </Styles>
    )
}

export default Sidebar;