import styled from 'styled-components'

const Overlay = styled.div`
    position: fixed;
    z-index: ${props => props.zIndex ? props.zIndex : 10 };
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: opacity .15s;
    background-color: rgba(0, 0, 0, ${props => props.opacity ? props.opacity : .5});
    backdrop-filter: blur(4px);
    ${props => props.active ? `
        pointer-events: all;
        opacity: 1;
    ` : `
        pointer-events: none;
        opacity: 0;
    `}
`

export default Overlay;