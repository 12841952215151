import { getOrderAPI, ORDER_TIME_OPTIONS, submitOrderAPI } from "components/api/orders";
import { getTenderAPI, submitTenderAPI } from "components/api/tenders";
import { getUserAPI } from "components/api/users";
import { Button, DynamicForm, Spin, Table } from "components/elements";
import { Page } from "components/shared/layouts"
import { debounce, intComma, removeEmpty } from "inc/functions";
import persianDate from "persian-date";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { OrderEditTabs } from "./utils";

export const OrderTenders = ({ match }) => {

	const { id } = match.params;

	const [loading, setLoading] = useState(false);
	const [tenders, setTenders] = useState();
	const [order, setOrder] = useState();
	const [columns, setColumns] = useState([]);

    const [showForm, setShowForm] = useState(false);
	const [object, setObject] = useState();
	const [fields, setFields] = useState();
    const [serviceMan, setServiceMan] = useState();

	const permissions = useSelector(state => state.auth.permissions);

	const debouncedServiceManSearch = debounce((q) => getUserAPI({params: {cellphone: q}, throwCatch: false}).then(res => {
		if (res.data.length > 0) {
			setServiceMan(res.data[0]);
		} else {
			setServiceMan(null);
		}
	}), 500);


	useEffect(() => {
		setFields([
			{
				label: 'تلفن خدمت رسان',
				onChange: ev => {
					debouncedServiceManSearch(ev.target.value);
					return ev.target.value;
				},
				required: true,
				defaultValue: serviceMan?.cellphone,
			},
			{
				label: 'نام خدمت سان',
				disabled: true,
				defaultValue: serviceMan && `${serviceMan.firstName || ''} ${serviceMan.lastName || ''}`,
			},
			{
				type: 'pdate',
				label: 'تاریخ انجام',
				name: 'tenderDate',
				defaultValue: object?.tenderDate && new persianDate(new Date(object?.tenderDate)).toLocale('en').format("YYYY-MM-DD"),
				required: true,
			},
			{
				type: 'select',
				label: 'زمان انجام',
				name: 'tenderTime',
				options: ORDER_TIME_OPTIONS.map(opt => ({
					label: opt,
					value: opt,
				})),
				defaultValue: object?.tenderTime,
				required: true,
			},
			{
				type: 'price',
				label: 'دستمزد',
				name: 'prices.wages',
				defaultValue: object?.prices?.wages,
				required: true,
			},
			{
				type: 'price',
				label: 'هزینه کالا',
				name: 'prices.materials',
				defaultValue: object?.prices?.materials,
				required: true,
			},
			{
				type: 'price',
				label: 'مجموع هزینه',
				name: 'prices.estimated',
				defaultValue: object?.prices?.estimated,
				required: true,
			},
			{
				type: 'textarea',
				label: 'توضیحات',
				name: 'quoteDescription',
				defaultValue: object?.quoteDescription,
				col: 1
			},
			{
				label: ' ',
				type: 'submit',
				value: 'ذخیره',
				inputClassName: 'd-flex justify-content-end',
				block: false,
				before: <Button secondary onClick={() => {
					setShowForm(false);
					setObject(undefined);
				}}>لغو</Button>,
				col: 1,
			}
		])
	}, [object, serviceMan])

	useEffect(() => {
		setColumns([
			{
				title: 'خدمت رسان',
				dataIndex: 'serviceman',
				render: s => `${s?.firstName || ''} ${s?.lastName || ''}`
			},
			{
				title: 'زمان ثبت',
				dataIndex: 'createdAt',
				render: d => d && (new persianDate(d)).format("dddd D MMMM HH:mm")
			},
			{
				title: 'هزینه خدمات',
				dataIndex: 'prices',
				render: p => `${intComma(p?.wages)} ت`
			},
			{
				title: 'هزینه کالا',
				dataIndex: 'prices',
				render: p => `${intComma(p?.materials)} ت`
			},
			{
				title: 'مجموع هزینه',
				dataIndex: 'prices',
				render: p => <b>{intComma(p?.estimated)} ت</b>
			},
			{
				title: 'تاریخ حضور',
				dataIndex: 'tenderDate',
				render: d => (new persianDate(d?.split("-")?.map(x => parseInt(x)))).format("dddd D MMMM")
			},
			{
				title: 'زمان',
				dataIndex: 'tenderTime',
				render: t => t?.split("-")?.join(" الی ")
			},
			{
				title: '',
				dataIndex: 'id',
				render: (id, {serviceman}) => ['serviceman-pending', "customer-pending"].includes(order?.status) && (
					<Button success rounded icon="check" onClick={() => handleConfirmTender(id, serviceman.id)}>تایید</Button>
				)
			}
		])
	}, [order]);

	useEffect(() => {
		if (id) {
			loadTenders();
			loadOrder();
		}
	}, [id]);

	const loadTenders = () => {
		setLoading(true);
		getTenderAPI({ params: { orderId: id } }).then(res => {
			setTenders(res);
			setLoading(false);
		}).catch(err => {
			setLoading(false);
		})
	}

	const loadOrder = () => {
		getOrderAPI({ pk: id }).then(res => setOrder(res)).catch( err => {});
	}

	const handleConfirmTender = (tenderId, serviceManId) => {
		// first load the order, edit tenderId and update it

		Swal.fire({
			text: 'از تایید این پیشنهاد اطمینان دارید؟',
			icon: 'warning',
			confirmButtonText: 'بله',
			cancelButtonText: 'خیر',
			showCancelButton: true
		}).then(({isConfirmed}) => {

			if (!isConfirmed) return;

			setLoading(true);

			const data = {
				tenderId,
				serviceManId,
				status: 'service-pending',
			}

			submitOrderAPI({data: removeEmpty(data), pk: order.id})
			.then(res => {
				Swal.fire({
					icon: 'success',
					text: 'مناقصه باموفقیت ثبت شد.'
				});
				loadTenders();
			}).catch(err => setLoading(false));

		});

	}

	const handleSubmit = data => {
		if (!serviceMan) {
			Swal.fire({
				text: 'خدمت رسان انتخاب نشده است.',
				icon: 'warning',
				confirmButtonText: 'باشه'
			});
			return;
		}
		if (!data.tenderDate) {
			Swal.fire({
				text: 'تاریخ انتخاب نشده است.',
				icon: 'warning',
				confirmButtonText: 'باشه'
			});
			return;
		}

		data = {
			...data,
			orderId: id,
			servicemanId: serviceMan.id,
			tenderDate: new persianDate(data.tenderDate.split("-").map(x => parseInt(x))).toCalendar('gregorian').toLocale('en-US').format('YYYY-MM-DD')
		}

		setLoading(true);
		if (object?.id) {
			submitTenderAPI({data: removeEmpty(data, [null]), pk: object?.id}).then(res=> {
				setLoading(false);
				Swal.fire({
					text: `مناقصه با موفقیت ویرایش شد.`,
					icon: 'success',
					confirmButtonText: 'باشه'
				});
				setObject(undefined);
				loadOrder();
				loadTenders();
			}).catch(err => {
				setLoading(false);
			});
		} else {
			submitTenderAPI({data: removeEmpty(data, [null])}).then(res=> {
				setLoading(false);
				Swal.fire({
					text: `مناقصه با موفقیت ایجاد شد.`,
					icon: 'success',
					confirmButtonText: 'باشه'
				});
				setObject(undefined);
				loadOrder();
				loadTenders();
			}).catch(err => {
				setLoading(false);
			});
		}

	}

	return (
		<Page title={'مناقصات'}>
			<OrderEditTabs id={id} />
			<Spin active={loading}>
				{!showForm && permissions?.['create-tender'] && <Button link icon='plus' onClick={() => setShowForm(true)}>ثبت مناقصه</Button>}
				{showForm && <DynamicForm cols={2} fields={fields} block onSubmit={handleSubmit} />}
			</Spin>
			<Spin active={loading}>
				{tenders?.data?.length > 0 ? (
					<Table columns={columns} dataSource={tenders?.data} />
				) : (
					"هیچ مناقصه‌ای ثبت نشده است."
				)}
			</Spin>
		</Page>
	)
}