import React from 'react';
import styled from 'styled-components';
import { CONSTS } from './_constants';

const Flex = ({className, children, dir, align, justify, flex, ...otherProps}) => (
    <div className={className} {...otherProps}>
        {children}
    </div>
)
const StyledFlex = styled(Flex)`
    display: flex;
    ${props => 
        props.dir ? `flex-direction: ${props.dir};` : ''
    }
    ${props => 
        props.align ? `align-items: ${props.align};` : ''
    }
    ${props => 
        props.justify ? `justify-content: ${props.justify};` : ''
    }
    ${props => 
        props.flex ? `& > *{
            flex: ${props.flex};
        }`  : ''
    }
`;



const FlexItem = ({className, children, ...otherProps}) => (
    <div className={className} {...otherProps}>
        {children}
    </div>
)

const StyledFlexItem = styled(FlexItem)`

`;
StyledFlex.Item = StyledFlexItem;

export default StyledFlex;