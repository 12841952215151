import { Button, Link } from 'components/elements';
import { CONSTS } from 'components/elements/_constants';
import LogoImg from 'static/img/logo.png';
import Swal from 'sweetalert2';
import UserNav from './usernav';
const { default: styled } = require("styled-components");

const TopNavLogoStyle = styled.div`
    max-width: 120px;
    margin-left: 15px;
    img {
        width: 100%;
        height: 100%;
        object-fit: scale-down;
        object-position: right center;
    }
`

const TopNavMenuStyle = styled.ul`
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
        a {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 15px;
            margin: 0;
            color: ${CONSTS.colors.primary};
            font-weight: 400;
			text-decoration: none;
			&:hover {
				color: #000;
			}
        }
    }
`

const Style = styled.div`
    height: 65px;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
	position: relative;
    z-index: 1000;
    padding: 15px 20px;
    display: flex;
	box-shadow: 0 4px 4px rgba(0, 0, 0, .08);
`

const TopNav = ({className, userNav=true}) => {

    return (
        <Style id='navbar'>
            <TopNavLogoStyle>
                <img src={LogoImg} alt="لوگوی چی کارا" />
            </TopNavLogoStyle>
            {userNav && <UserNav className="me-auto" />}
        </Style>
    )
}

export default TopNav;