import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { getCategoryAPI } from "components/api/categories";
import { getCityAPI } from "components/api/cities";
import { deleteQuestionAskAPI, getQuestionAPI, submitQuestionAPI } from "components/api/questions";
import { deleteServiceAskAPI, getServiceAPI, submitServiceAPI } from "components/api/services";
import { deleteTransactionAskAPI, getTransactionAPI, submitTransactionAPI, submitUserManualCreditAPI } from "components/api/transaction";
import { deleteUserAskAPI, getUserAPI } from "components/api/users";
import { Avatar, Badge, Button, DynamicForm, DynamicFormView, Link, Modal, Pagination, Spin, Table, Tabs, Title } from "components/elements";
import { CONSTS } from "components/elements/_constants";
import { Page } from "components/shared/layouts";
import SearchInput from "components/shared/searchinput";
import { BASE_URL, WEBSITE_BASE_URL } from "inc/constants";
import { intComma, removeEmpty } from "inc/functions";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import Swal from "sweetalert2";
import { ServiceTabs, UserEditTabs } from "./utils";

const Style = styled.div`
    
`
const UserCredit = ({className, match}) => {

	const history = useHistory();

	const [object, setObject] = useState();
	const [user, setUser] = useState();
	const [transactions, setTransactions] = useState();
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(true);
	const [fields, setFields] = useState();
	
	const { id } = match.params;

	const permissions = useSelector(state => state.auth.permissions);
    
    useEffect(() => {
		
		loadTransactions()
		if (id) {
			loadUser();
		}
		
    }, [id]);

	useEffect(() => {
		setFields([
			{
				type: 'radio',
				label: 'نوع تراکنش',
				name: 'type',
				required: true,
				defaultValue: object?.type || 'increase',
				options: [
					{
						label: 'افزایش اعتبار',
						value: 'increase'
					},
					{
						label: 'کاهش اعتبار',
						value: 'decrease'
					},
				],
				horizontal: true,
			},
			{
				type: 'price',
				label: 'مبلغ',
				name: 'amount',
				defaultValue: object?.amount,
				required: true
			},
			{
				type: 'textarea',
				label: 'توضیحات',
				name: 'description',
				defaultValue: object?.description
			},
			{
				label: ' ',
				type: 'submit',
				value: 'ذخیره',
				inputClassName: 'd-flex justify-content-end',
				block: false,
				before: <Button secondary onClick={() => {
					setShowForm(false);
					setObject(undefined);
				}}>لغو</Button>
			}
		])
	}, [object]);


	const handleSubmit = data => {

		if (!data.amount || !data.type) {
			Swal.fire({
				text: 'لطفا تمامی موارد خواسته شده را وارد کنید.',
				icon: 'error',
				confirmButtonText: 'باشه'
			});
			return;
		}

		data = removeEmpty({
			...data,
			userId: id,
		})

		setLoading(true);
		if (object?.id) {
			submitTransactionAPI({data, pk: object?.id}).then(res=> {
				setLoading(false);
				Swal.fire({
					text: `تراکنش با موفقیت ویرایش شد.`,
					icon: 'success',
					confirmButtonText: 'باشه'
				});
				setObject(undefined);
				loadUser();
				loadTransactions();
			}).catch(err => {
				setLoading(false);
			});
		} else {
			submitUserManualCreditAPI(data).then(res=> {
				setLoading(false);
				Swal.fire({
					text: `تراکنش با موفقیت ایجاد شد.`,
					icon: 'success',
					confirmButtonText: 'باشه'
				});
				setObject(undefined);
				loadUser();
				loadTransactions();
			}).catch(err => {
				setLoading(false);
			});
		}

		return true;
	}

	const handleClickEdit = question => {
		setShowForm(true);
		setObject(question);
		window.scrollTo({top: 0});
	}

	const loadUser = () => {
		setLoading(true);
        return getUserAPI({pk: id}).then(res => {
			setUser(res);
			setLoading(false);
		}).catch(err => {
			setLoading(false);
		})
    }

	const loadTransactions = () => {
        setLoading(true);
        getTransactionAPI({params: {limit: 100, userId: id, target: "order-credit"}}).then(res => {
            setTransactions(res);
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }

	const columns = [
		{
			title: 'نوع',
			dataIndex: 'type',
			render: t => {
				if (t === "increase") {
					return <Badge success>افزایش</Badge>
				} else if (t === "decrease") {
					return <Badge warning>کاهش</Badge>
				}
			}
		},
		{
			title: 'مقدار',
			dataIndex: 'amount',
			render: a => intComma(a)
		},
		{
			title: 'توضیحات',
			dataIndex: 'description',
		},
		{
			title: '',
			dataIndex: 'id',
			render: (id, question) => (
				<>
					<Button small error icon="trash" onClick={() => deleteTransactionAskAPI({pk: id, success: () => {loadTransactions(); loadUser();}})} />
					{/* <Button small light icon="edit" onClick={() => handleClickEdit(question)} /> */}
				</>
			)
		}
	]

    return (
        <Style className={className}>
            <Page title="اعتبار کاربر">
				<UserEditTabs user={user} />
				<div className="my-3">
					موجودی فعلی: <Badge success>{intComma(user?.credit)} تومان</Badge>
				</div>
                <Spin active={loading}>
					{!showForm && permissions?.['create-transaction'] && <Button link icon='plus' onClick={() => setShowForm(true)}>ثبت تراکنش</Button>}
					{showForm && <DynamicForm theme="inline" fields={fields} block onSubmit={handleSubmit} />}
                </Spin>
				<Spin active={loading}>
					<Table columns={columns} dataSource={transactions?.data} />
				</Spin>
            </Page>
        </Style>
    )
}

export default UserCredit;