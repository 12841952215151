import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { getCategoryAPI } from "components/api/categories";
import { getCityAPI } from "components/api/cities";
import { getUserAPI } from "components/api/users";
import { deleteQuestionAskAPI, getQuestionAPI, submitQuestionAPI } from "components/api/questions";
import { deleteServiceAskAPI, getServiceAPI, getServiceServiceMansAPI, submitServiceAPI } from "components/api/services";
import { deleteUserAskAPI } from "components/api/users";
import { Avatar, Badge, Button, DynamicForm, DynamicFormView, Link, Modal, Pagination, Spin, Table, Tabs, Title } from "components/elements";
import { CONSTS } from "components/elements/_constants";
import { Page } from "components/shared/layouts";
import SearchInput from "components/shared/searchinput";
import { BASE_URL, WEBSITE_BASE_URL } from "inc/constants";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import Swal from "sweetalert2";
import { ServiceTabs } from "./utils";
import persianDate from "persian-date";

const Style = styled.div`
    
`
const ServiceServiceMans = ({className, match}) => {

	const history = useHistory();

	const permissions = useSelector(state => state.auth.permissions);

	const [serviceMans, setServiceMans] = useState();
    const [loading, setLoading] = useState(false);
	
	const { id } = match.params;
    
    useEffect(() => {
		
		if (id) {
			loadServiceMans();
		}
		
    }, [id]);

	const loadServiceMans = () => {
        setLoading(true);
		getServiceServiceMansAPI(id).then(res => {
			setServiceMans(res);
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }

	const columns = [
		{
			title: 'نام',
			dataIndex: 'firstName',
		},
		{
			title: 'نام خانوادگی',
			dataIndex: 'lastName',
		},
		{
			title: 'تلفن همراه',
			dataIndex: 'cellphone',
		},
		{
			title: 'کد ملی',
			dataIndex: 'details',
			render: d => d?.nationalCode,
		},
		{
			title: 'کاربر جدید',
			dataIndex: 'isNew',
			render: a => <FontAwesomeIcon className={a ? 'color-primary' : ''} icon={a ? 'check-square' : 'times'} />
		},
		{
			title: 'تاریخ ثبت نام',
			dataIndex: 'createdAt',
			render: d => new persianDate(new Date(d)).format("YYYY/M/D")
		},
		{
			title: '',
			dataIndex: 'id',
			render: (id, user) => !user.archive && (
				<>
					{permissions?.['update-user'] && (
						<Link to={`/users/${id}/edit/`}>
							<Button small secondary icon="edit" />
						</Link>
					)}
				</>
			)
		}
	]

    return (
        <Style className={className}>
            <Page title="خدمت‌رسان‌های خدمت">
				{id && <ServiceTabs id={id} />}
				<Spin active={loading}>
					<Table columns={columns} dataSource={serviceMans?.data} />
				</Spin>
            </Page>
        </Style>
    )
}

export default ServiceServiceMans;