import axios from 'axios'
import { BASE_URL } from 'inc/constants'
import { getAPIDefaultHeaders, getAuthHeader ,handleCatch, handleEntityDelete, serialize} from 'inc/functions'

const getOrderController = new AbortController();
let getOrderPending = false;
export const getOrderAPI = ({params=null, pk=null}) => {

	if (getOrderPending) {
		getOrderController.abort();
	}
    
    let url = `${BASE_URL}/orders${pk ? '/' + pk : ''}?${serialize(params)}`;

	getOrderPending = true;
    
    return axios.get(url, {...getAPIDefaultHeaders(), signal: getOrderController.signal})
            .then(res => {
				getOrderPending = false;
				return res.data;
			})
            .catch(err => {
				getOrderPending = false;
                handleCatch(err);
                throw err;
            } )
}

export const getOrdersStatusAPI = () => {
    
    let url = `${BASE_URL}/orders/ordersStatus`;
    
    return axios.get(url, getAPIDefaultHeaders())
            .then(res => res.data)
            .catch(err => {
                handleCatch(err);
                throw err;
            } )
}

export const submitOrderAPI = ({data={}, pk=null}) => {
    
    let api;
    let url = `${BASE_URL}/orders${pk ? '/' + pk  : ''}`;

    if (pk) {
        api = axios.put(url, data, getAPIDefaultHeaders())
    } else {
        api = axios.post(url, data, getAPIDefaultHeaders())
    }
    
    return api
        .then(res => res.data)
        .catch(err => {
            handleCatch(err);
            throw err;
        })
}

export const calculateOrderPriceAPI = ({ data = {}, pk = null }) => {
    
    let url = `${BASE_URL}/orders/calculateOrderPrices/${pk}`;

	return axios.put(url, data, getAPIDefaultHeaders())
        .then(res => res.data)
        .catch(err => {
            handleCatch(err);
            throw err;
        })
}

export const deleteOrderAskAPI = ({pk, ...params}) => {
    return handleEntityDelete({
        url: `/orders/${pk}`,
        name: 'سفارش',
        ...params
    })
}

export const orderStatusLabelMap = {
	'serviceman-pending': 'درجستجوی خدمت رسان',
	'customer-pending': 'منتظر تایید مشتری',
	'service-pending': 'تایید شده',
	'service-executing': 'درحال انجام',
	'service-done-payment-pending': 'منتظر پرداخت',
	// 'online-payment-canceled': 'پرداخت لغو شده',
	// 'online-payment-failed': 'پرداخت ناموفق',
	// 'cash-payment': 'پرداخت نقدی',
	'order-completed': 'اتمام یافته',
	'order-canceled': 'لغو شده',
	// 'reverse': 'رزرو شده',

	// //  not listed:
	// 'serviceman-wait': 'منتظر خدمت رسان',
	// 'payment-canceled': 'پرداخت لغو شده',
	// 'done': 'اتمام یافته'
}


export const orderPaymentTypes = {
	'cash': 'نقد',
	'online': 'آنلاین',
	'card': 'کارت به کارت'
}

export const orderPaymentStatuses = {
	'pending': 'در انتظار پرداخت',
	'done': 'پرداخت شده',
	'failed': 'پرداخت ناموفق',
	'canceled': 'لغو شده توسط کاربر',
	'reverse': 'برگشت داده شده'
}


export const ORDER_TIME_OPTIONS = [
	'8-10',
	'10-12',
	'12-14',
	'14-16',
	'16-18',
	'18-20'
]

export const ORDER_CREATEDWITH_OPTIONS = {
	'website': 'سایت',
	'admin-panel': 'پنل ادمین',
}