import React from 'react';
import styled from 'styled-components';

const StepperComp = ({className, steps, active = 0}) => {

    const cls = className;

    const Circle = () => <div className={"circle"}/>;
    const ActiveCircle = () => (<div className={"active-circle"}>
        <div className={"active-circle-inner"}/>
    </div>);
    const Connector = () => <div className={"connector"}/>;

    return (
        <div className={cls}>
            <div className={`${cls}__state`}>
                {steps.map((step, index) =>
                    <>
                        {index === active ?
                            <ActiveCircle/>
                            :
                            <Circle/>
                        }
                        {index !== steps.length - 1 && <Connector/>}
                    </>
                )}
            </div>
            <div className={`${cls}__body`}>
                {steps.map((step, index) =>
                    <div className={`${cls}__body__step ${index === active ? "active" : ""}`}>
                        {step}
                    </div>
                )}
            </div>
        </div>
    )
}

const circle = 24;
const activeCircle = 40;
const connectorHeight = 60;

const Stepper = styled(StepperComp)`

  
  
  display: flex;

  &__state {
    width: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  &__body {
    width: 340px;
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    
    &__step {
      height: ${circle}px;
      font: normal normal 300 24px/${circle}px TT Norms;
      color: #DFDFDF;
      
      &:not(:last-child) {
        margin-bottom: ${connectorHeight}px;
      }
      
      &.active {
        height: ${activeCircle}px;
        line-height: ${activeCircle}px;
        color: #fff;
      }
    }
  }


  .circle {
    width: ${circle}px;
    height: ${circle}px;
    background-color: #DFDFDF;
    border-radius: 50%;
  }

  .active-circle {
    width: ${activeCircle}px;
    height: ${activeCircle}px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &-inner {
      width: ${circle}px;
      height: ${circle}px;
      background-color: #545871;
      border-radius: 50%;
    }
  }

  .connector {
    width: 0;
    height: ${connectorHeight}px;
    border: 1px solid #9597A6;
  }
`

export default Stepper;