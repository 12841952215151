import TransactionList from "./list";
import TransactionNew from "./new";
const { Switch, Route } = require("react-router")

const Transactions = () => {
    return (
        <Switch>
            <Route path="/transactions/" exact component={TransactionList} />
            <Route path="/transactions/new/" exact component={TransactionNew} />
            <Route path="/transactions/:id/edit/" exact component={TransactionNew} />
        </Switch>
    )
}

export default Transactions;