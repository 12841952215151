import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { getCategoryAPI } from "components/api/categories";
import { getCityAPI } from "components/api/cities";
import { deleteServiceAskAPI, getServiceAPI, submitServiceAPI } from "components/api/services";
import { deleteUserAskAPI } from "components/api/users";
import { Avatar, Badge, Button, DynamicForm, DynamicFormView, Link, Modal, Pagination, Spin, Table, Tabs, Title } from "components/elements";
import { Page } from "components/shared/layouts";
import SearchInput from "components/shared/searchinput";
import { BASE_URL, WEBSITE_BASE_URL } from "inc/constants";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import Swal from "sweetalert2";
import { ServiceTabs } from "./utils";


const Style = styled.div`
    
`
const ServiceNew = ({className, match}) => {

	const history = useHistory();

    const [object, setObject] = useState();
    const [loading, setLoading] = useState(false);
	const [fields, setFields] = useState();
	const [categories, setCategories] = useState();
	const [cities, setCities] = useState();

	const { id } = match.params;

	const permissions = useSelector(state => state.auth.permissions);
    
    useEffect(() => {

		if (id) {
			load();
		}
		getCategoryAPI({}).then(res => {
			setCategories(res?.data);
		});
		getCityAPI({}).then(res => {
			setCities(res?.data);
		})
    }, []);

	useEffect(() => {
		setFields([
			{
				label: 'نام خدمت',
				name: 'name',
				required: true,
				defaultValue: object?.name
			},
			{
				type: 'react-select',
				label: 'دسته بندی',
				name: 'categoryId',
				options: categories?.map(cat => ({
					label: cat.name,
					value: cat.id
				})),
				defaultValue: object?.category?.id,
				required: true
			},
			{
				type: 'select',
				label: 'شهر',
				name: 'cityId',
				options: cities?.map(city => ({
					label: city.name,
					value: city.id
				})),
				defaultValue: object?.city?.id,
				required: true
			},
			// {
			// 	type: 'color',
			// 	label: 'رنگ',
			// 	name: 'visualizeDetail.color',
			// 	defaultValue: object?.visualizeDetail?.color,
			// 	col: 4
			// },
			{
				label: ' ',
				inputLabel: 'خدمت فعال',
				type: 'checkbox',
				className: `mt-4 pe-3 ${object ? '' : 'opacity-0'}`,
				name: 'active',
				defaultValue: object?.active,
				disabled: !object,
				col: 2
			},
			{
				type: 'image',
				label: 'آیکون',
				name: 'visualizeDetail.icon',
				defaultValue: object?.visualizeDetail?.icon,
				col: 4
			},
			{
				type: 'image',
				label: 'عکس تامبنیل',
				name: 'visualizeDetail.thumbnailImage',
				defaultValue: object?.visualizeDetail?.thumbnailImage,
				col: 4/1.5
			},
			// {
			// 	type: 'image',
			// 	label: 'عکس عمودی',
			// 	name: 'visualizeDetail.verticalImage',
			// 	defaultValue: object?.visualizeDetail?.verticalImage
			// },
			{
				type: 'image',
				label: 'عکس هدر',
				name: 'visualizeDetail.headerImage',
				defaultValue: object?.visualizeDetail?.headerImage,
				col: 4/1.5
			},
			// {
			// 	type: 'textarea',
			// 	label: 'توضیحات کامل',
			// 	name: 'descriptions.long',
			// 	// required: true,
			// 	defaultValue: object?.descriptions?.long,
			// 	col: 1,
			// 	rows: 8
			// },
			{
				label: 'توضیحات کوتاه (روی باکس)',
				name: 'descriptions.short',
				defaultValue: object?.descriptions?.short,
				col: 1
			},
			// {
			// 	type: 'textarea',
			// 	label: 'توضیحات بنر',
			// 	name: 'descriptions.banner',
			// 	defaultValue: object?.descriptions?.banner
			// },
			{
				type: 'textarea',
				label: 'توضیحات متا',
				name: 'descriptions.bot',
				defaultValue: object?.descriptions?.bot,
				col: 1
			},
			// {
			// 	type: 'textarea',
			// 	label: 'توضیحات خدمت',
			// 	name: 'descriptions.service',
			// 	defaultValue: object?.descriptions?.service
			// },
			{
				type: 'texteditor',
				label: 'اطلاعات مهم',
				name: 'importantDetails.information',
				defaultValue: object?.importantDetails?.information,
				required: true,
				col: 1
			},
			// {
			// 	type: 'textarea',
			// 	label: 'نوشته مهم',
			// 	name: 'importantDetails.notes',
			// 	defaultValue: object?.importantDetails?.notes
			// },
			// {
			// 	label: 'گارانتی شرکت',
			// 	name: 'corporateWarranty',
			// 	col: 1,
			// 	defaultValue: object?.corporateWarranty
			// },
			{
				label: 'کد خدمت',
				name: 'serviceCode',
				col: 4,
				defaultValue: object?.serviceCode,
				required: true
			},
			{
				label: 'کد حسابداری',
				name: 'accountingCode',
				col: 4,
				defaultValue: object?.accountingCode,
				required: true
			},
			// {
			// 	type: 'number',
			// 	label: 'کد پشتیبانی',
			// 	name: 'supportCode',
			// 	col: 4
			// },
			{
				type: 'number',
				label: 'اولویت ترتیب',
				name: 'order',
				defaultValue: object?.order,
				required: true,
				col: 4
			},
			{
				type: 'checkbox',
				className: 'd-flex align-items-center mt-4',
				inputLabel: 'خدمت جدید',
				name: 'isNew',
				defaultValue: object?.isNew,
				col: 4
			},
			{
				label: ' ',
				type: 'submit',
				value: 'ذخیره',
				col: 1
			}
		])
	}, [categories, cities, object]);


	const handleSubmit = data => {

		if (!object) {
			delete data['active'];
			delete data['isNew'];
		}
		data = {
			...data,
			corporateWarranty: data?.corporateWarranty || '-',
			visualizeDetail: {
				...data?.visualizeDetail,
				color: data?.visualizeDetail?.color || ''
			},
			descriptions: {
				...data.descriptions,
			}
		}
		setLoading(true);
		submitServiceAPI({data: data, pk: id || null}).then(res=> {
			setLoading(false);
			Swal.fire({
				title: 'تبریک!',
				text: `خدمت با موفقیت ${id ? 'ویرایش' : 'ایجاد'} شد.`,
				icon: 'success',
				confirmButtonText: 'باشه'
			});
			history.push(`/services/${res.id}/edit/`);
		}).catch(err => {
			setLoading(false);
		})
	}

    const load = () => {
        setLoading(true);
        getServiceAPI({pk: id}).then(res => {
            setObject(res);
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }

	const pageMeta = <>
		{id && <Link target="_blank" href={`${WEBSITE_BASE_URL}/service/${id}/`}>
			<Button small success icon="eye">نمایش</Button>
		</Link>}
		{id && permissions?.['delete-service'] && <Button 
					error
					small
					icon="trash"
					onClick={() => deleteServiceAskAPI({
						pk: id, 
						name:'خدمت', 
						success: () => history.push("/services")
					})}>حذف</Button>}
		{permissions?.['create-service'] && id && <Link to="/services/new/">
					<Button icon="plus" small>جدید</Button>
			</Link>}
		<Link to="/services/">
			<Button small secondary icon="angle-left">بازگشت</Button>
		</Link>
	</>

    return (
        <Style className={className}>
            <Page title={id ? 'ویرایش خدمت' : 'خدمت جدید'} meta={pageMeta}>
				{id && <ServiceTabs id={id} />
}                <Spin active={loading}>
					<DynamicForm fields={fields} block cols={2} onSubmit={handleSubmit} />
                </Spin>
            </Page>
        </Style>
    )
}

export default ServiceNew;