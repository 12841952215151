import React,{useState,useEffect, useRef, createRef} from 'react';
import styled from 'styled-components';
// import { PreviewApi } from "@zzwing/react-image";
import Compressor from 'compressorjs';
import { Input, Button, Label, List, FormImage, FormUploader, Modal, LocationPicker, FormFileViewer, ReactSelect, ImageLibrary, FileLibrary, AsyncReactSelect, DatePicker, MultiCheckbox, TextEditor, JoditTextEditor } from '.';

function nestData(data) {
    var arr = {};
	Object.keys(data).forEach(key => {
		var parts = key.split(".");
        var last = parts.pop();
        var cursor = arr;
        parts.forEach(function(part){
            if(!cursor[part]) cursor[part] = {};
            cursor = cursor[part];
        });
        cursor[last] = data[key];
	})
    return arr;
}


const convertBase64 = (file) => {
	return new Promise((resolve, reject) => {
		const fileReader = new FileReader();
		fileReader.readAsDataURL(file)
		fileReader.onload = () => {
			resolve(fileReader.result);
		}
		fileReader.onerror = (error) => {
			reject(error);
		}
	})
}

const Form = styled.form`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .formitem{
        margin-top: 7px;
        margin-bottom: 7px;
        padding-left: 5px;
        padding-right: 5px;
        @media (max-width: 767.98px) {
            width: 100% !important;
            padding-left: 4px;
        }
        &__label{
            font-size: 14px;
            color: #747487;
            font-weight: 400;
            margin-top: 4px;
            margin-bottom: 4px;
            padding-right: 5px;
        }
        &__input {
        }
        ._imgs {
            overflow: hidden;
        }
    }
    .submit {
        display: none
    }
    ${({theme}) => {
        switch (theme) {
            case 'inline':
                return `
                    .formitem {
                        // padding-left: 40px;
                        display: flex;
                        &__label {
                            // flex: 1;

                            width: 200px;
                            padding-right: 0;
                        }
                        &__input {
                            flex: 1;
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                        }
                    }
                `
            default:
                return `
                    .formitem {
                        &__label {

                        }
                        &__input {
                            
                        }
                    }
                `
                break;
        }
    }}
`
const DynamicForm = ({fields, onSubmit, onChange, cols, onError, onUploadMedia, theme="default", block, resetAfterSubmit=true, autoSubmit=true, ...otherProps}) => {
    /**
     * `fields` prop has array of:
     * {type, name, placeholder, defaultValue, disabled, required, value}
     *  - onUploadMedia()
     */
    const [options, setFields] = useState();

    const formRef = useRef();

    const initFields = (flds, force=false) => {
        const initFields = flds.map(fld => {
            const prev_field = options?.find(prvfld => prvfld.name===fld.name);
            const x = {
                ...fld,
                _ref : createRef(),
                error: false,
            }
            switch (fld.type) {
                case 'image':
                case 'location':
                case 'file':
                case 'multi-checkbox':
                case 'imagelibrary':
                case 'pdate':
                case 'async-react-select':
				case 'react-select':
				case 'color':
				case 'texteditor':
                // case 'filelibrary':
                    if ((prev_field?.value===undefined && fld.defaultValue) || force) {
                        x['value'] = fld.defaultValue
                    } else if (prev_field) {
                        x['value'] = prev_field.value;
                    }
                    break;
				case "price":
					if (fld?.defaultValue) {
						x["defaultValue"] = fld.defaultValue
							.toString()
							.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
					}
                default:
                    break;
            }
            return x
        });

        setFields(initFields);
    }

    useEffect(() => {
        if(fields) {
            initFields(fields);
        }
    }, [fields]);

    const getFieldType = (field, index) => {
        let input = null;
        let inputLabel = null;

        const {_ref, type, className, errors, label, ...attrs} = field;
        if (attrs['block'] === undefined && typeof block === "boolean") {
            attrs['block'] = block;
        }
        attrs['iref'] = _ref;
        attrs['id'] = 'id_' + attrs['name'];
        attrs['onChange'] = (e) => handleChange(e, index);
        attrs['key'] = field?.key || index;

        switch(field.type){
            case 'textarea':
                input = <Input.Textarea {...attrs} />
                break;
            case 'checkbox':
                input = <Input.Checkbox {...attrs} label={field?.inputLabel}/>
                break;
            case 'async-react-select':
                delete attrs['value'];
                input = <AsyncReactSelect {...attrs} />
                break;

            case 'react-select':
                // delete attrs['iref'];
                delete attrs['value'];
                input = <ReactSelect {...attrs} />
                break;
            case 'select':
                input = <Input.Select {...attrs} />
                break;
            case 'radio':
                input = <Input.Radio {...attrs} />
                break;
            case 'multi-checkbox':
                input = <MultiCheckbox {...attrs} onChange={val => handleChange(val, index)} />;
                break;
            case 'location':
                input = <LocationPicker {...attrs} onChange={val => handleChange(val, index)} />;
                break;
            case 'submit':
                // if (props.submit===false) return;
                input = <Button block={true} {...attrs} onClick={handleSubmit}>{field.value}</Button>;
                break;
            case 'button':
                input = <Button block={true} {...attrs}>{field.value}</Button>;
                break;
			case 'texteditor':
				input = <JoditTextEditor {...attrs} />
				// input = <TextEditor onUploadImage={onUploadMedia} {...attrs}/>
				break;
            case 'file':
                delete attrs['onChange'];
                input = 
                    <div key={index} className="_imgs">
                        {field.value && field.value.file ? 
                            <FormFileViewer {...attrs} value={field.value.file} onDelete={e => _handleImageRemove(e, index)} />
                        :
                            <FormUploader {...attrs} onChange={(e)=>_handleImageChange(e,index, 'file')} small={true} height={26}/>
                        }
                        
                    </div>;
                break;
            case 'image':
                delete attrs['onChange'];
                input = 
                    <div key={index} className="_imgs">
                        {field.value ? 
                            <FormImage value={field.value} onDelete={(e) => _handleImageRemove(e, index)} />
                        :
                            <FormUploader {...attrs} onChange={(e)=> _handleImageChange(e , index)} fileTypes={['jpg', 'png']}/>
                        }
                        
                    </div>
                break;
            case 'imagelibrary':
                input = <ImageLibrary images={field.value?.map(img => ({...img, src: img.thumbnail, url: img.file}))} onUpload={(f) => handleImageLibraryUpload(f, index)} />
                break;
            case 'hidden':
                input = <Input.Hidden {...attrs} />
                break;
            // case 'datetime':
            //     input = <Input.DateTime {...attrs}/>
            //     break;
            case 'pdate':
                input = <DatePicker {...attrs} />
                break;
            case 'custom':
                if (typeof field.render === "function") {
                    input = field.render();
                } else if (typeof field.render !== "undefined") {
                    input = field.render
                }
                break;
            default:
                input = <Input {...attrs} type={type} />
                break;
        }

        if(label){
            inputLabel = <label className="formitem__label" htmlFor={field?.name ? "id_" + field.name : ""}>{field.label}{field.required && '*'}</label>
        }
        if(errors && errors.length > 0){
            input = <>
                {input}
                {<List small error items={field.errors} />}
            </>
        }
        const col = field.col || cols || 1;
        switch (field.type) {
            case 'hidden':
                return input
                break;
            default:
                return (
                    <div key={field?.key || index} className={`formitem ${className || ''}`} style={{width: `${100/col}%`}}>
                        {inputLabel}
                        {input && 
							<div className={`formitem__input ${field?.inputClassName || ''}`}>{field?.before}{input}{field?.after}</div>
						}
                    </div>
                )
                break;
        }
    }
    
    // const deleteImage = (valueIndex,imageIndex)=> {
    //     let tempFields = [...fields];
    //     tempFields[valueIndex].value.splice(imageIndex,1);
    //     setFields(tempFields);
    // }

    const serializerData = (tempFields) => {

        if (!tempFields) {
            tempFields = [...options]
        }

        var can_submit = true;
        var data = {};

        tempFields.map( (field, field_index) => { //TODO: how is images?
            if (!field.name) return;
            switch(field.type){
                case 'file':
                case 'image':
					if (field?.value)
	                    data[field.name] = field?.value || null;
                    break;
                case 'imagelibrary':
                    data[field.name] = field.value ? (field.value || []).map(x => x.id) : null;
                    break;
                case 'checkbox':
                    data[field.name] = field._ref.current.checked;
                    break;
                case 'number':
                    data[field.name] = parseInt(field._ref.current.value) || null;
                    break;
				case "price":
					data[field.name] =
						field._ref?.current?.value?.replace(/\D/g, "") || null;
					break;
                case 'pdate':
                case 'multi-checkbox':
                case 'location':
                case 'location-modal':
					case 'texteditor':
						data[field.name] = field.value || null;
						break;
				case 'react-select':
					data[field.name] = Array.isArray(field.value) ? field.value.map(x => x.constructor === Object ? x.value : x) : field.value;
					break;
                case 'async-react-select':
                    if (field.isMulti) {
                        data[field.name] = field.value.map(opt => opt.value) || [];
                    } else {
                        data[field.name] = field.value?.value || null;
                    }
                    
                    break;
                default:
                    data[field.name] = field._ref.current.value || null;
                    break;
            }
            if(field.number) 
				data[field.name]= +data[field.name];
			if (data[field.name] === "true") {
				data[field.name] = true;
			} else if (data[field.name] === "false") {
				data[field.name] = false;
			}
            if(field.required && data[field.name]===undefined){
                // alert(field.name);
                tempFields[field_index].error = true;
                // tempFields[field_index].errors = ["پرکردن این فیلد اجباری است."];
                can_submit = false;
            } else {
                tempFields[field_index].error = false;
                // tempFields[field_index].errors = [];
            }
        });
        // tempFields.find(f => f.error===true)?._ref.current.focus();
        if(!can_submit){
            setFields(tempFields);
            if(onError){
                onError(1, "Please fill all required fields and try again");
            }
            return;
        }
        
        return nestData(data);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = serializerData();

        if(onSubmit && data) {
            // TODO: return Promise for resolve
            const res = onSubmit(data);
            if (res && resetAfterSubmit) {
                resetForm();
            }
        };
    }

    const handleChange = (ev, index) => {
        setFields(y => {
            const tempFields = [...y];
            const { error, type } = tempFields[index];
            if (error) {
                tempFields[index].error = false;
            }
            if (tempFields[index]['onChange']){
                const return_value = tempFields[index]['onChange'](ev, index);
                switch(type) {
                    case 'file':
                    case 'image':
					case 'multi-checkbox':
					case 'async-react-select':
                        tempFields[index].value = return_value;
                        break;
                    case 'checkbox':
                        if (tempFields[index]._ref) {
                            tempFields[index]._ref.current.checked = return_value;
                        }
                        break;
                    default:
                        if (tempFields[index]._ref?.current) {
                            tempFields[index]._ref.current.value = return_value;
                        }
                        break;
                }
            } else {
				switch(type){
					case 'color':
                        tempFields[index].value = ev.target.value;
						break;
                    case 'file':
                    case 'image':
                    case 'location':
                    case 'location-modal':
                    case 'multi-checkbox':
                    case 'pdate':
                    case 'async-react-select':
					case 'texteditor':
                    case 'react-select':
                        tempFields[index].value = ev;
                        break;
					case "price":
						// case 'react-select':
						tempFields[index].value = ev.target.value
							.replace(/\D/g, "")
							.toString()
							.replace(/\B(?=(\d{3})+(?!\d))/g, "،");
						break;
                    // case 'react-select':
                    //     if (tempFields[index].isMulti) {
                    //         tempFields[index].value = ev?.map(opt => opt.value) || [];
                    //     } else {
                    //         tempFields[index].value = ev?.value || null;
                    //     }
                        
                    //     break;
                }
            }

            if (onChange) {
                onChange(serializerData(tempFields));
            }

            return(tempFields);
        });
    }
    const _handleImageChange = (e,index, type='image') =>{
        e.preventDefault();
        
        if(!e.target.files[0]) return;

        // let type = e.target.files[0].type.toLowerCase();
        // if(!(type== "image/jpeg" || type== "image/png")) {
        //     return alert('پسوند فایل بارگزاری شده مجاز نمی باشد') //TODO: Handle alert
        // }
        let file = e.target.files[0];
        const tempFields = [...options];
        tempFields[index]['isUploading'] = true;
        setFields(tempFields);
        // if (type === 'image') {
        //     new Compressor(file, {
        //         quality: 0.8,
        //         maxWidth: 1200,
        //         maxHeight: 1200,
        //         success(compressed) {
        //             onUploadMedia(compressed, type)
        //                 .then(obj => {
        //                     tempFields[index]['isUploading'] = false;
        //                     setFields(tempFields)
        //                     handleChange(obj, index);
        //                 })
        //                 .catch(err => {
        //                     tempFields[index]['isUploading'] = false;
        //                     setFields(tempFields)
        //                 });
        //         },
        //         error(err) {
        //         },
        //     });
        // } else {
			convertBase64(file).then(obj => {
				tempFields[index]['isUploading'] = false;
                setFields(tempFields)
                handleChange(obj, index);
			}).catch(err => {
                tempFields[index]['isUploading'] = false;
                setFields(tempFields)
            });
            
        // }
    }
    const _handleImageRemove = (e, index) => {
        e.preventDefault();
        handleChange(null, index);
    }
    const handleImageLibraryUpload = (file, index) => {
        onUploadMedia(file, 'image').then(media => {
            setFields(tempFields => tempFields.map((fld, fi) => {
                if (fi===index) {
                    return {
                        ...fld,
                        value: [media, ...(fld.value || [])]
                    }
                } else {
                    return fld;
                }
            }))
        })
    }

    const resetForm = () => {
        formRef.current.reset();
        initFields(fields, true);
    }

    return (
        <Form ref={formRef} method="POST" onSubmit={handleSubmit} theme={theme} {...otherProps}>
            {autoSubmit && <input type="submit" className="submit" />}
            {options?.map((field, k) =>
                getFieldType(field, k)
            )}
        </Form>
  );
}

export default DynamicForm;