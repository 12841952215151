import axios from 'axios'
import { BASE_URL } from 'inc/constants'
import { getAPIDefaultHeaders, getAuthHeader ,handleCatch, handleEntityDelete, serialize} from 'inc/functions'

export const getCommentAPI = ({params=null, action=null, pk=null}) => {
    
    let url = `${BASE_URL}/comments${action ? '/' + action : ''}${pk ? '/' + pk : ''}?${serialize(params)}`;
    
    return axios.get(url, getAPIDefaultHeaders())
            .then(res => res.data)
            .catch(err => {
                handleCatch(err);
                throw err;
            } )
}

export const submitCommentAPI = ({data={}, pk=null}) => {
    
    let api;
    let url = `${BASE_URL}/comments${pk ? '/' + pk  : ''}`;

    if (pk) {
        api = axios.put(url, data, getAPIDefaultHeaders())
    } else {
        api = axios.post(url, data, getAPIDefaultHeaders())
    }
    
    return api
        .then(res => res.data)
        .catch(err => {
            handleCatch(err);
            throw err;
        })
}

export const deleteCommentAskAPI = ({pk, ...params}) => {
    return handleEntityDelete({
        url: `/comments/${pk}`,
        name: 'نظر',
        ...params
    })
}