import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { getCategoryAPI } from "components/api/categories";
import { deleteServiceAskAPI, getServiceAPI } from "components/api/services";
import { deleteUserAskAPI } from "components/api/users";
import { Avatar, Badge, Button, DynamicForm, DynamicFormView, Input, Link, Modal, Pagination, Spin, Table, Tabs, Title } from "components/elements";
import { Page } from "components/shared/layouts";
import SearchInput from "components/shared/searchinput";
import { BASE_URL, WEBSITE_BASE_URL } from "inc/constants";
import { getURLParams, serialize } from "inc/functions";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import Swal from "sweetalert2";


const Style = styled.div`
    
`
const ServiceList = ({className}) => {

	const defaultFilters = {
		limit: 20,
		skip: 0,
		order: 'des'
	}

	const permissions = useSelector(state => state.auth.permissions);

    const history = useHistory()

    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
	const [columns, setColumns] = useState();
    const [filters, setFilters] = useState({
		...defaultFilters, 
		...getURLParams(history.location.search)
	});

	useEffect(() => {
        load(getURLParams(history.location.search));
    }, [history.location.search]);

	useEffect(() => {
        history.push(`/services/?${serialize(filters)}`);
    }, [filters]);


    const load = (params) => {
        setLoading(true);
        getServiceAPI({params: params}).then(res => {
            setData(res);
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }

	useEffect(() => {
		setColumns([
			{
				dataIndex: 'visualizeDetail',
				render: v => v?.thumbnailImage && <Avatar src={v?.thumbnailImage} />
			},
			{
				title: 'کد خدمت',
				dataIndex: 'serviceCode',
				searchIndex: 'serviceCode'
			},
			{
				title: 'فعال',
				dataIndex: 'active',
				render: a => <FontAwesomeIcon className={a ? 'color-primary' : ''} icon={a ? 'check-square' : 'times'} />
			},
			{
				title: 'نام خدمت',
				dataIndex: 'name',
				searchIndex: 'name'
			},
			{
				title: 'دسته بندی',
				dataIndex: 'category',
				render: c => c?.title,
				searchIndex: 'categoryTitle'
			},
			{
				title: 'کد پشتیبانی',
				dataIndex: 'supportCode',
			},
			{
				title: '',
				dataIndex: 'id',
				render: (id, service) => !service.archive && !JSON.parse(filters?.archive || false) && (
					<>
						<Link target="_blank" href={`${WEBSITE_BASE_URL}/service/${id}/`}>
							<Button small success icon="eye" />
						</Link>
						{permissions?.['delete-service'] && (
							<Button small error icon="trash" onClick={() => deleteServiceAskAPI({pk: id, success: () => load(filters)})} />
						)}
						{permissions?.['update-service'] && (
							<Link to={`/services/${id}/edit/`}>
								<Button small secondary icon="edit" />
							</Link>
						)}
					</>
				)
			}
		])
	}, [filters])

    return (
        <Style className={className}>
            <Page title="خدمات">
                <div className="row my-2">
					<div className="col-2 pe-0">
						<Input.Select block className="me-2" placeholder="فیلتر خدمات فعال" options={[
							{
								label: 'خدمات فعال',
								value: true
							},
							{
								label: 'خدمات غیرفعال',
								value: false
							}
						]} defaultValue={JSON.parse(filters?.active || true)} onChange={ev => setFilters(x => ({...x, active: ev.target.value}))} />
					</div>
					<div className="col-2 pe-0">
						<Input.Select block className="me-2" placeholder="فیلتر خدمات حذف شده" options={[
							{
								label: 'خدمات موجود',
								value: false
							},
							{
								label: 'خدمات حذف شده',
								value: true
							}
						]} defaultValue={JSON.parse(filters?.archive || false)} onChange={ev => setFilters(x => ({...x, archive: ev.target.value}))} />
					</div>
                    <div className="col-5 text-start me-auto">
						{permissions?.['create-service'] && (
							<Link to="/services/new/">
								<Button>خدمت جدید</Button>
							</Link>
						)}
                    </div>
                </div>
                {/* <div style={{height: 60}} /> */}
                <Spin active={loading}>
                    {columns && <Table columns={columns} dataSource={data?.data} onColumnSearch={(key, val) => setFilters(f => ({...f, [key]: val}))} />}
                </Spin>
                <Pagination count={data?.count || 0} current={filters.skip / 20 + 1} pageSize={20} onChange={(p) => setFilters(x => ({...x, skip:(p -1 ) * 20}))}/>
            </Page>
        </Style>
    )
}

export default ServiceList;