import React from 'react';
import styled from 'styled-components';
import {CONSTS} from './_constants';

const Avatar = ({className, src, ...otherProps}) => (
    <div className={className} {...otherProps}>
        {src ?
            <img src={src} alt={"profile"}/>
            :
            <i className="fas fa-user"/>
        }
    </div>
)

const StyledAvatar = styled(Avatar)`
  width: ${props => props.size ? props.size : 40}px;
  height: ${props => props.size ? props.size : 40}px;
  border-radius: ${props => props.rounded ? '100%' : 0};
  margin: 0;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  ${props => props.bg && `
        background-color: #eee;
    `}

  text-align: center;

  > img {
    width: 100%;
    height: 100%;
    /*next line commented because of conflict with online-icon */
    /* max-height: 100%; */
    object-fit: cover;
    object-position: center center;
    /* background-color: #eee; */
    /* vertical-align: middle; */
  }
  > i {
    width: 100%;
    height: 100%;
    font-size: 40px;
  }
`

export default StyledAvatar;