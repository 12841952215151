import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { CONSTS } from './_constants';
import Icon from './icon';

// type ImageUploaderProps = {
//     className?: string,
//     onChange?: any,
//     height?: string,
//     fileTypes?: string[],
// }
const ImageUploader = ({ className, iref, small, isUploading, icon, defaultValue, disabled, ...otherProps }) => {
    const inputRef = useRef();
    return (
        <div className={`${className} previewComponent`}>
            <input className="fileInput"
                type="file"
                ref={el => {
                    if (iref)
                        iref.current = el;
                    inputRef.current = el;
                }}
                disabled={isUploading || disabled}
                {...otherProps}
            />
            <div className="_upload-container"
                onClick={() => inputRef.current.click()}
            >
                {
                    isUploading ?
                        <div className="_loading">درحال بارگزاری...</div> :
                        <>
                            <div className="_upload-description">
                                {disabled ? 'بدون فایل' : <>
                                    <Icon fa className="_upload-icon" icon="plus" />
                                    یک فایل برای آپلود انتخاب کنید.
                                </>}
                            </div>
                        </>
                }
                {/* {props.fileTypes &&
                    <div className="_upload-types">
                        ValidFormat :<br/> {props.fileTypes.map((v,k)=>k+1 !== (props.fileTypes && props.fileTypes.length) ? v + ", " : v)}
                    </div>} */}
            </div>
        </div>
    )

}
const StyledImageUploader = styled(ImageUploader)(props => `
    background:${ props.disabled ? `rgba(0, 0, 0, .04)` : '#fff'};
    border: 1px solid rgba(100, 100, 100, .2);
    ${props.error ? `
        border-color: ${CONSTS.colors.error} !important;
    ` : ''}
    border-radius: ${CONSTS.border.radius};
    height: ${props.height ? props.height : '100'}px;
    margin-top: 5px;
    border-radius: 5px;
    // margin-left: 10px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 300px;
    position: relative;
    cursor: pointer;
    display: block;
    transition: ease all .25s;
    transform: scale(1);
    ${!props.disabled ? `
        &:hover {
            background-color: rgba(250, 250, 250, 1);
        }
    ` : ''}
    &:active {
        transform: scale(1.02);
    }
    input {
        cursor: pointer;
        font-size: 100px;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        z-index: 100;
    }
    ._upload-container {
        cursor: pointer;
        height: 100%;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        align-cotent: center;
        flex-direction: column;
        ._upload-icon {
            color: #666;
            margin-left: 5px;
            font-size: 13px;
        }
        ._loading{
            font-size: 13px;
        }
        ._upload-description {
            font-size: 13px;
            color: rgba(100, 100, 100, 1);
            text-align: center;
        }
        ._upload-types {
            font-size: 12px;
            color: ${CONSTS.colors.greyDarker};
            text-align: center;
            line-height: 15px;
            padding-top: 10px;
        }
    }
`)

export default StyledImageUploader;
