import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getServiceAPI } from "components/api/services"
import { getZoneAPI } from "components/api/zones";
import { removeEmpty } from "inc/functions"
import persianDate from "persian-date";
import { useEffect } from "react"
import { useState } from "react"
import { useSelector } from "react-redux";
import styled from 'styled-components';

const { DynamicForm, DynamicFormView, Tabs } = require("components/elements")

const ServiceManServicesForm = styled.div`
	background-color: rgba(0, 0, 0, .01);
	border: 1px solid rgba(0, 0, 0, .1);
	border-radius: 4px;
	padding: 15px 10px;
	margin-bottom: 20px;
`
export const ServiceManServices = ({defaultValue, onChange}) => {

	const [data, setData] = useState()
	const [services, setServices] = useState()

	const getFields = (obj, index) => {
		const f = [
			{
				type: 'react-select',
				name: 'id',
				placeholder: 'خدمت',
				options: services?.data?.map(serv => ({
					label: serv.name,
					value: serv.id
				})),
				defaultValue: obj?.id,
				block: true,
				required: true,
				col: 4
			},
			{
				type: 'text',
				placeholder: 'توضیحات درباره تجربه خدمت رسان',
				defaultValue: obj?.description,
				name: 'description',
				required: true,
				col: 4/3
			},
			// {
			// 	name: 'financialDetails.wages',
			// 	placeholder: 'دستمزد خدمترسان',
			// 	defaultValue: obj?.financialDetails?.wages,
			// 	block: true,
			// 	required: true,
			// 	col: 4
			// },
			{
				name: 'financialDetails.commission.fromWage',
				placeholder: 'کمیسیون از خدمت',
				defaultValue: obj?.financialDetails?.commission?.fromWage,
				block: true,
				required: true,
				col: 4
			},
			{
				name: 'financialDetails.commission.fromMaterials',
				placeholder: 'کمیسیون از کالا',
				defaultValue: obj?.financialDetails?.commission?.fromMaterials,
				block: true,
				required: true,
				col: 4
			},
			{
				name: 'financialDetails.tax.fromWage',
				placeholder: 'درصد مالیات از خدمت',
				defaultValue: obj?.financialDetails?.tax?.fromWage,
				block: true,
				required: true,
				col: 4
			},
			{
				name: 'financialDetails.tax.fromMaterials',
				placeholder: 'درصد مالیات از کالادرصد',
				defaultValue: obj?.financialDetails?.tax?.fromMaterials,
				block: true,
				required: true,
				col: 4
			},
			{
				type: 'checkbox',
				name: 'enabled',
				defaultValue: obj?.enabled || true,
				inputLabel: 'فعال',
				inputClassName: 'pt-3',
				col: 8
			},
			{
				type: 'radio',
				name: 'financialDetails.checkoutType',
				defaultValue: obj?.financialDetails?.checkoutType || 'percent',
				required: true,
				horizontal: true,
				options: [
					{
						label: 'درصدی',
						value: 'percent'
					},
					{
						label: 'ثابت',
						value: 'fixed'
					},
				],
				// label: '',
				inputClassName: 'pt-3',
				col: 8
			},
			{
				type: 'text',
				name: 'financialDetails.cashReceivedPolicy.maximum',
				defaultValue: obj?.financialDetails?.cashReceivedPolicy?.maximum,
				required: true,
				placeholder: 'حداکثر دفعات نقدی',
				col: 4
			},
			// {
			// 	type: 'text',
			// 	name: 'financialDetails.cashReceivedPolicy.current',
			// 	defaultValue: obj?.financialDetails?.cashReceivedPolicy?.current,
			// 	placeholder: 'دفعات نقدی فعلی',
			// 	col: 8
			// },
			{
				type: 'number',
				name: 'minimumScore',
				defaultValue: obj?.minimumScore,
				required: true,
				placeholder: 'حداقل امتیاز',
				col: 4
			},
		]

		if (obj) {
			f.push (
				{
					type: 'button',
					secondary: true,
					value: 'حذف',
					onClick: () => handleChange(obj, index, true),
					col: 4
				}
			)
		}
		else {
			f.push (
				{
					type: 'submit',
					value: 'افزودن',
					success: true,
					col: 4
				}
			)
		}

		return f;
	}

	useEffect(() => {
		getServiceAPI({params: {limit: 1000}}).then(res => {
			setServices(res);
		})
	}, []);

	useEffect(() => {
		if (defaultValue !== undefined) {
			setData(defaultValue);
		}
	}, [defaultValue]);

	const handleChange = (newItem, index, remove = false) => {

		setData(prevData => {
			
			let newData;

			if (index!==undefined && remove) {
				// Remove
				console.info("remove");
				newData = prevData?.filter((prevItem, prevItemIndex) => prevItemIndex !== index);
			} else if (index !== undefined) {
				// Update
				console.info("update");
				newData = prevData?.map((prevItem, prevItemIndex) => prevItemIndex===index ? newItem : prevItem);
			} else {
				// New
				console.info("new");
				newData = [...(prevData || []), newItem];
			}

			if (onChange) {
				onChange(newData);
			}

			return newData;

		});

		// if (index === undefined) {
		// 	return true;
		// }
	}

	return (
		<div>
			{data?.map((item, index) => 
					<ServiceManServicesForm>
						<DynamicForm autoSubmit={false} key={item?.id || index} block fields={getFields(item, index)} onChange={d => handleChange(d, index)} />
					</ServiceManServicesForm>
				)}
			<DynamicForm autoSubmit={false} block fields={getFields()} onSubmit={d => handleChange(d)} />
		</div>
	)
}


export const UserAddressList = ({defaultValue, onChange}) => {

	const [data, setData] = useState();
	const [zones, setZones] = useState();
	const [selectAll, setSelectAll] = useState(false);

	const getFields = (obj, index) => {

		const zonesOptions = zones?.data?.map(zone => ({label: zone.name, value: zone.id}))

		if (index === undefined && (data === undefined || data?.length === 0)) {
			zonesOptions?.unshift({
				label: 'همه مناطق',
				value: 'all'
			})
		}

		const f = [
			{
				type: 'react-select',
				name: 'zoneId',
				placeholder: 'منطقه',
				options: zonesOptions,
				defaultValue: obj?.zoneId,
				block: true,
				required: true,
				col: 6
			},
			{
				type: 'text',
				name: 'title',
				placeholder: 'عنوان آدرس',
				defaultValue: obj?.title,
				required: true,
				col: 6
			},
			{
				type: 'text',
				placeholder: 'آدرس کامل',
				defaultValue: obj?.content,
				name: 'content',
				required: true,
				col: 6/3
			},
		]

		if (obj) {
			f.push (
				{
					type: 'button',
					secondary: true,
					value: 'حذف',
					onClick: () => handleChange(obj, index, true),
					col: 6
				}
			)
		}
		else {
			f.push (
				{
					type: 'submit',
					value: 'افزودن',
					success: true,
					col: 6
				}
			)
		}

		return f;
	}

	useEffect(() => {
		if (defaultValue !== undefined) {
			setData(defaultValue);
			// setData(defaultValue?.map(add => ({
			// 	title: add.title,
			// 	content: add.content,
			// 	zoneId: add?.zone?.id
			// })));
		}
	}, [defaultValue, zones]);

	useEffect(() => {
		getZoneAPI({params: {limit: 100}}).then(res => {
			setZones(res);
		})
	}, []);

	const handleChange = (newItem, index, remove = false) => {

		setData(prevData => {
			
			let newData;

			if (index!==undefined && remove) {
				// Remove
				newData = prevData?.filter((prevItem, prevItemIndex) => prevItemIndex !== index);
			} else if (index !== undefined) {
				// Update
				newData = prevData?.map((prevItem, prevItemIndex) => prevItemIndex===index ? newItem : prevItem);
			} else {
				// New
				newData = [...(prevData || []), newItem];
			}

			if (typeof onChange === "function") {
				if (newItem && newData.length > 0 && newData[0].zoneId === 'all') {
					const firstOne = newData[0];
					newData = zones.data.map(zone => ({
						title: firstOne.title,
						content: firstOne.content,
						zoneId: zone.id
					}));
				}
				onChange(newData);
			}

			return newData;

		});

		return true;

	}

	return (
		<div>
			{data?.map((item, index) => 
					<DynamicForm autoSubmit={false} key={index} block fields={getFields(item, index)} onChange={d => handleChange(d, index)} />
				)}
			<DynamicForm autoSubmit={false} block fields={getFields()} onSubmit={d => handleChange(d)} />
		</div>
	)
}

export const UserEditTabs = ({user}) => {

	const permissions = useSelector(state => state.permissions);

	const items = [
		{
			label: 'ویرایش کاربر',
			to: `/users/${user?.id}/edit/`,
			visible: permissions?.['update-user']
		},
	]

	if (user?.type === "serviceMan") {
		items.push(
			{
				label: 'تراکنش ها',
				to: `/users/${user?.id}/transactions`,
				visible: permissions?.['transactions']
			},
			{
				label: 'سفارشات',
				to: `/users/${user?.id}/orders/`,
				visible: permissions?.['orders']
			}
		)
	} else if (user?.type === "customer") {
		items.push(
			{
				label: 'اعتبار',
				to: `/users/${user?.id}/credit/`,
				visible: permissions?.['transactions']
			},
			{
				label: 'زیرمجموعه‌ها',
				to: `/users/${user?.id}/referrals/`,
				visible: permissions?.['users']
			},
		)
	}

	items.push()

	return (
		<Tabs className="mb-3" items={items} />
	)
}

export const usersListColumns = [
	{
		title: 'کد',
		dataIndex: 'supportCode',
	},
	{
		title: 'شرکت',
		dataIndex: 'admin',
		render: a => a?.company?.name,
		searchIndex: 'companyName',
		searchMessage: 'نام شرکت را وارد کنید'
	},
	{
		title: 'نقش',
		dataIndex: 'type',
		render: t => {
			if (t === "customer") return "مشتری";
			else if (t === "serviceMan") return "خدمت رسان";
		}
	},
	{
		title: 'نام',
		dataIndex: 'firstName',
		searchIndex: 'firstName'
	},
	{
		title: 'نام خانوادگی',
		dataIndex: 'lastName',
		searchIndex: 'lastName'
	},
	{
		title: 'تلفن همراه',
		dataIndex: 'cellphone',
		searchIndex: 'cellphone'
	},
	{
		title: 'کد ملی',
		dataIndex: 'details',
		render: d => d?.nationalCode,
		searchIndex: 'nationalCode'
	},
	{
		title: 'کاربر جدید',
		dataIndex: 'isNew',
		render: a => <FontAwesomeIcon className={a ? 'color-primary' : ''} icon={a ? 'check-square' : 'times'} />
	},
	{
		title: 'تاریخ ثبت نام',
		dataIndex: 'createdAt',
		render: d => new persianDate(new Date(d)).format("YYYY/M/D")
	},
]