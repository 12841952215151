import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import {Editor} from 'react-draft-wysiwyg';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useEffect, useState } from 'react';

const { default: styled } = require("styled-components");

const TextEditorStyle = styled.div`
	direction: ltr;
    .wrapper {
        border: 1px solid #eee;
    }
    .toolbar {
        font-size: 12px;
    }
    .editor {
        padding: 0 20px;
        max-height: 300px;
        overflow: auto;
    }
`

const TextEditor = ({className, defaultValue, onUploadImage=undefined, onChange, ...otherProps}) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const handleUploadImage = file => {
        return onUploadImage(file).then(media => {
            return {
                data: {
                    link: media.file
                }
            }
        })
    }

    const toolbar = {
        options: ['image', 'colorPicker', 'inline', 'blockType', 'fontSize', 'list', 'link'],
        image: {
            urlEnabled: true,
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback: onUploadImage && handleUploadImage,
            previewImage: true,
            previewImage: false,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            alt: { present: false, mandatory: false },
            defaultSize: {
                height: 'auto',
                width: 'auto',
            },
        },
    }

    const handleStateChange = v => {
        if (onChange)
            onChange(draftToHtml(convertToRaw(v.getCurrentContent())));
        setEditorState(v);
    }

    useEffect(() => {
        if (!defaultValue) return;
        setEditorState(
            EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    {
                        ...convertFromHTML(defaultValue)
                    }
                )
                
            )
        );
    }, [defaultValue]);

    return (
        <TextEditorStyle>
            <Editor
                toolbar={toolbar}
                editorState={editorState}
                toolbarClassName="toolbar"
                wrapperClassName="wrapper"
                editorClassName="editor"
                onEditorStateChange={handleStateChange}
                {...otherProps}
            />
        </TextEditorStyle>
    )
}

export default TextEditor;