import { deleteAdminAskAPI, getAdminAPI, submitAdminAPI } from "components/api/admins";
import { getCompanyAPI } from "components/api/companies";
import { getRoleAPI } from "components/api/roles";
import { Alert, Avatar, Badge, Button, DynamicForm, DynamicFormView, Link, Modal, Pagination, Spin, Table, Tabs, Title } from "components/elements";
import { Page } from "components/shared/layouts";
import { ADMIN_ROLE_ID } from "inc/constants";
import { removeEmpty } from "inc/functions";
import persianDate from "persian-date";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import Swal from "sweetalert2";


const Style = styled.div`
    
`
const AdminNew = ({className, match}) => {

	const history = useHistory();

    const [object, setObject] = useState();
    const [loading, setLoading] = useState(false);
	const [fields, setFields] = useState();
	const [companies, setCompanies] = useState();
	const [company, setCompany] = useState();
	const [roles, setRoles] = useState();

	const { id } = match.params;
    
    useEffect(() => {

		if (id) {
			load();
		}

		getCompanyAPI({}).then(res=> {
			setCompanies(res.data);
		})

    }, []);

	useEffect(() => {
		if (!company) return;

		setLoading(true);
		getRoleAPI({ params: { companyId: company } }).then(res => {
			setRoles(res.data);
		}).finally(() => {
			setLoading(false);
		})
	}, [company])

	useEffect(() => {
		const f = [
			{
				label: 'نام',
				name: 'details.firstName',
				required: true,
				defaultValue: object?.details?.firstName
			},
			{
				label: 'نام خانوادگی',
				name: 'details.lastName',
				required: true,
				defaultValue: object?.details?.lastName
			},
			{
				label: 'نام کاربری',
				name: 'username',
				required: true,
				defaultValue: object?.username,
				disabled: !!id
			},
			{
				type: 'select',
				label: 'شرکت',
				name: 'companyId',
				required: true,
				defaultValue: object?.company?.id,
				options: companies?.map(c => ({
					label: c.name,
					value: c.id
				})),
				onChange: ev => {
					setCompany(ev.target.value);
					return ev.target.value;
				}
			},
			{
				label: 'رمز عبور',
				type: 'password',
				name: 'password',
				required: !id
			},
			{
				label: 'تکرار رمز عبور',
				type: 'password',
				name: 'password2',
				required: !id
			},
			{
				type: 'custom',
				className: !id && 'd-none',
				render: 
					<Alert>
						درصورتی که نمیخواهید رمز کاربر را تغییر دهید آن را خالی بگذارید
					</Alert>
				,
				col: 1
			},
			{
				type: 'select',
				label: 'نقش',
				name: 'role',
				required: true,
				defaultValue: object?.role?.id,
				options: roles?.map(c => ({
					label: c.description,
					value: c.id
				})),
				disabled: !company,
				placeholder: company ? 'انتخاب کنید' : 'ابتدا شرکت را انتخاب کنید'
			},
			{
				label: 'کد ملی',
				name: 'details.nationalCode',
				defaultValue: object?.details?.nationalCode
			},
			{
				label: 'تلفن همراه',
				name: 'details.cellphone',
				defaultValue: object?.details?.cellphone
			},
			{
				type: 'submit',
				value: 'ذخیره',
				col: 1
			}
		];
		setFields(f);
	}, [object, companies, id, roles, company]);


	const handleSubmit = data => {

		if (data.password && data.password !== data.password2) {
			Swal.fire({
				title: 'رمز عبور نامعتبر',
				text: 'رمز عبور با تکرار آن مطابقت ندارد.',
				icon: 'error'
			});
			return;
		}
		
		delete data['password2'];

		if (id) {
			delete data['username']
		}

		data = {
			...data,
			// role: ADMIN_ROLE_ID
		}
		
		setLoading(true);
		submitAdminAPI({data: removeEmpty(data), pk: id || null}).then(res=> {
			setLoading(false);
			Swal.fire({
				title: 'تبریک!',
				text: `مدیر با موفقیت ${id ? 'ویرایش' : 'ایجاد'} شد.`,
				icon: 'success',
				confirmButtonText: 'باشه'
			});
			history.push(`/admins/${res.id}/edit/`);
		}).catch(err => {
			setLoading(false);
		})
	}

    const load = () => {
        setLoading(true);
        getAdminAPI({pk: id}).then(res => {
            setObject(res);
			setCompany(res.company?.id)
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        })
    }

	const pageMeta = <>
		{id && <Button 
					error
					small
					icon="trash"
					onClick={() => deleteAdminAskAPI({
						pk: id, 
						name:'مدیر', 
						success: () => history.push("/admins")
					})}>حذف</Button>}
		{id && <Link to="/admins/new/">
					<Button icon="plus" small>جدید</Button>
			</Link>}
		<Link to="/admins/">
			<Button small secondary icon="angle-left">بازگشت</Button>
		</Link>
	</>

    return (
        <Style className={className}>
            <Page title={id ? 'ویرایش مدیر' : 'مدیر جدید'} meta={pageMeta}>
                <Spin active={loading}>
					<DynamicForm fields={fields} block onSubmit={handleSubmit} cols={2} />
                </Spin>
            </Page>
        </Style>
    )
}

export default AdminNew;