import { useSelector } from "react-redux";
import ServiceComments from "./comments";
import ServiceList from "./list";
import ServiceNew from "./new";
import ServiceQuestions from "./questions";
import ServiceServiceMans from "./service-mans";
const { Switch, Route } = require("react-router")

const Services = () => {

	const permissions = useSelector(state => state.auth.permissions);

    return (
        <Switch>
            {permissions?.services && (
					<Route path="/services/" exact component={ServiceList} />
				)}
			{permissions?.['create-service'] && (
            	<Route path="/services/new/" exact component={ServiceNew} />
			)}
			{permissions?.['update-service'] && (
            	<Route path="/services/:id/edit/" exact component={ServiceNew} />
			)}
			{permissions?.questions && (
            	<Route path="/services/:id/questions/" exact component={ServiceQuestions} />
			)}
			{permissions?.comments && (
            	<Route path="/services/:id/comments/" exact component={ServiceComments} />
			)}
			{permissions?.users && (
            	<Route path="/services/:id/serviceMans/" exact component={ServiceServiceMans} />
			)}
        </Switch>
    )
}

export default Services;