import axios from 'axios'
import { BASE_URL } from 'inc/constants'
import { getAPIDefaultHeaders, getAuthHeader ,handleCatch, handleEntityDelete, serialize} from 'inc/functions'

export const getMyProfileAPI = () => {
    
    let url = `${BASE_URL}/auth/profile`;
    
    return axios.get(url, getAPIDefaultHeaders())
            .then(res => res.data)
            .catch(err => {
                handleCatch(err);
                throw err;
            } )
}

export const updateMyProfileAPI = ({data={}}) => {
    
    let url = `${BASE_URL}/profile/`;
    
    return axios.put(url, data, getAPIDefaultHeaders())
        .then(res => res.data)
        .catch(err => {
            handleCatch(err);
            throw err;
        })
}

export const updatePasswordAPI = (data) => {

	let url = `${BASE_URL}/update-password/`;
    
    return axios.put(url, data, getAPIDefaultHeaders())
        .then(res => res.data)
        .catch(err => {
            handleCatch(err);
            throw err;
        })
}
