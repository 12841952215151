import { MEDIA_UPLOAD_URL  ,DEFAULT_LANG, File_UPLOAD_URL, BASE_URL, SWEET_BUTTONS } from "./constants";
import Axios from "axios";
import Cookies from 'js-cookie';
import Swal from "sweetalert2";

export const intComma = value => {

	if (value === undefined) return;
	
    var origValue = String(value);
    var newValue = origValue.replace(/^(-?\d+)(\d{3})/, '$1،$2');
    if (origValue == newValue){
        return newValue;
    } else {
        return intComma(newValue);
    }
};

export const loginRedirect = (role='member') => {
    const target = `/auth/login/`; 
    if (window.location.pathname !== target)
        window.location = target
}

export const handleCatch = (err, isLogin=false) => {
    try{
        let res = err.response;
		let msg;
        
		if(!res) return;

		if (res.status === 401 && !isLogin){
			msg = 'توکن منقضی شده دوباره وارد شوید';
			logout();
		}
		else if (res?.data?.message) {
			msg = res.data.message;
		}
		else if (res?.data) {
			msg = JSON.stringify(res.data)
		} else {
			msg = 'خطای نامشخص';
		}

		Swal.fire({
			title: 'خطا',
			text: msg,
			icon: 'error',
			confirmButtonText: 'باشه'
		})
        
    } catch(error){
        Swal.fire({
            text: error,
            icon: 'error'
        });
    }
}

export const getURLParams = (url) => {
    if(!url)
        url = window.location.href;
    const q = window.location.href.split('?')[1];
    if (!q) return {};
    const groups = q.split('&');
    const params = {};
    groups.forEach(g => {
        const parts = g.split('=');
        params[parts[0]]= (parts[1]);
    });
    return params;
}

export const getEntityURLParams = url => {
    var params = [];
    var parts = url.split(":");
    delete parts[0];
    parts.forEach(part => {
        var i = part.indexOf("/");
        if(i !=-1)
            part = part.slice(0, i);
        params.push(part);
    });
    return params;
}

export const getAuthHeader = () =>{
    return {Authorization:`Bearer ${getToken()}`}
}

// export const getHeadersAuth = () => {
//     var token = Cookies.get("token");
//     return {
//         Authorization: `Token ${token}`
//     }
// }

export const getAPIDefaultHeaders = () => {
    return {
        headers: getAuthHeader()
    }
}

export const dateObjToStr = (d, time) =>  {
    Date.prototype.yyyymmdd = function () {
        var mm = this.getMonth() + 1; // getMonth() is zero-based
        var dd = this.getDate();

        return [this.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd
        ].join('-');
    };

    return d.yyyymmdd();

}

export const handleUploadMedia = (file, type='image') => {
    var url = type==='image' ? MEDIA_UPLOAD_URL : File_UPLOAD_URL;
    const formData = new FormData();
    formData.append(type, file)

    const config = {
        headers: Object.assign({}, {
            'content-type': 'multipart/form-data',
        }, getAuthHeader())
    }
    return Axios.post(url, formData, config)
    .then(
        res => res.data,
    )
    .catch( err => {
        handleCatch(err);
    })
}

export const getToken = () => {
    return localStorage.getItem('access');
}
export const setToken = (token) => {
    return localStorage.setItem('token', token);
}
export const getUser = () => {
    let user = Cookies.get('user');
    if(user){
        user = JSON.parse(user);
        user['role'] = Cookies.get("role");
        return user;
    }
}
export const serialize = function (obj, remove_null=false) {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            if ([undefined, "", null].includes(obj[p]) && remove_null) continue;
            str.push((p) + "=" + (obj[p]));
        }
    return str.join("&");
}
export const setUser = (dict) => {
    const user = JSON.stringify(dict);
    return Cookies.set("user", user);
}
export const getRole = () => {
    return Cookies.get('role');
}
export const setRole = (role) => {
    return Cookies.set('role', role);
}
export const logout = () => {
    const role = getRole();
    localStorage.removeItem("refresh");
    localStorage.removeItem("access");
    loginRedirect(role);
}
export const getFileName = fullname => {
    const parts = fullname.split('/');
    return parts[parts.length-1];
}

export const handleEntityDelete = ({url, name = 'مورد', setLoading = null, success = null}) => {
    Swal.fire({
        text: `آیا از حذف این ${name} اطمینان دارید؟`,
        icon: 'warning',
        confirmButtonText: 'آره',
        cancelButtonText: 'نه',
        showCancelButton: true
    }).then( ({isConfirmed}) => {
        if (!isConfirmed) return Promise.resolve();
        if (setLoading) setLoading(true);
        return Axios.delete(`${BASE_URL}${url}`, getAPIDefaultHeaders()).then((res) => {
            if (setLoading) {
                setLoading(false);
            }
            if (success) {
                success(res);
            }
        }).catch((err) => {
            if (setLoading) {
                setLoading(false);
            }
            handleCatch(err);
        });
    })

};

export const numToPers=(value)=> {
    const mil=value/1000000;
    const _mil =value%1000000;

    return `${mil>=1?`${Math.trunc(mil)} میلیون `:''} ${_mil!==0&&mil>=1?'و ':''}
    ${_mil!==0||(_mil)/1000>=1?`${Math.trunc((_mil)/1000)} هزار `:`${value%1000===0?``:value%1000+'و'}`}`
}

export function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};


const isDict = (obj) => {
	return obj !== null && typeof obj === 'object' && Array.isArray(obj) === false;
}
export const removeDeepEmptyObject = (obj) => {
	if (isDict(obj)) {
		Object.keys(obj).forEach(key => {
			if (isDict(obj[key])) {
				removeDeepEmptyObject(obj[key]);
				if (Object.keys(obj[key]).length === 0) {
					delete obj[key];
				}
			} else if (obj[key] === null || obj[key] === undefined) {
				delete obj[key];
			}
		} );
	}
	
	return obj;
}
export const removeEmpty = (dict, nullValues=[null, undefined, ""], emptyObject) => {
	return Object.fromEntries(
		Object.entries(dict)
		  .filter(([_, v]) => nullValues.indexOf(v) === -1 && !(emptyObject && isDict(v) && Object.keys(v).length === 0))
		  .map(([k, v]) => [k, isDict(v) ? removeEmpty(v) : v])
		  .filter(([_, v]) => (isDict(v) && emptyObject) ? Object.keys(v).length > 0 : true)
	  );
}

export const camelize = (str) => {
	return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
		return index === 0 ? word.toLowerCase() : word.toUpperCase();
	}).replace(/\s+/g, '').replace('-', '');
}

export const debounce = (func, delay) => {
	let inDebounce;
	return function () {
		const context = this;
		const args = arguments;
		clearTimeout(inDebounce);
		inDebounce = setTimeout(() => func.apply(context, args), delay);
	};
}