export const CONSTS = {
    border: {
        radius: 5,
        radius2: 0
    },
    colors: {
        primary: "#086AD9",
        primaryDark: "#0458b8",
        primaryLight: "#0fbcf9",

        error: "#E61515",
        errorDark: "#EF5417",
        errorLight: "rgba(198,53,53,.15)",

        success: "#00C976",
        successDark: "#27AE60",
        successLight: "rgba(53,198,53,.15)",

        warning: "#FFD21D",
        warningDark: "#9E8216",
        warningLight: "rgba(198,169,53,.15)",

        info: "#727987",
        infoDark: "#3C4454",
        infoLight: "rgba(114,121,135,.15)",

        secondary: "#F8F6F7",
        secondaryDark: "#ededed",

        greyDarker: '#727987',
        greyDark: '#B1B9C7',
        grey: '#EEF2FB',
        greyLight: '#DDE2EB',
        greyLighter: '#EEF2FB',
    },
    selectors: {
        rtl: '&:lang(fa)'
    },
    resp: {
        sm: 575.98,
        md: 767.98,
        lg: 991.98,
        xl: 1199.98
    },

}
    
export const MAPBOX_TOKEN = 'pk.eyJ1IjoiYWxpcmV6YWhvb3NoeWFyIiwiYSI6ImNrZHZzNnVucDA4MmQyeW53cXIxbXc1b3gifQ.R-1qEetdjHE7dQlFA9JlMg'