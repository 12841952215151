import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { getCategoryAPI } from "components/api/categories";
import { getCityAPI } from "components/api/cities";
import { deleteTransactionAskAPI, getTransactionAPI, submitTransactionAPI } from "components/api/transaction";
import { deleteUserAskAPI, getUserAPI } from "components/api/users";
import { Avatar, Badge, Button, DynamicForm, DynamicFormView, Link, Modal, Pagination, Spin, Table, Tabs, Title } from "components/elements";
import { Page } from "components/shared/layouts";
import SearchInput from "components/shared/searchinput";
import { BASE_URL, WEBSITE_BASE_URL } from "inc/constants";
import { removeEmpty } from "inc/functions";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import Swal from "sweetalert2";


const Style = styled.div`
    
`
const TransactionNew = ({className, match}) => {

	const history = useHistory();

    const [object, setObject] = useState();
    const [loading, setLoading] = useState(false);
	const [fields, setFields] = useState();
	const [categories, setCategories] = useState();
	const [cities, setCities] = useState();

	const { id } = match.params;
    
    useEffect(() => {

		if (id) {
			load();
		}
		
    }, []);

	useEffect(() => {
		setFields([
			{
				type: 'radio',
				label: 'نوع تراکنش',
				name: 'type',
				required: true,
				defaultValue: object?.type,
				options: [
					{
						label: 'افزایش اعتبار',
						value: 'increase'
					},
					{
						label: 'کاهش اعتبار',
						value: 'decrease'
					},
				],
				horizontal: true,
			},
			{
				type: 'async-react-select',
				name: 'userId',
				label: 'کاربر',
				placeholder: 'نام خانوادگی کاربر را جستجو کنید',
				optionLoader: q => 
					getUserAPI({params: removeEmpty({lastName: q, type: 'serviceMan'})}).then(res => 
						res?.data.map(u => ({
							label: `${u?.firstName} ${u?.lastName}`,
							value: u.id
						}))
					),
				// defaultValue: object?.company ? {
				// 	label: object?.company?.name,
				// 	value: object?.company?.adminId
				// } : null,
				defaultOptions: true,
				cacheOptions: true,
				block: true,
				required: true,
			},
			{
				type: 'price',
				label: 'مبلغ',
				name: 'amount',
				defaultValue: object?.amount,
				required: true
			},
			{
				type: 'textarea',
				label: 'توضیحات',
				name: 'description',
				defaultValue: object?.description
			},
			{
				label: ' ',
				type: 'submit',
				value: 'ذخیره',
				col: 1
			}
		])
	}, [object]);


	const handleSubmit = data => {
		data = {
			...data,
			action: "system",
			target: "commission-credit",
		}
		setLoading(true);
		submitTransactionAPI({data: data, pk: id || null}).then(res=> {
			setLoading(false);
			Swal.fire({
				title: 'تبریک!',
				text: `تراکنش با موفقیت ${id ? 'ویرایش' : 'ایجاد'} شد.`,
				icon: 'success',
				confirmButtonText: 'باشه'
			});
			history.push(`/transactions/${res.id}/edit/`);
		}).catch(err => {
			setLoading(false);
		})
	}

    const load = () => {
        setLoading(true);
        getTransactionAPI({pk: id}).then(res => {
            setObject(res);
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }

	const pageMeta = <>
		{id && <Button 
					error
					small
					icon="trash"
					onClick={() => deleteTransactionAskAPI({
						pk: id, 
						name:'تراکنش', 
						success: () => history.push("/transactions")
					})}>حذف</Button>}
		{id && <Link to="/transactions/new/">
					<Button icon="plus" small>جدید</Button>
			</Link>}
		<Link to="/transactions/">
			<Button small secondary icon="angle-left">بازگشت</Button>
		</Link>
	</>

    return (
        <Style className={className}>
            <Page title={id ? 'ویرایش تراکنش' : 'تراکنش جدید'} meta={pageMeta}>
                <Spin active={loading}>
					<DynamicForm fields={fields} block onSubmit={handleSubmit} />
                </Spin>
            </Page>
        </Style>
    )
}

export default TransactionNew;