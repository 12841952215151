import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import { getHeadersAuth } from 'inc/functions';
import { Button, Icon } from '../elements';
import { CONSTS } from '../elements/_constants';

const Stlyes = styled.div`
    .library {
        &__header {
            background-color: ${CONSTS.colors.primary};
            color: #fff;
            border-radius: 4px 4px 0 0;
            &__button{
                position: relative;
                background-color: rgba(5, 101, 165, 1);
                padding: 0 10px;
                &__icon{
                    margin-left: 5px;
                    font-size: .7rem;
                }
                &:hover {
                    background-color: rgba(5, 101, 165, .5);
                }
            }
            &__disabled {
                background-color: rgba(5, 101, 165, 0);
                cursor: auto;
                &:hover {
                    background-color: rgba(5, 101, 165, 0);
                }
            }
        }
        &__body {
            border-radius: 0 0 4px 4px;
            background-color: #fff;
            max-height: 200px;
            overflow: auto;
            &::-webkit-scrollbar {
                width: 5px;
            }
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }
            &::-webkit-scrollbar-thumb {
                background: #888;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
        }
        &__list{
            list-style-type: none;
            padding: 4px;
            margin: 0;
            border: 1px solid #eee;
            border-top: none;
            display: flex;
            flex-wrap: wrap;
            &__item {
                font-size: 11px;
                &__delete {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    color: #fff;
                    background-color: red;
                    padding: 2px 3px;
                    opacity: 0;
                    font-size: 8px;
                    border-radius: 0 2px 0 2px;
                    &:hover {
                        background-color: #fff;
                        color: red;
                    }
                }
                &__status {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
                &__link {
                    position: relative;
                    display: inline-block;
                    width: 60px;
                    height: 60px;
                    border-radius: 4px;
                    overflow: hidden;
                    margin-right: 4px;
                    margin-bottom: 0;
                    background-color: #eee;
                }
                &__image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    opacity: 0;
                    transform: scale(1);
                    transition: transform .15s;
                    &:hover {
                        transform: scale(1.1);
                    }
                }
                &--error {
                    .library__list__item__status{
                        color: ${CONSTS.colors.error};
                    }
                    
                }
                &--ok {
                    .library__list__item__image, .library__list__item__delete{
                        opacity: 1;
                    }
                }
                &--loading {
                    opacity: .6;
                    pointer-events: none;
                    .library__list__item__status{
                        color: #999;
                    }
                }
            }
        }
        &__input {
            display: none;
        }
        &__nofile {
            background-color: #fff;
            padding: 15px 10px;
            text-align: center;
            font-size: 13px;
            color: #999;
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid #eee;
            border-top: none;
            &__icon {
                font-size: 2rem;
                /* margin-bottom: 15px; */
            }
        }
    }
`

const ImageLibrary = ({className, onUpload, onDelete, images, title="تصاویر"}) => {
    const fileRef = useRef();
    const mapStatus = {
        '-1': ['error', 'exclamation-triangle'],
        '0': ['loading', 'spinner'],
        '1': ['ok']
    }
    const [items, setItems] = useState([]);

    useEffect(()=> {
        const x = images?.map(file => {
            file.status = 1;
            return file;
        }) || [];
        setItems(x);
    }, [images]);

    const handleUploadClick = (ev) => {
        fileRef.current.click();
    }

    const handleInputChange = (ev) => {
        if(!ev.target.files[0]) return;

        let file = ev.target.files[0];
        
        if(onUpload) {
            const item = {
                name: file.name,
                status: 0
            }
            setItems(x => [{
                ...item
            }, ...x])
            onUpload(file)
        } else {
            const item = {
                name: file.name,
                status: -1
            }
            setItems(x => [{
                ...item
            }, ...x])
        }
    }

    const handleDelete = (ev, item, index) => {
        ev.preventDefault();
        if(onDelete) {
            setItems(x => 
                x.map(itm => {
                    if (itm===item) {
                        return {
                            ...itm,
                            status: 0
                        }
                    }
                    return itm;
                })
            )
            onDelete(item);
        } else {
            setItems(x => 
                x.map(itm => {
                    if (itm===item) {
                        return {
                            ...itm,
                            status: -1
                        }
                    }
                    return itm;
                })
            )
        }
    }

    return (
        <Stlyes className={className}>
            <input type="file" ref={fileRef} onChange={handleInputChange} className="library__input" />
            <div className="library__header">
                {onUpload ? <Button className="library__header__button" onClick={handleUploadClick}>
                    <Icon className="library__header__button__icon" fa icon="upload" />
                    آپلود عکس
                </Button> : <Button disabled={true} className="library__header__disabled">{title}</Button>}
            </div>
            <div className="library__body">
                {items.length > 0 && <ul className="library__list">
                    {items.map((item, indx) => 
                            <li key={indx} className={`library__list__item library__list__item--${mapStatus[item.status][0]}`}>
                                <a className="library__list__item__link" target="_blank" href={item.url} title={item.name}>
                                    <img className="library__list__item__image" src={item.src} />
                                    <Icon className="library__list__item__status" fa icon={mapStatus[item.status][1]} />
                                    {onDelete && <Icon className="library__list__item__delete" fa icon="trash" onClick={ev => handleDelete(ev, item, indx)} />}
                                </a>
                            </li>
                        )}
                </ul>}
                {items.length===0 && 
                    <div className="library__nofile">
                        <Icon className="library__nofile__icon" fa icon="image" />
                        هیچ تصویری پیدا نشد.
                    </div>
                }
            </div>
        </Stlyes>
    )
}

export default ImageLibrary;