import React, {useState} from 'react';
import styled from 'styled-components';
import {CONSTS}  from './_constants';
// import {Link} from 'react-router-dom';
import {Link} from './typography';
import Icon from './icon';

const NavItem = ({className, children, icon, exact, href=null, to=null, isActive, onClick=null}) => {
    const path = to ? to : href;
    const active = isActive===true || window.location.pathname === path || (!exact && window.location.pathname.startsWith(path));
    const content = <>
                        {icon && <Icon className='_icon' icon={icon}/>}
                        <span className="_label">{children}</span>
                    </>
    return(
        <li className={`${className} ${active ? "active" : ""}`} onClick={onClick}>
            {(to || href) ? <Link className={`${icon ? "d-flex align-items-center" : ""}`} to={to} href={href}>
                {content}
            </Link> : content}
        </li>
    )
}
const Nav = ({className, children}) => (
    <div className={className}>
        <ul>
            {children}
        </ul>
    </div>
)
Nav.Item  = NavItem;

const StyledNav = styled(Nav)`
    > ul {
        list-style-type: none;
        padding-left: 0;
        margin: 0;
        border: 1px solid rgba(0, 0, 0, .05);
        li{
            cursor: pointer;
            background-color: #fff;
            transition: background-color .25s;
            &:hover{
                background-color:rgba(0, 0, 0, .02);
                ._label, ._icon{
                    color:#666;
                }    
            }
            &:not(:last-child){
                border-bottom: 1px solid rgba(0, 0, 0, .05);
            }
            a{
                text-decoration: none;
                display: block;
                padding: 10px 0;
            }
            ._label, ._icon{
                font-size: 12px;
                color: #333;
                font-weight: 400;
                transition: color .2s;
                transition: color .25s;
                
            }
            ._label{
                margin-left: 7px;
            }
            &.active{
                background-color: #EAF0F4;
                ._label, ._icon{
                    font-weight: bold;
                }
            }
        }
    }
    ${CONSTS.selectors.rtl}{
        ._label{
            margin-left: 0;
            margin-right : 7px;
        }
    }
`

export default StyledNav;