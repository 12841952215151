import { getChatAPI } from "components/api/chats";
import { getOrderAPI } from "components/api/orders";
import { Spin } from "components/elements";
import { ChatList } from "components/shared/chat";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";

const Style = styled.div`
	.page {
	}
`
const OrderChats = ({ match }) => {

	const [order, setOrder] = useState();
	const [chats, setChats] = useState();
	const [loading, setLoading] = useState(false);
	const { id } = match.params;

	useEffect(() => {
		if (id) {
			loadOrder();
			loadChats();
		}
	}, [id]);

	const loadChats = () => {
		setLoading(true);
		getChatAPI({ params: { orderId: id }}).then(res => {
			setChats(res.data);
		}).finally(err => setLoading(false));
	}

	const loadOrder = () => {
		getOrderAPI({ pk: id }).then(res => {
			setOrder(res);
			setLoading(false);
		}).catch(err => setLoading(false));
	}

	return (
		<Style>
			<Spin active={loading}>
				{chats && (
					<>
						<ChatList currentUserId={order?.customer?.id} messages={chats} />
					</>
				)}
			</Spin>
		</Style>
	)
}

export default OrderChats;