import React from 'react';
import styled from 'styled-components';
import { CONSTS } from './_constants';
import Icon from './icon';

const Card = ({ className, style, title, header, meta, children }) => {
    return (
        <div className={className} style={style}>
            {
                (header || title || meta) &&
                <div className="_header">
                    {header ? header :
                        <>
                            <h2 className="_title">{title}</h2>
                            {meta && <div className="_meta">{meta}</div>}
                        </>
                    }
                </div>
            }
            <div className="_body">{children}</div>
        </div>
    )
}

const StyledCard = styled(Card)`
    border: 1px solid #DFE0EB;
    border-radius: 8px;
    background-color: #fff;
    > ._header{
        position: relative;
        padding: 15px 15px 0 15px;
        display: flex;
        > ._title{
            font-size: 17px;
            margin: 0;
            font-weight: 400;
            font-style: bold;
            color: #252733;
            flex: 1;
            display: flex;
            align-items: center;
        }
        > ._meta{
            flex: 1;
            text-align: left;
        }
    }
    > ._body{
        font-size: 13px;
        line-height: 25px;
        padding: 25px;
        /* overflow: auto; */
    }
`

export default StyledCard;