import { Button } from 'components/elements';
import './detail.css'

import { OrderDetailForm } from "./utils";

const { getOrderAPI } = require("components/api/orders");
const { useState, useEffect } = require("react");

const OrderDetail = ({ match }) => {

	const { id } = match.params;

	const [loading, setLoading] = useState(false);
	const [object, setObject] = useState();

	useEffect(() => {
		setLoading(true);
		getOrderAPI({ pk: id }).then(res => {
			setObject(res);
			setLoading(false);
			document.title = `سفارش شماره ${res.supportCode}`
			setTimeout(() => {
				window.print();
			}, 500);
		}).catch(err => setLoading(false));
	}, [id]);

	if (loading) return 'در حال بارگزاری...';

	return (
		<div>
			<Button secondary className='noprint mb-3' onClick={() => window.print()}>چاپ</Button>
			<OrderDetailForm order={object} cols={1} />
		</div>
	)

}

export default OrderDetail;