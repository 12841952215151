import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { getAdminAPI } from "components/api/admins";
import { getCategoryAPI } from "components/api/categories";
import { getCityAPI } from "components/api/cities";
import { getCollaborationAPI } from "components/api/collaborations";
import { getRoleAPI, getRoleByName } from "components/api/roles";
import { deleteUserAskAPI, getUserAPI, submitUserAPI } from "components/api/users";
import { Avatar, Badge, Button, DynamicForm, DynamicFormView, Link, Modal, Pagination, Spin, Table, Tabs, Title } from "components/elements";
import { Page } from "components/shared/layouts";
import SearchInput from "components/shared/searchinput";
import SectionTitle from "components/shared/sectionTitle";
import { BASE_URL, WEBSITE_BASE_URL } from "inc/constants";
import { intComma, removeEmpty } from "inc/functions";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import Swal from "sweetalert2";
import { ServiceManServices, SubTitle, UserAddressList, UserEditTabs } from "./utils";

const Style = styled.div`
    
`
const UserNew = ({className, match}) => {

	const history = useHistory();

    const [loading, setLoading] = useState(false);
	const [fields, setFields] = useState();
    const [object, setObject] = useState();
    const [type, setType] = useState();
	const [serviceManServices, setServiceManServices] = useState();
	const [addresses, setAddresses] = useState();
	const [collaboration, setCollaboration] = useState();
	const [roles, setRoles] = useState();
	const [activeStatus, setActiveStatus] = useState(true);
	// const [categories, setCategories] = useState();
	// const [cities, setCities] = useState();

	// console.log(removeEmpty(x, [null, undefined, ""], true));

	const { id, collaborationid } = match.params;

	// const isNewUser = id===undefined && collaborationid===undefined;

    useEffect(() => {

		if (id) {
			load();
		}
		if (collaborationid) {
			loadCollaboration();
		}
		getRoleAPI({}).then(res => {
			setRoles(res?.data);
		});

    }, []);

	useEffect(() => {
		const x = [
			{
				type: 'custom',
				render: <SectionTitle className="mt-2 mb-0">
					<div className="d-flex justify-content-between">
						<div>اطلاعات کاربری</div>
						{object && (
							<div>اعتبار:‌ {intComma(object?.credit)} تومان</div>
						)}
					</div>
				</SectionTitle>,
				col: 1
			},
			{
				label: 'نام',
				name: 'firstName',
				required: true,
				defaultValue: object?.firstName || collaboration?.firstName
			},
			{
				label: 'نام خانوادگی',
				name: 'lastName',
				required: true,
				defaultValue: object?.lastName || collaboration?.lastName
			},
			{
				label: 'تلفن همراه',
				name: 'cellphone',
				// disabled: !isNewUser,
				defaultValue: object?.cellphone || collaboration?.cellphone
			},
			{
				type: 'select',
				label: 'نوع کاربر',
				name: 'type',
				// disabled: !isNewUser,
				options: [
					{
						label: 'مشتری',
						value: 'customer'
					},
					{
						label: 'خدمت رسان',
						value: 'serviceMan'
					},
					
				],
				onChange: ev => {
					setType(ev.target.value);
					return ev.target.value;
				},
				required: true,
				defaultValue: object?.type || (collaboration && 'serviceMan'),
			},
			{
				label: 'کد معرف',
				name: 'supportCode',
				disabled: true,
				defaultValue: object?.supportCode,
			},
			{
				label: 'امتیاز کاربر',
				name: 'point',
				disabled: true,
				defaultValue: object?.point,
			},
			{
				label: 'کد ملی',
				name: 'details.nationalCode',
				required: false,
				defaultValue: object?.details?.nationalCode,
			},
			{
				label: 'شماره شناسنامه',
				name: 'details.nationalId',
				required: false,
				// disabled: true,
				defaultValue: object?.details?.nationalId,
			},
			{
				type: 'pdate',
				label: 'تاریخ تولد',
				name: 'details.birthDate',
				required: false,
				prevYears: 100,
				nextYears: 0,
				defaultValue: object?.details?.birthDate ? object.details.birthDate.split("T")[0] : null,
			},
			{
				label: 'نام پدر',
				name: 'details.fatherName',
				required: false,
				defaultValue: object?.details?.fatherName,
			},
			{
				label: 'ایمیل',
				name: 'details.email',
				required: false,
				defaultValue: object?.details?.email,
			},
			{
				label: 'شماره تلفن ثابت',
				name: 'details.tel',
				required: false,
				defaultValue: object?.details?.tel,
			},
			{
				label: 'شماره شبا',
				name: 'details.sheba',
				placeholder: 'شماره شبا همراه با IR',
				// required: type === "serviceMan",
				defaultValue: object?.details?.sheba,
			},
			{
				type: 'async-react-select',
				name: 'adminId',
				label: 'شرکت',
				optionLoader: q => 
					getAdminAPI({params: removeEmpty({companyName: q})}).then(res => 
						res?.data.map(u => ({
							label: `${u?.details?.firstName} ${u?.details?.lastName} (${u?.company?.name})`,
							value: u.id
						}))
					),
				defaultValue: object?.admin ? {
					label: object?.admin?.company?.name,
					value: object?.admin?.id
				} : null,
				defaultOptions: true,
				cacheOptions: true,
				block: true,
				required: type === "serviceMan",
			},
			{
				type: 'radio',
				label: 'وضعیت کاربر',
				name: object && 'active.status',
				disabled: !object,
				onChange: ev => {setActiveStatus(ev); return ev},
				required: false,
				defaultValue: object?.active?.status?.toString() || 'true',
				horizontal: true,
				options: [
					{
						label: 'فعال',
						value: 'true'
					},
					{
						label: 'غیرفعال',
						value: 'false'
					},
				],
				col: 4
			},

			{
				type: 'text',
				label: 'پیام غیرفعال‌سازی',
				placeholder: 'این پیام به کاربر نمایش داده می‌شود.',
				disabled: activeStatus==='true' || !object,
				name: 'active.description',
				defaultValue: object?.active?.description,
				className: 'ps-3',
				col: 4
			},
			{
				type: 'radio',
				label: 'جنسیت',
				name: 'details.gender',
				required: false,
				defaultValue: object?.details?.gender,
				horizontal: true,
				options: [
					{
						label: 'آقا',
						value: 'male'
					},
					{
						label: 'خانم',
						value: 'female'
					},
				],
				col: 4
			},
			{
				type: 'radio',
				label: 'وضعیت تاهل',
				name: 'details.isMarried',
				required: false,
				defaultValue: object?.details?.isMarried,
				horizontal: true,
				options: [
					{
						label: 'مجرد',
						value: false
					},
					{
						label: 'متاهل',
						value: true
					},
				],
				col: 4
			},
			{
				type: 'image',
				label: 'عکس پروفایل',
				name: 'details.avatar',
				required: false,
				defaultValue: object?.details?.avatar,
			},
			{
				type: 'textarea',
				label: 'توضیحات اختیاری',
				name: 'details.description',
				required: false,
				defaultValue: object?.details?.description,
			},
		];

		x.push(
			{
				type: 'custom',
				render: <SectionTitle className="mt-2 mb-0">آدرس های کاربر{type === "serviceMan" && "*"}</SectionTitle>,
				col: 1
			},
			{
				type: 'custom',
				render: <UserAddressList defaultValue={addresses} onChange={setAddresses} />,
				col: 1
			},
		)

		if (type === "serviceMan") {
			x.push(
				{
					type: 'custom',
					render: <SectionTitle className="mt-2 mb-0">سایر اطلاعات خدمت رسان*</SectionTitle>,
					render: <SectionTitle className="mt-2 mb-0">
						<div className="d-flex justify-content-between">
							<div>سایر اطلاعات خدمت رسان*</div>
							{object && (
								<div>اعتبار:‌ {intComma(object?.serviceManSection?.contractDetails?.credit?.current)} تومان</div>
							)}
						</div>
					</SectionTitle>,
					col: 1
				},
				{
					type: 'custom',
					label: 'خدمات:',
					render: <ServiceManServices defaultValue={serviceManServices} onChange={setServiceManServices} />,
					// render: <div>{serviceManServices?.length}</div>,
					col: 1
				},

				// {
				// 	type: 'custom',
				// 	render: <SectionTitle className="mt-2 mb-0">اطلاعات مالی خدمت رسان</SectionTitle>,
				// 	col: 1
				// },
				// {
				// 	type: 'radio',
				// 	label: 'نوع تسویه حساب با شرکت',
				// 	name: 'serviceManSection.financialDetails.checkoutType',
				// 	defaultValue: object?.serviceManSection?.financialDetails?.checkoutType,
				// 	required: true,
				// 	horizontal: true,
				// 	options: [
				// 		{
				// 			label: 'درصدی',
				// 			value: 'percent'
				// 		},
				// 		{
				// 			label: 'ثابت',
				// 			value: 'fixed'
				// 		},
				// 	],
				// 	col: 1
				// },
				// {
				// 	type: 'number',
				// 	label: 'میزان دستمزد خدمترسان',
				// 	name: 'serviceManSection.financialDetails.wages',
				// 	defaultValue: object?.serviceManSection?.financialDetails?.wages,
				// 	required: true
				// },
				// {
				// 	type: 'number',
				// 	label: 'میزان کمیسیون شرکت برای این خدمترسان',
				// 	name: 'serviceManSection.financialDetails.commission',
				// 	defaultValue: object?.serviceManSection?.financialDetails?.commission,
				// 	required: true
				// },

				{
					type: 'custom',
					render: <SectionTitle className="mt-2 mb-0">جزئیات قرارداد خدمت رسان*</SectionTitle>,
					col: 1
				},
				{
					type: 'price',
					label: 'میزان سفته خدمترسان در نزد شرکت به منظور تضمین',
					name: 'serviceManSection.contractDetails.promissoryNote',
					defaultValue: object?.serviceManSection?.contractDetails?.promissoryNote,
					required: true,
				},
				{
					type: 'pdate',
					calendarPosition: 'top-right',
					label: 'تاریخ اتمام قرارداد با شرکت',
					name: 'serviceManSection.contractDetails.contractExpirationDate',
					defaultValue: object?.serviceManSection?.contractDetails?.contractExpirationDate && object?.serviceManSection?.contractDetails?.contractExpirationDate.split("T")[0],
					nextYears: 10,
					required: true,
				},
				// {
				// 	type: 'price',
				// 	label: 'میزان اعتبار خدمترسان در حساب شرکت',
				// 	name: 'serviceManSection.contractDetails.credit.current',
				// 	defaultValue: object?.serviceManSection?.contractDetails?.credit?.current,
				// 	required: true,
				// },
				{
					type: 'price',
					label: 'حداقل میزان اعتبار',
					name: 'serviceManSection.contractDetails.credit.minimum',
					defaultValue: object?.serviceManSection?.contractDetails?.credit?.minimum,
					required: true,
				},
				{
					label: 'نحوه تسهیم',
					type: 'radio',
					options: [
						{
							label: 'تسهیم شده',
							value: 'multiplexing'
						},
						{
							label: 'بدون تسهیم',
							value: 'simple'
						},
					],
					name: 'serviceManSection.contractDetails.paymentMethod',
					defaultValue: object?.serviceManSection?.contractDetails?.paymentMethod,
					horizontal: true,
				},
				{
					type: 'number',
					label: 'کد حسابداری',
					name: 'serviceManSection.contractDetails.accountingNumber',
					defaultValue: object?.serviceManSection?.contractDetails?.accountingNumber,
				},				
			)
		}
		x.push({
			type: 'submit',
			value: object ? 'ذخیره' : 'ایجاد کاربر',
			col: 1
		})
		setFields(x);
	}, [object, collaboration, serviceManServices, addresses, type, activeStatus]);


	const handleSubmit = data => {

		delete data['supportCode'];
		delete data['point'];
		data = {
			...data,
			details: {
				...(data?.details || []),
				addresses: addresses,
			},
			
		}

		if (data.type === "serviceMan" && !addresses?.length) {
			Swal.fire({
				icon: 'warning',
				text: 'حداقل یک آدرس برای خدمت‌رسان وارد کنید.',
				confirmButtonText: 'باشه'
			});
			return;
		}

		if (data.type === "customer") {
			data = {
				...data,
				role: getRoleByName(roles, 'user')?.id
			}
		} else if (data.type === "serviceMan") {
			if (!serviceManServices?.length) {
				Swal.fire({
					text: 'حداقل یک خدمت برای متخصص مشخص کنید.',
					icon: 'warning',
					confirmButtonText: 'باشه'
				});
				return;
			}
			data = {
				...data,
				role: getRoleByName(roles, 'serviceMan')?.id,
				serviceManSection: {
					...(data?.serviceManSection || []),
					services: serviceManServices
				},
			}
		}

		if (!id) {
			delete data.active;
		}

		// delete data['details'];

		setLoading(true);
		submitUserAPI({data: removeEmpty(data, [null, undefined, ""], true), pk: id || null}).then(res=> {
			setLoading(false);
			Swal.fire({
				title: 'تبریک!',
				text: `کاربر با موفقیت ${id ? 'ویرایش' : 'ایجاد'} شد.`,
				icon: 'success',
				confirmButtonText: 'باشه'
			});
			setObject(res);
			history.push(`/users/${res.id}/edit/`);
		}).catch(err => {
			setLoading(false);
		})
	}

    const load = () => {
        setLoading(true);
        getUserAPI({pk: id}).then(res => {
            setObject(res);
            setLoading(false);
			setType(res.type);
			setActiveStatus(res.active?.status || true);
			setServiceManServices(res?.serviceManSection?.services?.map(serv => ({
				id: serv?.service?.id,
				description: serv?.description,
				enabled: serv?.enabled,
				financialDetails: serv?.financialDetails,
				minimumScore: serv?.minimumScore
			})));
			setAddresses(res?.details?.addresses?.map(add => ({
				title: add.title,
				content: add.content,
				zoneId: add?.zone?.id
			})));
			// setData(defaultValue?);
        }).catch(err => {
            setLoading(false);
        })
    }

	const loadCollaboration = () => {
        setLoading(true);
		getCollaborationAPI({pk: collaborationid}).then(res => {
			setCollaboration(res);
			setType('serviceMan');
			setLoading(false);
		}).catch(err => setLoading(false));
	}

	const pageMeta = <>
		{id && <Button 
					error
					small
					icon="trash"
					onClick={() => deleteUserAskAPI({
						pk: id, 
						name:'کاربر', 
						success: () => history.push("/users")
					})}>حذف</Button>}
		<Link to="/users/">
			<Button small secondary icon="angle-left">بازگشت</Button>
		</Link>
	</>

    return (
        <Style className={className}>
            <Page title={id ? 'ویرایش کاربر' : 'کاربر جدید'} meta={pageMeta}>
                <Spin active={loading}>
					{object?.id && <UserEditTabs user={object} />}
					<DynamicForm fields={fields} block cols={2} onSubmit={handleSubmit} />
                </Spin>
            </Page>
        </Style>
    )
}

export default UserNew;