import React from "react";
import styled from "styled-components";

const LoadingSpinnerComp = ({className}) => {
    const cls = className;


    return (
        <div className={cls}>
            <svg className={`${cls}__spinner`}>
                <circle className={`${cls}__spinner__path`} cx="25" cy="25" r="20" fill="none" strokeWidth="5"/>
            </svg>
            <div className={`${cls}__text`}>
                Loading
            </div>
        </div>
    )
}

const LoadingSpinner = styled(LoadingSpinnerComp)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  &__spinner {
    animation: rotate 2s linear infinite;
    //margin-left: calc(100vw - 100%);
    width: 50px;
    height: 50px;
    
    &__path {
      stroke: #00ADEE;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }
  
  &__text {
    margin-top: .5rem;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`

export default LoadingSpinner;