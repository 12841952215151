import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import navItems from './navmap';
import { CONSTS } from 'components/elements/_constants';
import { useEffect } from 'react';
import { Link } from 'components/elements';
const Styles = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
        a {
            position: relative;
            display: block;
            padding: 8px 20px;
            text-decoration: none;
            color: #333;
            font-weight: 500;
            border-radius: 5px;
            transition: background-color .15s, color .15s;
            &:hover {
                color: ${CONSTS.colors.primary};
            }
        }
        &.active {
            a {
                background-color: ${CONSTS.colors.primary};
                color: #fff;
            }
        }
        &.has-children {
            & > a {
                &::before {
                    position: absolute;
                    content: '';
                    right: 4px;
                    top: 50%;
                    width: 5px;
                    height: 5px;
                    transform: translateY(-50%) rotate(-45deg);
                    border: 1px solid transparent;
                    border-top-color: #333;
                    border-left-color: #333;
                    transition: transform .25s;
                }
            }
            li {
                a {
                    display: none;
                }
            }
            &.expanded {
                li {
                    a {
                        display: block;
                    }
                }
                & > a {
                    color: ${CONSTS.colors.primary};
                    &::before {
                        transform: translateY(-50%) rotate(-135deg);
                        border-top-color: ${CONSTS.colors.primary};
                        border-left-color: ${CONSTS.colors.primary};
                    }
                }
            }
        }
		&.disabled {
			a {
				color: #999;
				cursor: auto;
			}
		}
		&.child {
			margin-right: 15px;
		}
		.badge {
			margin: 0 10px;
			background-color: rgba(98, 93, 207, .1);
			border-radius: 10px;
			color: #000;
			font-size: 12px;
			padding: 3px 9px;
			font-weight: 300;
		}
    }
`

const Nav = ({ className, items }) => {

    const location = useLocation();
    const [menuItems, setMenuItems] = useState();

    useEffect(() => {
        if (!items) return;

        const x = items.map(item => {
            if (item.children && item.children.length > 0) {
                return {
                    ...item,
                    expanded: (typeof item?.expanded === "boolean") ? item.expanded : (item.children.find(isActive))
                }
            }
            return item
        });
        setMenuItems(x);
    }, [items])

    const isActive = (item) => {

        // if ([undefined, true].includes(item.exact)) {
            return location?.pathname === item?.to;
        // } else {
        //     return location?.pathname.startsWith(item?.to);
        // }


        // if (!item.href && target === item.label) {
        //     return true;
        // }
        // if (item.exact) {
        //     return location.pathname === item.to;
        // }
        // return location.pathname.startsWith(item.to);
    }

    const renderItem = (item, index, classes=[]) => {
		
        const { label, to } = item;
        // let classes = [];
        let childItems = null;
		let badge = null;

		if (item.visible === false) {
			classes.push("d-none");
		};

        if (item.children) {
            
            if (item.expanded) {
                classes.push("expanded")
            }

			if (item.children.every(child => child.visible === false)) {
				return null;
			}

            classes.push("has-children");
            childItems = <>
                {item.children
						.filter(child => [undefined, true].includes(child.visible))
						.map( (child, index) => renderItem(child, index, ["child"]))
						}
            </> 
            
        }

		if (item.disabled) {
			classes.push("disabled")
		}

		if (item.badge) {
			badge = <span className="badge">{item.badge}</span>
		}
        
        if (isActive(item)) {
            classes.push("active")
        }

		const Comp = item.children ? styled.a`cursor: pointer;` : Link;

        return (
            <li key={index} className={classes.join(' ')}>
                <Comp to={to} onClick={() => item.children ? handleItemClick(index) : null}>
                    {label}
					{badge}
                </Comp>
                <>
                    {childItems}
                </>
            </li>
        )
    }

    const handleItemClick = (itemIndex) => {
        const current = menuItems[itemIndex];
        if (current.children) {
            setMenuItems(items => {
                const x = items.map((item, index) => index===itemIndex ? ({...item, expanded: !item.expanded}) : item)
                return x;
            });
        }
    }

    return (
        <Styles className={className}>
            {menuItems?.map((item, index) => renderItem(item, index))}
        </Styles>
    )
}

export default Nav