import { Switch, Route, Redirect } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

import "./static/css/style.css";

// Auth Scenes
import AuthScene from './scenes/auth';

// User Scenes
import { useState } from 'react';
import AdminScene from 'scenes/admin';
import { ThemeContainer } from 'components/elements';
import { PrintScene } from 'scenes/print';


// Register Font Awesome
library.add(fab, fas, far);
let bypass= false;

const App =  () => {

	const theme = {
		text: '#000',
		background: '#fff',
		primary: '#086ad9'
	}

	const [showApp, setShowApp] = useState(true);

	if (showApp) {
		return (
			<ThemeContainer theme={theme}>
				<BrowserRouter>
					<Switch>
						<Route path="/auth/" component={AuthScene} />
						<Route path="/print/" component={PrintScene} />
						<Route path="/" component={AdminScene} />
					</Switch>
				</BrowserRouter>
			</ThemeContainer>
		);
	} else {
		return null;
	}

}

export default  App;
