import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { getCategoryAPI } from "components/api/categories";
import { deleteCollaborationAskAPI, getCollaborationAPI } from "components/api/collaborations";
import { deleteServiceAskAPI, getServiceAPI } from "components/api/services";
import { deleteUserAskAPI } from "components/api/users";
import { Avatar, Badge, Button, DynamicForm, DynamicFormView, Link, Modal, Pagination, Spin, Table, Tabs, Title } from "components/elements";
import { Page } from "components/shared/layouts";
import SearchInput from "components/shared/searchinput";
import { BASE_URL, WEBSITE_BASE_URL } from "inc/constants";
import { getURLParams, serialize } from "inc/functions";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import Swal from "sweetalert2";
import persianDate from "persian-date"
import { deleteContactUsAskAPI, getContactUsAPI } from "components/api/contactUs";


const Style = styled.div`
    
`
const ContactUsList = ({className}) => {

	const defaultFilters = {
		limit: 20,
		skip: 0,
	}

    const history = useHistory()

    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({...defaultFilters, ...getURLParams(history.location.search)});

	useEffect(() => {
        load(getURLParams(history.location.search));
    }, [history.location.search]);

	useEffect(() => {
        history.push(`/contactus/?${serialize(filters)}`);
    }, [filters]);


    const load = (params) => {
        setLoading(true);
        getContactUsAPI({params: params}).then(res => {
            setData(res);
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }

    const columns = [
		{
			title: 'نام',
			dataIndex: 'name',
		},
        {
            title: 'شماره تلفن',
            dataIndex: 'phone',
        },
        {
            title: 'عنوان',
            dataIndex: 'topic',
        },
        {
            title: 'زمان ثبت',
            dataIndex: 'createdAt',
			render: d => new persianDate(new Date(d)).format("dddd D MMMM ساعت HH:mm")
        },
		{
            title: 'پیام',
            dataIndex: 'message',
			render: m => m.length > 20 ? <div className="cursor-pointer" onClick={() => Swal.fire({
				text: m,
				confirmButtonText: 'بستن'
			})}>
				{m.substr(0, 20)}{m.length > 20 && '...'}
			</div> : m
        },
        // {
        //     title: '',
        //     dataIndex: 'id',
        //     render: (id) => (
        //         <>
        //             <Button small error icon="trash" onClick={() => deleteContactUsAskAPI({pk: id, success: () => load({})})} />
        //         </>
        //     )
        // }
    ]

    return (
        <Style className={className}>
            <Page title="تماس با ما">
                <Spin active={loading}>
                    <Table columns={columns} dataSource={data?.data} />
                </Spin>
                <Pagination count={data?.count || 0} current={filters.skip / 20 + 1} pageSize={20} onChange={(p) => setFilters(x => ({...x, skip:(p -1 ) * 20}))}/>
            </Page>
        </Style>
    )
}

export default ContactUsList;