import { useDispatch, useSelector } from "react-redux";
import avatarImg from '../../../static/img/avatar.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getOrLoadWhoamiAction, memberLogoutAction } from 'redux/actions/auth';
import { SWEET_BUTTONS } from "../../../inc/constants";
import { Avatar, Link } from '../../elements'
import Swal from 'sweetalert2';
import { useEffect } from "react";
import { useState } from "react";
import { CONSTS } from "components/elements/_constants";
import { useRef } from "react";

const { default: styled } = require("styled-components");

const UserNavStyle = styled.div`
	position: relative;
    display: flex;
    align-items: center;
    .usernav {
        &__avatar {
            border: 1px solid #fff;
            border-radius: 10px;
            overflow: hidden;
			cursor: pointer;
			&:hover {
				border-color: ${CONSTS.colors.primary};
			}
        }
		&__popup {
			position: absolute;
			z-index: 800;
			left: 0;
			top: calc(100% + 10px);
			border-radius: 10px;
			background-color: #fff;
			border: 1px solid #ddd;
			box-shadow: 0 20px 35px rgba(0, 0, 0, .1);
			min-width: 260px;
			opacity: 0;
			pointer-events: none;
			transition: opacity .2s, transform .2s;
			transform: translateY(10px);
			&__header {
				position: relative;
				padding: 15px;
				font-weight: normal;
				display: block;
			}
			&__footer {
				border-top: 1px solid #eee;
				padding: 5px 0;
			}
			&__nav {
				list-style-type: none;
				padding: 0;
				margin: 0;
				li {
					a {
						text-align: center;
						display: block;
						padding: 10px 15px;
						text-decoration: none;
						color: ${CONSTS.colors.primary};
						border-top: 1px solid #eee;
						font-weight: 300;
						font-size: 16px;
						transition: background-color .2s;
						&:hover {
							background-color: rgba(0, 0, 0, .02);
						}
					}
					&.danger {
						a {
							color: ${CONSTS.colors.error};
						}
					}
				}
			}
		}
		&__role {
			position: absolute;
			left: 15px;
			top: 15px;
			color: #616161;
		}
		&__name {
			font-size: 1rem;
			margin-bottom: 5px;
		}
		&__email {
		}
    }
	${({isOpen}) => isOpen ? `
		.usernav {
			&__popup {
				opacity: 1;
				pointer-events: all;
				transform: translateY(0);
			}
		}
	` : ''}
`
const UserNav = ({className}) => {

	const [isOpen, setIsOpen] = useState(false);

	const navRef = useRef();

    const member = useSelector(state => state.auth.member);

    const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getOrLoadWhoamiAction());
	}, [])

	useEffect(() => {

		if (navRef?.current) {
			window.addEventListener('click', function(e){   
				if (!navRef.current?.contains(e.target)){
					setIsOpen(false);
				}
			});
		} 

	}, [navRef?.current])

    const user = {
		...member,
		avatar: avatarImg,
        name: `${member?.details?.firstName} ${member?.details?.lastName}`,
    }

    const logout = () => {
        Swal.fire({
            icon:"warning",
            text: "آیا از خروج اطمینان دارید؟",
            cancelButtonText: 'نه',
            confirmButtonText: 'آره',
            showCancelButton: true
        }).then(({isConfirmed}) => {
            if (!isConfirmed) return ;
            dispatch(memberLogoutAction());
        });
    }

	const toggleOpen = () => {
		setIsOpen(x => !x);
	}

    return (
		<UserNavStyle ref={navRef} className={className} isOpen={isOpen}>
			<Avatar onClick={toggleOpen} size={40} className="usernav__avatar" src={user.avatar} bg />
			<div className="usernav__popup">
				<div className="usernav__popup__header">
					{user.is_admin && <div className="usernav__role">مدیر</div>}
					<div className="usernav__name">{user.name}</div>
					<div className="usernav__email">{user?.details?.email}</div>
				</div>
				<ul className="usernav__popup__nav">
					<li>
						<Link to="/profile/">
							حساب کاربری
						</Link>
					</li>
					<li className="danger">
						<a href="#" onClick={logout}>
							خروج
						</a>
					</li>
				</ul>
			</div>
		</UserNavStyle>
    )
}


export default UserNav;