import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteOrderAskAPI, getOrderAPI, orderPaymentStatuses, orderPaymentTypes, orderStatusLabelMap, ORDER_CREATEDWITH_OPTIONS, ORDER_TIME_OPTIONS, submitOrderAPI } from "components/api/orders";
import { getQuestionAPI } from "components/api/questions";
import { getServiceAPI } from "components/api/services";
import { deleteUserAskAPI, getUserAPI } from "components/api/users";
import { getZoneAPI } from "components/api/zones";
import { Avatar, Badge, Button, DynamicForm, DynamicFormView, Link, Modal, Pagination, Spin, Table, Tabs, Title } from "components/elements";
import { Page } from "components/shared/layouts";
import SectionTitle from "components/shared/sectionTitle";
import { debounce, removeDeepEmptyObject, removeEmpty } from "inc/functions";
import persianDate from "persian-date";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import Swal from "sweetalert2";
import { CalculateOrderPriceModal, getQuestionFormField, OrderEditTabs } from "./utils";


const Style = styled.div`
    
`
const OrderNew = ({className, match}) => {

	const history = useHistory();

    const [object, setObject] = useState();
    const [customer, setCustomer] = useState();
    const [service, setService] = useState();
    const [serviceMan, setServiceMan] = useState();
    const [questions, setQuestions] = useState();
    const [address, setAddress] = useState();
	const [answers, setAnswers] = useState({});
    const [loading, setLoading] = useState(false);
	const [fields, setFields] = useState();
	const [zones, setZones] = useState();
	const [services, setServices] = useState();
	const [calculatePriceModalActive, setCalculatePriceModalActive] = useState(false);

	const { id } = match.params;
    
    useEffect(() => {

		if (id) {
			load();
		}
		getZoneAPI({params: {limit: 100}}).then(res => {
			setZones(res?.data);
		});
		getServiceAPI({}).then(res => {
			setServices(res?.data);
		});

    }, []);

	const debouncedUserSearch = debounce((q) => getUserAPI({params: {cellphone: q}, throwCatch: false}).then(res => {
		if (res.data.length > 0) {
			setCustomer(res.data[0]);
		} else {
			setCustomer(null);
		}
	}), 500);

	const debouncedServiceSearch = debounce((q) => getServiceAPI({params: {serviceCode: q}}).then(res => {
		if (res.data.length > 0) {
			setService(res.data[0]);
			getQuestionAPI({params: {serviceId: res.data[0].id}}).then(res => {
				setQuestions(res.data);
			}).catch(err => {});
		} else {
			setService(null);
		}
	}), 500);

	const debouncedServiceManSearch = debounce((q) => getUserAPI({params: {cellphone: q}, throwCatch: false}).then(res => {
		if (res.data.length > 0) {
			setServiceMan(res.data[0]);
		} else {
			setServiceMan(null);
		}
	}), 500);

	useEffect(() => {
		const f = [
			{
				label: 'تلفن مشتری',
				onChange: ev => {
					debouncedUserSearch(ev.target.value);
					return ev.target.value;
				},
				required: true,
				defaultValue: customer?.cellphone,
				col: 2
			},
			{
				label: 'نام مشتری',
				disabled: true,
				defaultValue: customer && `${customer.firstName || ''} ${customer.lastName || ''}`,
				col: 2
			},
			{
				label: 'تلفن خدمت رسان',
				onChange: ev => {
					debouncedServiceManSearch(ev.target.value);
					return ev.target.value;
				},
				// required: true,
				defaultValue: serviceMan?.cellphone,
				col: 2
			},
			{
				label: 'نام خدمت سان',
				disabled: true,
				defaultValue: serviceMan && `${serviceMan.firstName || ''} ${serviceMan.lastName || ''}`,
				col: 2,
			},
			{
				label: 'کد خدمت',
				onChange: ev => {
					debouncedServiceSearch(ev.target.value);
					return ev.target.value;
				},
				required: true,
				defaultValue: service?.supportCode,
				col: 2
			},
			{
				label: 'خدمت',
				disabled: true,
				defaultValue: service?.name,
				col: 4,
			},
			{
				label: 'روش',
				disabled: true,
				defaultValue: ORDER_CREATEDWITH_OPTIONS[object?.createdWith || 'admin-panel'],
				col: 4
			},
		];

		if (questions?.length > 0) {
			f.push({
				type: 'custom',
				render: <SectionTitle className="mb-0 mt-3">سوالات</SectionTitle>,
				col: 1
			})
			questions.forEach(q => {
				f.push(getQuestionFormField(q, handleChangeAnswer));
			});
		}

		if (customer && service) {

			f.push(
				// address
				{
					type: 'custom',
					render: <SectionTitle className="mb-0 mt-3">آدرس</SectionTitle>,
					col: 1
				},
			)
	
			if (customer?.details?.addresses?.length > 0) {
				f.push(
					{
						type: 'radio',
						label: 'یکی از آدرس های زیر را انتخاب کنید یا آدرس جدید وارد کنید',
						options:
							customer?.details?.addresses?.map((addr, addri) => ({
								label: `${addr.title} - ${addr.content}`,
								value: addri
							}))
						,
						required: true,
						onChange: ev => {
							setAddress(customer.details.addresses[ev]);
							return ev;
						},
						col: 1
					},
				)
			}

			f.push(
				{
					label: 'عنوان آدرس',
					name: 'address.title',
					defaultValue: object?.address?.title || address?.title,
					defaultValue: address?.title,
					required: true,
					col: 2,
				},
				{
					label: 'منطقه',
					type: 'react-select',
					name: 'address.zoneId',
					defaultValue: address?.zone && zones?.find(z => z.latitude === address?.zone?.latitude && z.longitude === address?.zone?.longitude)?.id,
					options: zones?.map(({name, id}) => ({label: name, value: id})),
					required: true,
					col: 2,
				},
				{
					label: 'جزئیات آدرس',
					type: 'textarea',
					name: 'address.content',
					defaultValue: object?.address?.content || address?.content,
					required: true,
				},
				
			)

			f.push(
				{
					type: 'custom',
					render: <SectionTitle className="mb-0 mt-3">جزئیات سفارش</SectionTitle>,
					col: 1
				},
				{
					type: 'select',
					label: 'وضعیت سفارش',
					name: 'status',
					defaultValue: object?.status,
					options: Object.entries(orderStatusLabelMap).map(([value, label]) => ({label, value}) ),
					col: 2
				},
				{
					label: 'شماره تماس سفارش',
					name: 'orderPhone',
					defaultValue: object?.orderPhone || customer?.cellphone,
					key: 'orderPhone',
					col: 2
				},
				{
					type: 'textarea',
					name: 'orderDescription',
					label: 'توضیحات',
					defaultValue: object?.orderDescription,
					col: 2
				},
				// {
				// 	type: 'textarea',
				// 	name: 'cancellationReason',
				// 	label: 'دلایل کنسلی',
				// 	placeholder: 'هر دلیل را در یک خط بنویسید',
				// 	defaultValue: object?.description,
				// 	col: 2
				// },

				// date and time
				{
					type: 'custom',
					render: <SectionTitle className="mb-0 mt-3">تاریخ و زمان</SectionTitle>,
					col: 1
				},
				{
					type: 'pdate',
					label: 'تاریخ',
					name: 'orderDate',
					defaultValue: object?.orderDate && new persianDate(new Date(object.orderDate)).toLocale('en').format("YYYY-M-D"),
					required: true,
					block: true,
					calendarPosition: 'top-right'
				},
				{
					type: 'select',
					label: 'زمان',
					name: 'orderTime',
					defaultValue: object?.orderTime,
					options: ORDER_TIME_OPTIONS.map(time => ({
						label: time.split("-").join(" الی "),
						value: time
					})),
					required: true,
				},
			)

			f.push(
				{
					type: 'image',
					label: 'تصاویر اضافی',
					name: 'orderImages',
					defaultValue: object?.orderImages?.[0],
				},
			)

			f.push(
				{
					type: 'custom',
					render: <SectionTitle className="mb-0 mt-3 d-flex justify-content-between align-items-center">
						<span>
							جزئیات مالی
						</span>
						{object && (
							<Button onClick={() => setCalculatePriceModalActive(true)} className="py-0" sm link>محاسبه قیمت</Button>
						)}
					</SectionTitle>,
					col: 1
				},
				{
					type: 'price',
					label: 'مبلغ اُجرت خدمت‌رسان',
					name: 'amount.wage',
					defaultValue: object?.amount?.wage,
					// required: true,
					col: 2
				},
				{
					type: 'price',
					label: 'مبلغ وسایل و مواد اولیه جهت انجام خدمت',
					name: 'amount.materials',
					defaultValue: object?.amount?.materials,
					// required: true,
					col: 2
				},
				{
					type: 'price',
					label: 'مبلغ نهائی سفارش پس از تخفیف',
					name: 'amount.total',
					defaultValue: object?.amount?.total,
					// required: true,
					col: 1
				},
				{
					type: 'price',
					label: 'پیش پرداخت',
					name: 'amount.upfront.amount',
					defaultValue: object?.amount?.upfront?.amount,
					// required: true,
					col: 2
				},
				{
					type: 'price',
					label: 'مابقی مبلغ پرداخت شده',
					name: 'amount.settlement.amount',
					defaultValue: object?.amount?.settlement?.amount,
					// required: true,
					col: 2
				},
				{
					type: 'select',
					label: 'نحوه پرداخت',
					name: 'amount.settlement.type',
					defaultValue: object?.amount?.settlement?.type,
					options: Object.entries(orderPaymentTypes).map(([value, label]) => ({
						label,
						value
					})),
					col: 2
				},
				{
					type: 'select',
					label: 'وضعیت پرداخت',
					name: 'amount.settlement.status',
					defaultValue: object?.amount?.settlement?.status,
					options: Object.entries(orderPaymentStatuses).map(([value, label]) => ({
						label,
						value
					})),
					col: 2
				},
				{
					type: 'price',
					label: 'مقدار مالیات',
					name: 'amount.settlement.taxAmount',
					defaultValue: object?.amount?.settlement?.taxAmount,
					// required: true,
					col: 2
				},
				{
					type: 'price',
					label: 'مقدار سهم شرکت از مبلغ تسویه حساب',
					name: 'amount.settlement.commissionAmount',
					defaultValue: object?.amount?.settlement?.commissionAmount,
					// required: true,
					col: 2
				},
				{
					type: 'price',
					label: 'سهم نهایی شرکت',
					name: 'amount.settlement.multiplexingDetails.companyAmount',
					defaultValue: object?.amount?.settlement?.multiplexingDetails?.companyAmount,
					// required: true,
					col: 2
				},
				{
					type: 'price',
					label: 'سهم نهایی خدمت‌رسان',
					name: 'amount.settlement.multiplexingDetails.serviceManAmount',
					defaultValue: object?.amount?.settlement?.multiplexingDetails?.serviceManAmount,
					// required: true,
					col: 2
				}
			)

			f.push(
				{
					label: ' ',
					type: 'submit',
					value: 'ذخیره',
					col: 1
				}
			)
		}


		setFields(f);
	}, [object, customer, service, questions, serviceMan, zones, address]);

	const handleChangeAnswer = (questionId, userAnswer) => {
		setAnswers(prevAnswers => ({...prevAnswers, [questionId]: userAnswer}));
		return userAnswer;
	}


	const handleSubmit = data => {

		if (questions?.length > 0 && Object.keys(answers).length !== questions?.length) {
			Swal.fire({
				text: 'لطفا به تمام پرسش ها پاسخ دهید',
				icon: 'warning',
				confirmButtonText: 'باشه'
			});
			return;
		}

		if (data?.status === "order-completed" && !object?.tender) {
			Swal.fire({
				text: 'قبل از تکمیل سفارش باید مناقصه و خدمت‌رسان مشخص شده باشد.',
				icon: 'warning',
				confirmButtonText: 'باشه',
			});
			return;
		}
		
		data = {
			...data,
			customerId: customer.id,
			serviceManId: serviceMan?.id,
			serviceId: service.id,
			orderImages: data.orderImages ? [data.orderImages] : null,
			answers: Object.entries(answers).map(([questionId, answer]) => ({questionId, answer})),
			orderDate: new persianDate(data.orderDate.split("-").map(x => parseInt(x))).toCalendar('gregorian').toLocale('en-US').format('YYYY-MM-DD'),
			// cancellationReason: data.cancellationReason?.split("\n")?.filter(x => x?.trim().length > 0),
		}

		data = removeDeepEmptyObject(data);

		if (data.amount) {
			data = {
				...data,
				amount: {
					...data?.amount,
					upfront: {
						...data?.amount?.upfront,
						type: 'cash',
						status: 'done'
					},
					settlement: {
						...data?.amount?.settlement,
					}
				}
			}
		}

		setLoading(true);
		submitOrderAPI({data: removeEmpty(data), pk: id || null}).then(res=> {
			setLoading(false);
			Swal.fire({
				title: 'تبریک!',
				text: `سفارش با موفقیت ${id ? 'ویرایش' : 'ایجاد'} شد.`,
				icon: 'success',
				confirmButtonText: 'باشه'
			});
			history.push(`/orders/${res.id}/edit/`);
		}).catch(err => {
			setLoading(false);
		})
	}

    const load = () => {
        setLoading(true);
        getOrderAPI({pk: id}).then(res => {
            setObject(res);
			setCustomer(res.customer);
			setService(res.service);
			setServiceMan(res.serviceMan);
			setAddress(res.address);
			setAnswers(Object.fromEntries(
				res.answers.map(x => [x.questionId.id, x.answer])
			));
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }

	const afterCalculatePrice = d => {
		setObject(d);
		setCalculatePriceModalActive(false);
		window.location.reload();
	}

	const pageMeta = <>
		{/* {id && <Button 
					error
					small
					icon="trash"
					onClick={() => deleteOrderAskAPI({
						pk: id, 
						name:'سفارش', 
						success: () => history.push("/orders")
					})}>حذف</Button>} */}
		{id && <a href="/orders/new/">
					<Button icon="plus" small>جدید</Button>
			</a>}
		<Link to="/orders/">
			<Button small secondary icon="angle-left">بازگشت</Button>
		</Link>
	</>

    return (
        <Style className={className}>
            <Page title={id ? `ویرایش سفارش ${object?.supportCode || ''}` : 'سفارش جدید'} meta={pageMeta}>
				<OrderEditTabs id={id} />
                <Spin active={loading}>
					<DynamicForm fields={fields} block onSubmit={handleSubmit} />
                </Spin>
				<CalculateOrderPriceModal active={calculatePriceModalActive} orderId={id} afterSubmit={afterCalculatePrice} onToggle={() => setCalculatePriceModalActive(false)} />
            </Page>
        </Style>
    )
}

export default OrderNew;