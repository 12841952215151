import CityList from "./list";
import CityNew from "./new";
const { Switch, Route } = require("react-router")

const Cities = () => {
    return (
        <Switch>
            <Route path="/cities/" exact component={CityList} />
            <Route path="/cities/new/" exact component={CityNew} />
            <Route path="/cities/:id/edit/" exact component={CityNew} />
        </Switch>
    )
}

export default Cities;