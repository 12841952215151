import { Card, DynamicForm, Avatar, Badge, Button, Icon, Title, Link, Text, Modal, Spin} from 'components/elements';
import {getAPIDefaultHeaders, handleCatch, parseJwt, setToken} from 'inc/functions'
import {BASE_URL} from 'inc/constants'
import axios from 'axios';
import {useEffect, useState} from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { memberLoginAction } from 'redux/actions/auth';

import { motion, AnimatePresence } from "framer-motion"

const Styles = styled.div`
    min-height: 100%;
    display: flex;
    flex-direction: column;
    .login {
        &__wrapper {
            margin-top: auto;
        }
        &__title{
            font-size: 1.2rem;
            text-align: center;
        }
    }
`

export const Agreement = styled.p`
    margin-bottom: 0px;
    a {
        text-decoration: none;
    }
`

const fields = [
    {
        label: 'نام کاربری',
        name: 'username',
        placeholder: '.نام کاربری خود را وارد کنید',
        required: true,
    },
    {
        type: 'password',
        label: 'رمز عبور',
        name: 'password',
        placeholder: 'رمز عبور خود را وارد کنید.',
        required: true,
    },
    {
        type: 'submit',
        value: 'ورود',
    }
]

const Login = () => {
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const authenticate = data => { //api call
        setLoading(true);
        axios.post(BASE_URL + '/auth', data).then(result => {
            setLoading(false);
            const { token } = result.data;
            dispatch(memberLoginAction(token));

        }).catch(error => {
            setLoading(false);
            handleCatch(error, true);
        })
    }

    return (
        <Styles>
            {/* <LoginTabs /> */}
            <Spin active={loading}>
                <div>
					{/* <Text>رمز عبور خود را فراموش کرده‌اید؟ <Link primary to="/auth/forget-password/">بازیابی رمز عبور</Link> </Text> */}
                    <DynamicForm fields={fields}
                        buttonClassName={'mt-4 p-2'}
                        onSubmit={authenticate} block>
                    </DynamicForm>
                </div>
            </Spin>
        </Styles>

    )
}
export default Login