import React, {useState} from 'react';
import styled from 'styled-components';
import {CONSTS}  from './_constants';
// import {Link} from 'react-router-dom';
import {Link} from './typography';
import Icon from './icon';
import { useLocation } from 'react-router';

const Tabs = ({className, items, pathname, onChange, ...otherProps}) => {
    const location = useLocation();

    const isActiveItem = item => {
		
		const to = item?.to?.split("?")?.[0];
        
		const exact = typeof item.exact === "boolean" ? item.exact : true;
        
		if (exact) {
            return location.pathname === to;
        }
        return location.pathname.startsWith(to);
    }
    
    return (
        <div className={className} {...otherProps}>
            <ul>
                {items?.filter(item => item?.visible !== false)?.map((item, index) => {
                    const {to, label, badge, ...others} = item;
                    return (
                        <li key={index} className={isActiveItem(item) ? 'active' : ''}>
                            <Link to={to} {...others}>
                                {label}
								{badge && (
									<span className='tabs__badge'>{badge}</span>
								)}
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

const StyledTabs = styled(Tabs)`
	.tabs {
		&__badge {
			background-color: ${CONSTS.colors.error};
			color: #fff;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			font-size: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 4px;
		}
	}
    > ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        border-bottom: 2px solid #eee;
        li{
            a{
                position: relative;
                color: #888;
                border-bottom: 2px solid transparent;
                padding-top: 15px;
                padding-bottom: 15px;
                margin-left: 30px;
                font-size: 1rem;
                transition: color .25s;
                display: flex;
				align-items: center;
                &:hover{
                    text-decoration: none;
                }
                &::before {
                    position: absolute;
                    content: '';
                    top: calc(100% + 2px);
                    height: 2px;
                    left: 0;
                    right: 0;
                    transition: background-color .25s;
                }
            }
            &.active{
                a{
                    color: ${CONSTS.colors.primary};
                    &::before {
                        background-color: ${CONSTS.colors.primary};
                    }
                }
            }
        }
    }
    /* ${CONSTS.selectors.rtl}{
        ._label{
            margin-left: 0;
            margin-right : 7px;
        }
    } */
`

export default StyledTabs;