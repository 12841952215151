import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { getCategoryAPI } from "components/api/categories";
import { deleteCollaborationAskAPI, getCollaborationAPI } from "components/api/collaborations";
import { deleteServiceAskAPI, getServiceAPI } from "components/api/services";
import { deleteUserAskAPI } from "components/api/users";
import { Avatar, Badge, Button, DynamicForm, DynamicFormView, Link, Modal, Pagination, Spin, Table, Tabs, Title } from "components/elements";
import { Page } from "components/shared/layouts";
import SearchInput from "components/shared/searchinput";
import { BASE_URL, WEBSITE_BASE_URL } from "inc/constants";
import { getURLParams, serialize } from "inc/functions";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import Swal from "sweetalert2";
import persianDate from "persian-date"


const Style = styled.div`
    
`
const CollaborationList = ({className}) => {

	const defaultFilters = {
		limit: 20,
		skip: 0,
	}

    const history = useHistory()

    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({...defaultFilters, ...getURLParams(history.location.search)});

	useEffect(() => {
        load(getURLParams(history.location.search));
    }, [history.location.search]);

	useEffect(() => {
        history.push(`/collaborations/?${serialize(filters)}`);
    }, [filters]);


    const load = (params) => {
        setLoading(true);
        getCollaborationAPI({params: params}).then(res => {
            setData(res);
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }

    const columns = [
		{
			title: 'نام',
			dataIndex: 'firstName',
		},
		{
			title: 'نام خانوادگی',
			dataIndex: 'lastName',
		},
		{
            title: 'شهر',
			dataIndex: 'city'
        },
        {
            title: 'شماره تلفن',
            dataIndex: 'cellphone',
        },
        {
            title: 'زمینه پیشنهاد',
            dataIndex: 'category',
			render: c => c?.title
        },
        {
            title: 'زمان ثبت',
            dataIndex: 'createdAt',
			render: d => new persianDate(new Date(d)).format("dddd D MMMM ساعت HH:mm")
        },
        {
            title: '',
            dataIndex: 'id',
            render: (id) => (
                <>
                    <Button small error icon="trash" onClick={() => deleteCollaborationAskAPI({pk: id, success: () => load({})})} />
					<Link to={`/users/new/${id}/`}>
						<Button small icon="plus" />
					</Link>
                </>
            )
        }
    ]

    return (
        <Style className={className}>
            <Page title="فرم همکاری">
                {/* <div className="row justify-content-between my-2">
                    <div className="col-3">
                        <SearchInput defaultValue={filters?.name} block onChange={q => setFilters(f => q ? {...f, name: q, ...defaultFilters} : {...defaultFilters} )} />
                    </div>
                    <div className="col-5 text-start">
						<Link to="/services/new/">
							<Button>خدمت جدید</Button>
						</Link>
                    </div>
                </div> */}
                {/* <div style={{height: 60}} /> */}
                <Spin active={loading}>
                    <Table columns={columns} dataSource={data?.data} />
                </Spin>
                <Pagination count={data?.count || 0} current={filters.skip / 20 + 1} pageSize={20} onChange={(p) => setFilters(x => ({...x, skip:(p -1 ) * 20}))}/>
            </Page>
        </Style>
    )
}

export default CollaborationList;