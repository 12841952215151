import FAQList from "./list";
import FAQNew from "./new";
const { Switch, Route } = require("react-router")

const FAQs = () => {
    return (
        <Switch>
            <Route path="/faqs/" exact component={FAQList} />
            <Route path="/faqs/new/" exact component={FAQNew} />
            <Route path="/faqs/:id/edit/" exact component={FAQNew} />
        </Switch>
    )
}

export default FAQs;