import CollaborationList from "./list";
const { Switch, Route } = require("react-router")

const Collaborations = () => {
    return (
        <Switch>
            <Route path="/collaborations/" exact component={CollaborationList} />
        </Switch>
    )
}

export default Collaborations;