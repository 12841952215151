import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { getCategoryAPI } from "components/api/categories";
import { getCityAPI } from "components/api/cities";
import { deleteQuestionAskAPI, getQuestionAPI, submitQuestionAPI } from "components/api/questions";
import { deleteServiceAskAPI, getServiceAPI, submitServiceAPI } from "components/api/services";
import { deleteUserAskAPI } from "components/api/users";
import { Avatar, Badge, Button, DynamicForm, DynamicFormView, Link, Modal, Pagination, Spin, Table, Tabs, Title } from "components/elements";
import { CONSTS } from "components/elements/_constants";
import { Page } from "components/shared/layouts";
import SearchInput from "components/shared/searchinput";
import { BASE_URL, WEBSITE_BASE_URL } from "inc/constants";
import { removeEmpty } from "inc/functions";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import Swal from "sweetalert2";
import { ServiceTabs } from "./utils";

const Style = styled.div`
    
`
const ServiceQuestions = ({className, match}) => {

	const history = useHistory();

    const [object, setObject] = useState();
    const [service, setService] = useState();
    const [type, setType] = useState('list');
	const [questions, setQuestions] = useState();
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
	const [fields, setFields] = useState();
	
	const { id } = match.params;
    
    useEffect(() => {
		
		loadQuestions()
		if (id) {
			loadService().then(res => {
			})
		}
		
    }, []);

	useEffect(() => {
		if (object) {
			setType(object.type);
		}
	}, [object])

	useEffect(() => {
		const f = [
			{
				type: 'select',
				label: 'نوع',
				name: 'type',
				required: true,
				defaultValue: type,
				onChange: ev => {
					setType(ev.target.value);
					return ev.target.value;
				},
				options: [
					{
						label: 'لیست',
						value: 'list'
					},
					{
						label: 'متن',
						value: 'text'
					},
					{
						label: 'عدد',
						value: 'number'
					},
				]
			},
		]

		f.push(
			{
				label: 'عنوان سوال',
				name: 'title',
				required: true,
				defaultValue: object?.title
			},
		)

		if (type === 'list') {
			f.push(
				{
					type: 'textarea',
					label: 'لیست پاسخ ها',
					name: 'items',
					required: true,
					defaultValue: object?.items?.join("\n")
				},
			)
		}

		f.push(
			{
				label: 'ترتیب',
				name: 'order',
				required: true,
				defaultValue: object?.order || 1
			},
			{
				label: ' ',
				type: 'submit',
				value: 'ذخیره',
				inputClassName: 'd-flex justify-content-end',
				block: false,
				before: <Button secondary onClick={() => {
					setShowForm(false);
					setObject(undefined);
				}}>لغو</Button>
			}
		)

		setFields(f);

	}, [object, type]);


	const handleSubmit = data => {

		if (!data.title || !data.type) {
			Swal.fire({
				text: 'لطفا تمامی موارد خواسته شده را وارد کنید.',
				icon: 'error',
				confirmButtonText: 'باشه'
			});
			return;
		}

		data = {
			...data,
			showOn: ['app', 'bot'],
			order: parseInt(data.order),
			categoryId: service.category?.id,
			serviceId: id,
			items: data.items?.trim()?.split("\n")?.filter(x => x !== "")
		}

		setLoading(true);
		submitQuestionAPI({data: removeEmpty(data), pk: object ? object.id : null}).then(res=> {
			setLoading(false);
			Swal.fire({
				text: `سوال با موفقیت ${object ? 'ویرایش' : 'ایجاد'} شد.`,
				icon: 'success',
				confirmButtonText: 'باشه'
			});
			setObject(undefined);
			loadQuestions();
		}).catch(err => {
			setLoading(false);
		});

		return true;
	}

	const handleClickEdit = question => {
		setShowForm(true);
		setObject(question);
		window.scrollTo({top: 0});
	}

    const loadService = () => {
        return getServiceAPI({pk: id}).then(res => {
            setService(res);
			if (!res.category) {
				Swal.fire({
					text: 'برای تعیین سوالات ابتدا دسته بندی خدمت را مشخص کنید.',
					icon: 'error',
					confirmButtonText: 'باشه'
				});
			}
			return res;
        }).catch(err => {
        })
    }
	const loadQuestions = () => {
        setLoading(true);
        getQuestionAPI({params: {limit: 100, serviceId: id}}).then(res => {
            setQuestions(res);
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }

	const columns = [
		{
			title: 'عنوان',
			dataIndex: 'title'
		},
		{
			title: 'نوع',
			dataIndex: 'type',
			render: t => {
				let tt = '';
				switch (t) {
					case 'list':
						tt = 'لیست'
						break;
					case 'text':
						tt = 'متن'
						break;
					case 'number':
						tt = 'عدد'
						break;
					default:
						break;
				}
				return tt;
			}
		},
		{
			title: 'گزینه ها',
			dataIndex: 'items',
			render: items => items?.join(" | ")
		},
		{
			title: '',
			dataIndex: 'id',
			render: (id, question) => (
				<>
					<Button small error icon="trash" onClick={() => deleteQuestionAskAPI({pk: id, success: loadQuestions})} />
					<Button small light icon="edit" onClick={() => handleClickEdit(question)} />
				</>
			)
		}
	]

    return (
        <Style className={className}>
            <Page title="سوالات خدمت">
				{id && <ServiceTabs id={id} />}
                <Spin active={!service || loading}>
					{!showForm && <Button link icon='plus' onClick={() => setShowForm(true)}>ایجاد سوال</Button>}
					{showForm && <DynamicForm theme="inline" fields={fields} block onSubmit={handleSubmit} />}
                </Spin>
				<Spin active={loading}>
					<Table columns={columns} dataSource={questions?.data} />
				</Spin>
            </Page>
        </Style>
    )
}

export default ServiceQuestions;